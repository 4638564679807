import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { lS, extractInitials, scroll } from "../../../atlas-utils";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { fetchBizPlatforms, fetchBrands, fetchStores, fetchStoresDebounced } from "../../../actions/actions";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { Filters } from "../../../components/_commons/Filters";
import { store } from "../../../store/configureStore";
import {
	fetchStoreMetrics,
	fetchDebouncedLocationAvailabilityTableMetrics,
	fetchLocationAvailabilityTableMetrics,
	fetchPeriscopeEnabledBrands,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations,
	fetchDebouncedPersicopeEnabledCities
} from "../../../actions/periscope";
import LocationStackedBar from "./LocationStackedBar";
import LocationAvailabilityTable from "./LocationAvailabilityTable";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import { ActionTypes } from "../../../actions/_types";
import MainHeader from "../../../components/Periscope/MainHeader";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LocationAvailability = ({
	configItems,
	login,
	isMultibrandEnabled,
	periscopeLocationAvailabilityState,
	periscopeDataResponse,
	periscopeFilters
}) => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const storeStatus = queryParams?.get("status");

	const brands = configItems?.brands?.items;
	const stores = configItems?.stores?.items;
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});

	const cities = periscopeFilters?.cities;
	const periscopeBrandIds = periscopeFilters?.brands;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredBrands, setFilteredBrands] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!isMultibrandEnabled || !brands || !periscopeBrandIds) return;

		const filteredBrands = brands.filter((brand) => {
			return periscopeBrandIds.includes(String(brand?.id)) || brand?.id === "all";
		});
		setFilteredBrands(filteredBrands);
	}, [isMultibrandEnabled, brands, periscopeBrandIds]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id == "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	let currentFilters = periscopeLocationAvailabilityState?.currentFilters || {};
	const appliedFilters = periscopeLocationAvailabilityState?.appliedFilters;

	const clearFilters = () => {
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { offset: 0, limit: 10 }
		});
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: {
				currentFilters: {},
				appliedFilters: {},
				sort: null
			}
		});
		setShowFilters(false);
		setStatus("all");
	};

	const handleReset = () => {
		clearFilters();
		setSelectedCity({
			value: "all",
			valueForDisplay: "All Cities",
			__typename: "CityType"
		});
	};

	const getPlatformData = () => {
		const platformsData = [];
		bizPlatforms.forEach((platform) => {
			platformsData.push({
				value: platform?.platformName?.toLowerCase(),
				valueForDisplay: platform?.platformName
			});
		});

		platformsData.shift();
		return platformsData;
	};

	const setInitialOffset = () => {
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { offset: 0 }
		});
	};

	const platformsData = useMemo(() => getPlatformData(bizPlatforms), [bizPlatforms]);
	const defaultPlatforms = useMemo(() => {
		return platformsData.map((platform) => platform.value).join(",") || "";
	}, [platformsData]);

	const [selectedBrand, setSelectedBrand] = useState(
		isMultibrandEnabled
			? lS.get("brand") && lS.get("brand")?.id !== "all"
				? lS.get("brand")
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
			: null
	);

	const [selectedCity, setSelectedCity] = useState({
		value: "all",
		valueForDisplay: "All Cities",
		__typename: "CityType"
	});

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrand = (brand) => {
		setSelectedBrand(brand);
		lS.set("brand", brand);
		setInitialOffset();
		handleReset();
	};

	const handleFilterState = (isOpen, field) => {
		let filters = {
			locations: () => fetchStores("", 50, null, true),
			cities: () => fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true)
		};

		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};

	const handleStoresDebounced = (searchText) => {
		let includeAll = true;
		if (searchText) {
			includeAll = false;
		}
		fetchStoresDebounced(searchText, 50, null, includeAll);
	};

	const [showFilters, setShowFilters] = useState(false);
	const filterOptions = [
		{ hide: null, field: "platform", valueForDisplay: "Platform", type: "MULTIPLE", values: platformsData },
		{
			field: "location",
			valueForDisplay: "Location",
			values: filteredLocations,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT",
			isAsync: true,
			handleAsyncSearch: handleStoresDebounced,
			isLoading: configItems?.stores?.isLoading
		},
		{
			hide: null,
			field: "sortby",
			valueForDisplay: "Sort By",
			type: "SINGLE_STRICT",
			values: [
				{ value: "most_offline", valueForDisplay: "Most Offline" },
				{ value: "highest_rated", valueForDisplay: "Highest rated" },
				{ value: "lowest_rated", valueForDisplay: "Lowest rated" },
				{ value: "A-Z", valueForDisplay: "A-Z" },
				{ value: "Z-A", valueForDisplay: "Z-A" }
			]
		}
	];
	const countNoOfFilters = () => {
		const allKeys = Object.values(periscopeLocationAvailabilityState?.appliedFilters || {});
		return allKeys.reduce((count, key) => {
			if (key?.["value"] || key?.["id"]) count += 1;
			return count;
		}, 0);
	};
	const filterCount = countNoOfFilters();

	const applyFilters = () => {
		setInitialOffset();
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_APPLY_FILTERS_CHANGE,
			payload: currentFilters
		});
		setShowFilters(false);
	};

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		let currentFilters = { ...appliedFilters };
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { currentFilters }
		});
	};

	const [status, setStatus] = useState(storeStatus ? storeStatus : "all");
	const handleStatusFilter = (val) => {
		if (val.value) {
			setStatus(val.value);
		} else {
			if (val == status) {
				setStatus("all");
			} else {
				setStatus(val);
			}
		}
		setInitialOffset();
	};

	const setFilterr = (field, value) => {
		let currentFilters = { ...periscopeLocationAvailabilityState?.currentFilters, [field]: value };
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { currentFilters }
		});
	};

	useEffect(() => {
		countNoOfFilters();
	}, [appliedFilters]);

	useEffect(() => {
		fetchBrands("", true);
		fetchBizPlatforms(false, false, true);
		fetchStores("", 50, null, true);
		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledBrands(String(login?.loggedInbizDetail?.id));
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);

	const tableRef = useRef(null);
	const [searchQuery, setSearchQuery] = useState("");
	const { sort } = periscopeLocationAvailabilityState;
	const { location, platform, sortby } = appliedFilters;
	let platformValues = platform?.value?.split(",");
	const sortByData = sortby?.value;
	const locationValues = location?.id;
	const limit = periscopeLocationAvailabilityState?.limit;
	const offset = periscopeLocationAvailabilityState?.offset;

	useEffect(() => {
		const { bizId, platforms, brand, location, city } = getVariables(searchQuery, offset || 0);
		const storeRequestVariables = {
			bizId,
			brand,
			city,
			location,
			platforms
		};
		if (platforms[0]?.length > 1) {
			fetchStoreMetrics(storeRequestVariables);
		}
	}, [location, defaultPlatforms, platform, selectedBrand, selectedCity]);

	const getVariables = (text, offsetValue) => {
		const splitPlatforms = defaultPlatforms?.split(",");
		const finalPlatforms = platformValues && platformValues[0]?.length > 0 ? platformValues : splitPlatforms;
		return {
			bizId: String(login.loggedInbizDetail.id),
			platforms: finalPlatforms,
			status: String(status),
			city: String(selectedCity?.value || "all"),
			brand: isMultibrandEnabled ? String(selectedBrand?.id || "all") : null,
			location: String(locationValues || "all"),
			limit: limit || 10,
			offset: offsetValue,
			sortBy: sortByData || "most_offline",
			sort: sort || null,
			search: text,
			status: status
		};
	};
	const textDebouncedLocationTableMetrics = (text) => {
		const variables = getVariables(text, 0);
		if (variables.platforms[0]?.length > 1) {
			fetchDebouncedLocationAvailabilityTableMetrics(variables);
		}
	};

	const fetchData = (variables, periscopeDataFetch = false) => {
		fetchLocationAvailabilityTableMetrics(variables, periscopeDataFetch);
	};

	const fetchInfo = () => {
		const variables = getVariables(searchQuery, offset || 0);
		if (variables.platforms[0]?.length > 1) {
			fetchData(variables);
		}
	};

	useEffect(() => {
		fetchInfo();
	}, [location, platform, selectedBrand, selectedCity, status, sortByData, limit, offset, sort, defaultPlatforms]);

	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;
	useEffect(() => {
		const variables = getVariables(periscopeLocationAvailabilityState?.searchText, offset || 0);

		if (periscopeDataFetch && variables.platforms[0]?.length > 1) {
			fetchStoreMetrics(variables, periscopeDataFetch);
			fetchData(variables, periscopeDataFetch);
		}
	}, [periscopeDataFetch]);

	const handleSearch = (field, value) => {
		setSearchQuery(value);
		textDebouncedLocationTableMetrics(value);
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { searchText: value, offset: 0 }
		});
	};
	const statusLabels = {
		offline: "Offline",
		online: "Online",
		all: "All"
	};
	const getStatusLabel = (status) => statusLabels[status] || statusLabels.all;

	const handleCityFilter = (val) => {
		setSelectedCity(val);
		setInitialOffset();
	};

	const handleCitySearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchDebouncedPersicopeEnabledCities(String(login?.loggedInbizDetail?.id), includeAll, text);
	};

	return (
		<div className="periscope-container">
			<div className="section-container-common-periscope location-availability-page" ref={tableRef}>
				<MainHeader classname="header-location-availability" headerText="Storefront Availability" />
				<div className={"filters-location  item-availability-filters"}>
					<div className="left-filters-location brand-state-filters">
						{isMultibrandEnabled && (
							<SelectFilterCustom
								options={filteredBrands}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrand(value)}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={true}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
							/>
						)}
						<div className="city-filter">
							<SelectFilterCustom
								options={cities}
								field="cities"
								currValue={selectedCity || cities[0]}
								setFilter={(f, value) => {
									handleCityFilter(value);
								}}
								labelKey={"valueForDisplay"}
								valueKey={"value"}
								isSearchable={true}
								placeholder="All Cities"
								handleSearch={handleCitySearch}
								handleSelectFilterState={handleFilterState}
							/>
						</div>
						<div style={{ width: "190px" }}>
							<SelectFilter
								options={[
									{ value: "all", valueForDisplay: "All" },
									{ value: "offline", valueForDisplay: "Offline" },
									{ value: "online", valueForDisplay: "Online" }
								]}
								field="status"
								currValue={{
									value: status,
									valueForDisplay: (
										<span>
											<span className="status-heading" style={{ color: "#8A8A8A" }}>
												Status
											</span>
											<span className="item-status">{getStatusLabel(status)}</span>
										</span>
									)
								}}
								labelKey="valueForDisplay"
								valueKey="value"
								setFilter={(f, v) => handleStatusFilter(v)}
								isSearchable={false}
								isClearable={false}
							/>
						</div>
						<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
							<div className="container" onClick={() => setShowFilters((prev) => !prev)}>
								<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
								<div data-testid="filter-btn" className="filter-title">
									Filter
									{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
								</div>
							</div>
						</div>
						{configItems?.dimensions?.width > 768 && (
							<Filters
								isOpen={showFilters}
								close={filterSidebarCloseHandler}
								options={filterOptions}
								apply={applyFilters}
								clear={clearFilters}
								currentFilters={currentFilters}
								setFilter={(f, value) => setFilterr(f, value)}
							/>
						)}
					</div>

					<div className="right-filters-location">
						<SearchFilter
							placeholder="Search"
							filterOption={{
								field: "name"
							}}
							setFilter={handleSearch}
							value={searchQuery}
						/>
					</div>
				</div>
				<LocationStackedBar handleStatusFilter={handleStatusFilter} status={status} />
				<LocationAvailabilityTable
					refreshBtn={fetchInfo}
					tableRef={tableRef}
					status={status}
					selectedBrand={selectedBrand}
					selectedCity={selectedCity}
					defaultPlatforms={defaultPlatforms}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	login: state?.login,
	isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
	periscopeLocationAvailabilityState: state?.periscopeLocationAvailabilityState,
	periscopeDataResponse: state?.periscopeDataResponse,
	periscopeFilters: state.periscopeFilters
});

export default connect(mapStateToProps)(LocationAvailability);
