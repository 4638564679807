import React from "react";

import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function LocationCreationFailure({ onTryAgain = () => {}, onLearnMore = () => {} }) {
	return (
		<div className="location-creation-failure">
			<img className="illustration" src="/assets/location-creation-failure.svg" alt="Failed" />
			<header className="location-creation-failure__title">
				<img src="/assets/icons/icon-error-two.svg" alt="error-icon" />
				<Text variant={TextVariant.HEADING4} className="location-creation-failure__main_title">
					Location Creation Failed
				</Text>
			</header>
			<Text variant={TextVariant.BODY1} className="location-creation-failure__sub_title">
				There was an error syncing locations. Please try again.
			</Text>
			<Button
				onClick={onLearnMore}
				variant={ButtonVariant.Link}
				size={ButtonSize.LARGE}
				className="location-creation-failure__learn-more"
			>
				Learn more
			</Button>

			<Button
				onClick={onTryAgain}
				variant={ButtonVariant.Secondary}
				size={ButtonSize.LARGE}
				className="location-creation-failure__try-again"
			>
				Try again
			</Button>
		</div>
	);
}

export default LocationCreationFailure;
