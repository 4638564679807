import React, { useState, useEffect } from "react";

import history from "../../history";

// components
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { Loading } from "../_commons/Loading";
import MenuStatus from "./MenuStatus";

// actions
import { fetchDspAggregatedStatus } from "../../actions/dspIntegration";

// constants
import { PLATFORM_MAP } from "../Hub/Integrations";
import { capitaliseText } from "../../atlas-utils";
import InfiniteSpinner from "../_commons/InfiniteSpinner";
import NotificationServices from "../../services/NotificationService";
export const STATUS_NAME_MAP = {
	DEFAULT: "Mapping Your Stores",
	MENU_REQUESTED: "Menu Requested",
	MENU_QUALIFIED: "Menu Qualified",
	INTEGRATION_ACTIVATED: "Integration Activated"
};
const STATUS_ICON_MAP = {
	done: {
		icon: "success-circle",
		color: "#0DA125"
	},
	wait: {
		icon: "wait-time",
		color: "#AAAAAA"
	},
	open: {
		icon: "loading",
		color: "#2f58f2"
	},
	warning: {
		icon: "error-triangle",
		color: "#D64949"
	}
};

const PublishAndActivate = ({
	activationSuccess,
	cardProps,
	dspIntegration,
	fetchDspPlatformLocationsList,
	handleSearchFilter,
	handleStatusFilter,
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	updateDspIntegrationState,
	handleCancel
}) => {
	const { loading, platform, aggregatedStatus = [] } = dspIntegration;
	const [isMenuStatusOpen, setMenuStatusOpen] = useState(false);
	const [selectedMenuStatusType, setSelectedMenuStatusType] = useState({});

	const [timeRemaining, setTimeRemaining] = useState(10); // Initialize countdown

	useEffect(() => {
		const timer = setTimeout(() => {
			if (timeRemaining > 0) {
				setTimeRemaining(timeRemaining - 1); // Decrease countdown
			} else {
				handleCancel(true);
			}
		}, 1000); // Update every second

		return () => clearTimeout(timer); // Clean up the timer on component unmount
	}, [timeRemaining, history]);

	const activationStatus =
		!loading &&
		(!aggregatedStatus?.length ||
			aggregatedStatus?.find((type) => type?.status === "INTEGRATION_ACTIVATED")?.iconState === "done")
			? true
			: false;

	useEffect(() => {
		NotificationServices.pushNotification({
			message: "Request successfully sent!",
			timeout: 5000,
			type: "success",
			isClosable: true,
			theme: "dark"
		});
		if (PLATFORM_MAP[platform]) {
			fetchDspAggregatedStatus(platform);
		}
	}, []);

	const handleOpenMenuStatusDetail = (name, status) => {
		// handleStatusFilter("dsp_status", {value: status});
		setSelectedMenuStatusType({ name, status });
		setMenuStatusOpen(true);
	};

	const closeMenuStatusDetail = () => {
		setMenuStatusOpen(false);
		setSelectedMenuStatusType({});
		// handleStatusFilter("dsp_status", {value: "all"});
	};

	if (!activationSuccess) {
		return (
			<div className="dsp-locations">
				<div className="publish-store-activation-status">
					<div className="header">
						<div className="status-icon">
							<img src={cardProps?.header?.icon} alt="" />
						</div>
						<div className="header-text">{cardProps?.header?.text}</div>
						<div className="header-sub-text">{cardProps?.header?.subText}</div>
					</div>
					<div className="footer">
						<Button clickHandler={cardProps?.footer?.primaryHandler}>{cardProps?.footer?.primary}</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="dsp-locations">
			<div className="publish-store-activation-status">
				<div className="header">
					<div className="status-icon">
						<img src="/assets/icons/activate.svg" alt="" />
					</div>
					<div className="header-text">Request Initiated</div>
					<div className="header-sub-text ">
						Verification takes a while. Post activation, you will start receiving online orders on our
						platform and/or in your POS. Here is what happens next:
					</div>
					<div className="benifits">
						<div className="point">
							<div className="img">
								<img src="/assets/left-nav/icon-menus.svg" />
							</div>
							<div className="text">Your menu will be verified in the next 5 minutes</div>
						</div>
						<div className="point">
							<div className="img">
								<img src="/assets/icons/tick-gray.svg" />
							</div>
							<div className="text">
								Once verified, the request will automatically be sent to the Online Ordering Platform
							</div>
						</div>
						<div className="point">
							<div className="img">
								<img src="/assets/icons/link.svg" />
							</div>
							<div className="text">
								The ordering platform and UrbanPiper will map locations and you'll receive confirmation
								via mail
							</div>
						</div>
					</div>
				</div>
				{/* <div
					className="status-alert-container"
					style={{
						borderLeft: `6px solid #3B5FEB`,
						backgroundColor: "#F2F5FF"
					}}
				>
					<img src="/assets/icons/info-blue.svg" />

					<div className="error-text-link">
						<div className="main-desc">
							Verification takes typically 2-3 days, {capitaliseText(platform)} will map and confirm all
							locations, UrbanPiper will then map locations and go live.
						</div>
					</div>
				</div> */}
				{/* {aggregatedStatus?.length > 0 && (
					<div className="activation-status">
						<div className="title">Activation Status</div>
						<div className="status-wrapper">
							{aggregatedStatus?.map((status, i) => (
								<div className="status" key={i}>
									{status?.iconState === "open" ? (
										<Loading circular color={STATUS_ICON_MAP[status?.iconState]?.color} />
									) : (
										<ButtonIcon
											icon={STATUS_ICON_MAP[status?.iconState]?.icon}
											color={STATUS_ICON_MAP[status?.iconState]?.color}
										/>
									)}
									<div className="type">
										<div className="name">{STATUS_NAME_MAP[status?.status]}</div>
										<div
											className={"count" + (status?.status !== "DEFAULT" ? " link" : "")}
											onClick={
												status?.status !== "DEFAULT"
													? () =>
															handleOpenMenuStatusDetail(
																STATUS_NAME_MAP[status?.status],
																status?.status
															)
													: () => {}
											}
										>
											({status?.completed}/{status?.total}) {STATUS_NAME_MAP[status?.status]}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				)} */}
				<div className="footer">
					<Button clickHandler={() => handleCancel(true)}>Go live on other platforms</Button>
				</div>
				<div className="redirect">
					<img src="/assets/icons/loading.svg" />
					Redirecting to Platforms in {timeRemaining}s...
				</div>
			</div>
			{/* <MenuStatus
				isOpen={isMenuStatusOpen}
				close={closeMenuStatusDetail}
				dspIntegration={dspIntegration}
				selectedMenuStatusType={selectedMenuStatusType}
				fetchDspPlatformLocationsList={fetchDspPlatformLocationsList}
				handleSearchFilter={handleSearchFilter}
				handleStatusFilter={handleStatusFilter}
				handlePagination={handlePagination}
				handlePageSize={handlePageSize}
				handleAssociationUpdates={handleAssociationUpdates}
				updateDspIntegrationState={updateDspIntegrationState}
			/> */}
		</div>
	);
};
export default PublishAndActivate;
