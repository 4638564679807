// action types
import { ActionTypes } from "../actions/_types";

// store
import { store } from "../store/configureStore";

// constants
import { ATLAS_ONBOARDING_STEPS, ONBOARDING_FLOWS } from "../client-config";

const ATLAS_ONBOARDING_STEPS_LIST_INIT = {
	isLoading: false,
	flowData: {},
	flowSteps: []
};

export const atlasOnboardingState = (state = ATLAS_ONBOARDING_STEPS_LIST_INIT, action) => {
	switch (action.type) {
		case ActionTypes.GET_USER_FLOW_STEPS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_USER_FLOW_STEPS_SUCCESS:
			let flowData = action.payload?.find?.(({ flow }) => {
				return flow?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
			})?.flow;

			const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
			if (!isMultibrandEnabled) {
				flowData.steps = flowData?.steps?.filter((step) => step.name !== ATLAS_ONBOARDING_STEPS.CREATE_BRANDS);
			}

			return {
				...state,
				isLoading: false,
				flowData: flowData ?? []
			};

		case ActionTypes.GET_USER_FLOW_STEPS_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.GET_USER_STEPS_REQUEST:
			return {
				...state
			};

		case ActionTypes.GET_USER_STEPS_SUCCESS:
			return {
				...state,
				flowSteps: action.payload
			};

		case ActionTypes.GET_USER_STEPS_FAILURE:
			return {
				...state,
				flowSteps: []
			};

		default:
			return state;
	}
};
