import React, { useState, useCallback, useEffect, useRef, useImperativeHandle, forwardRef } from "react";

// components
import { SelectFilter } from "../../_commons/SelectFilter";
import { SelectFilterCustom } from "../../_commons/SelectFilterCustom";
import { NewDateCompareFilter } from "../../_commons/NewDateCompareFilter";

// third party
import { connect } from "react-redux";
import uniqBy from "lodash/uniqBy";

// graphql
import { client } from "../../../client";

// store
import { store } from "../../../store/configureStore";

// utils
import { trackEvent } from "../../../atlas-utils";

// actions
import { ActionTypes } from "../../../actions/_types";
import { fetchBrands, fetchStoresDebounced, fetchBizPlatforms, fetchItemCategories } from "../../../actions/actions";
import { GET_STORES_LIST } from "../../../graphql/misc";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../../client-config";
const FILTERING_ENTITIES = ["brand_id", "location_id", "platform_names"];

const Filters = forwardRef(
	({
		showBrands = false,
		showLocations = false,
		showPlatforms = false,
		showDateCompare = false,
		isMultibrandEnabled = false,
		brands,
		stores,
		bizPlatforms,
		access,
		associatedLocations = [],
		module = "",
		analyticsFiltersState,
		dimensions,
		loading = false,
		includeAllTime = false,
		resetRef
	}) => {
		const { currentFilters, currentDateFilter, appliedDateFilter, initialFiltersFromUrl } = analyticsFiltersState;
		const [isDateCompareFilterWrapped, setIsDateCompareFilterWrapped] = useState(false);
		const [optionUpdates, setOptionUpdates] = useState({
			brand_id: {},
			location_id: {},
			platform_names: {}
		});
		const [isCheckedAll, setIsCheckedAll] = useState({ brand_id: true, location_id: true, platform_names: true });
		useImperativeHandle(resetRef, () => ({
			clear: () => {
				setOptionUpdates({ brand_id: {}, location_id: {}, platform_names: {} });
			}
		}));
		const filtersContainerRef = useRef();
		const filtersRightRef = useRef();
		const [platformsData, setPlatformsData] = useState([]);
		const [locationsData, setLocationsData] = useState([]);
		const [brandsData, setBrandsData] = useState([]);
		const [selectedLocations, setSelectedLocations] = useState([]);
		const [isSelectFilterOperationsLoading, setIsSelectFilterOperationsLoading] = useState(false);
		const [searchedLocations, setSearchedLocations] = useState([]);
		const [searchVal, setSearchVal] = useState("");
		const [filteredBrands, setFilteredBrands] = useState(
			!access?.isAdmin && (access?.isNonHqReport || access?.isNonHqAnalytics)
				? [...new Set(associatedLocations?.map((loc) => loc?.brandName))]
				: []
		);
		const [filteredBrandLocations, setFilteredBrandLocations] = useState(
			!access?.isAdmin && (access?.isNonHqReport || access?.isNonHqAnalytics)
				? [...new Set(associatedLocations?.map((loc) => loc?.name))]
				: []
		);
		const debounceRef = useRef();

		const fetchSearchedSelectedLocations = async (locationIds = [], searchVal = "") => {
			if (locationIds?.length || searchVal) {
				setIsSelectFilterOperationsLoading(true);
				try {
					const variables = {
						limit: 50,
						sort: {
							field: "name",
							order: "ASC"
						}
					};

					let filters = [
						{
							field: "is_active",
							value: true
						}
					];

					if (locationIds?.length) {
						filters.push({
							field: "biz_location_id",
							value: String(locationIds.join(","))
						});
					}

					if (searchVal) {
						filters.push({
							field: "name",
							value: searchVal
						});
					}

					variables.filters = filters;

					const resp = await client.query({
						query: GET_STORES_LIST,
						variables
					});
					setIsSelectFilterOperationsLoading(false);
					return resp;
				} catch (error) {
					console.log(error);
				}
				setIsSelectFilterOperationsLoading(false);
			}
		};

		useEffect(() => {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			if (!searchVal) {
				setSearchedLocations([]);
				return clearTimeout(debounceRef.current);
			}

			debounceRef.current = setTimeout(async () => {
				const resp = await fetchSearchedSelectedLocations([], searchVal);
				if (resp) {
					setSearchedLocations(resp.data.stores.objects);
				}
			}, 300);

			return () => {
				clearTimeout(debounceRef.current);
			};
		}, [searchVal]);

		useEffect(() => {
			// [entity].items.length === 0 avoids frequent API calls on URL change
			if (isMultibrandEnabled && showBrands && brands?.items?.length === 0) {
				fetchBrands("", true);
			}
			if (showLocations && stores?.items?.length === 0) {
				fetchStoresDebounced(
					"",
					20,
					null,
					true,
					(associatedLocations ?? [])?.map((loc) => loc.rootLocation)
				);
			}
			if (showPlatforms && bizPlatforms?.items?.length === 0) {
				fetchBizPlatforms(true, true, true);
			}
		}, []);

		useEffect(() => {
			if (
				![
					locationsData?.isLoading,
					platformsData?.isLoading,
					brandsData?.isLoading,
					isSelectFilterOperationsLoading
				].every((item) => item === false)
			) {
				return;
			}

			if (selectedLocations?.length) {
				setEntitiesWithSelectedOnTop("location_id");
			}
			if (platformsData?.items?.length) {
				setEntitiesWithSelectedOnTop("platform_names");
			}
			if (brandsData?.items?.length) {
				setEntitiesWithSelectedOnTop("brand_id");
			}
		}, [
			locationsData?.isLoading,
			platformsData?.isLoading,
			brandsData?.isLoading,
			isSelectFilterOperationsLoading
		]);

		const setEntitiesWithSelectedOnTop = (entity) => {
			if (entity === "location_id") {
				let locationsWithSelectedOnTop = locationsData?.items || [];
				if (selectedLocations?.length) {
					const _selectedLocations = [...selectedLocations];
					const _restLocations = locationsData?.items?.filter(
						(loc) => !selectedLocations.find((sel) => sel.id === loc.id)
					);
					locationsWithSelectedOnTop = [..._selectedLocations, ..._restLocations];
				}

				setLocationsData({
					...locationsData,
					items: locationsWithSelectedOnTop
				});
			} else if (entity === "platform_names") {
				let platformsWithSelectedOnTop = platformsData?.items || [];
				const selectedPlatformsKeys = Object.keys(optionUpdates.platform_names);
				if (selectedPlatformsKeys.length) {
					const _restPlatforms = [];
					const _selectedPlatforms = platformsData?.items?.filter((platform) => {
						if (selectedPlatformsKeys.includes(String(platform.id))) {
							return platform;
						} else {
							_restPlatforms.push(platform);
						}
					});

					platformsWithSelectedOnTop = [..._selectedPlatforms, ..._restPlatforms];
					setPlatformsData({
						...platformsData,
						items: platformsWithSelectedOnTop
					});
				}
			} else if (entity === "brand_id") {
				let brandsWithSelectedOnTop = brandsData?.items || [];
				const selectedBrandsKeys = Object.keys(optionUpdates.brand_id).map(String);
				if (selectedBrandsKeys.length) {
					const _restBrands = [];
					const _selectedBrands = brandsData?.items?.filter((brand) => {
						if (selectedBrandsKeys.includes(String(brand.id))) {
							return brand;
						} else {
							_restBrands.push(brand);
						}
					});

					brandsWithSelectedOnTop = [..._selectedBrands, ..._restBrands];
					setBrandsData({
						...brands,
						items: brandsWithSelectedOnTop
					});
				}
			}
		};

		useEffect(() => {
			setBrandsData(brands);
			setLocationsData(stores);
		}, [brands, stores]);

		useEffect(() => {
			if (!bizPlatforms?.isLoading && bizPlatforms?.items?.length) {
				const _bizPlatforms = { ...bizPlatforms };

				if (access.primeAccess?.length && access.primeAccess?.find((prm) => prm.posEnabled) !== undefined) {
					if (!_bizPlatforms.items?.find((item) => item.id === "prime")) {
						_bizPlatforms.items.push({
							id: "prime",
							platformName: "Prime",
							image: CATALOGUE_PLATFORMS_LOGO["prime"]
						});
					}
				}

				setPlatformsData(_bizPlatforms);
			}
		}, [bizPlatforms]);

		useEffect(() => {
			// only need to use this for the first time whenever a user lands on a page via URL, this is triggered on URL change as well
			const _initialFiltersFromUrl = { ...initialFiltersFromUrl };
			const _optionUpdates = { ...optionUpdates };
			const _isCheckedAll = { ...isCheckedAll };

			FILTERING_ENTITIES.forEach((entity) => {
				if (_initialFiltersFromUrl[entity]?.find((item) => item === "all")) {
					_isCheckedAll[entity] = true;
				} else {
					_isCheckedAll[entity] = false;
					const _selectedLocations = [];
					_optionUpdates[entity] = _initialFiltersFromUrl[entity]?.reduce((acc, opt) => {
						acc[opt] = true;
						if (entity === "location_id") {
							_selectedLocations.push(opt);
						}

						return acc;
					}, {});
					if (entity === "location_id" && _selectedLocations.length) {
						// setIsSelectFilterOperationsLoading(true);
						fetchSearchedSelectedLocations(_selectedLocations).then((resp) => {
							if (resp) {
								setSelectedLocations(resp.data.stores.objects);
							}
						});
					}
				}
			});
			setIsCheckedAll(_isCheckedAll);
			setOptionUpdates(_optionUpdates);
			// track event
			trackFiltersEvent(_initialFiltersFromUrl);
		}, [initialFiltersFromUrl]);

		const handleSearchLocations = (searchVal) => {
			setSearchVal(searchVal);
		};

		const detectFiltersWrap = () => {
			// check if filters container is getting wrapped and change date compare
			// filter dropdown position to either left or right
			if (filtersContainerRef.current && filtersRightRef.current) {
				if (filtersRightRef.current.offsetTop > filtersContainerRef.current.offsetTop) {
					setIsDateCompareFilterWrapped(true);
				} else {
					setIsDateCompareFilterWrapped(false);
				}
			}
		};

		useEffect(() => {
			detectFiltersWrap();
		}, [appliedDateFilter, dimensions]);

		const updateAnalyticsFiltersState = (payload) => {
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
				payload
			});
		};

		const handleSelectFilterState = (isOpen = false, field = null) => {
			if (!isOpen) {
				setEntitiesWithSelectedOnTop(field);

				updateAnalyticsFiltersState({
					appliedFilters: currentFilters
				});
			}
		};

		const handleCheck = (state, field, option, props) => {
			let _currentFilters = { ...currentFilters };
			let _selectedLocations = { ...selectedLocations };

			const _optionUpdates = {
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					[option.id]: state
				}
			};

			setOptionUpdates(_optionUpdates);

			const _isCheckedAllUpdates = { ...isCheckedAll };
			_isCheckedAllUpdates[field] = false;
			setIsCheckedAll(_isCheckedAllUpdates);

			// redux state updates
			if (state) {
				_currentFilters[field] = [String(option.id), ..._currentFilters[field]];
				_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
				_currentFilters[field] = [...new Set(_currentFilters[field])]; // remove duplicates

				if (field === "location_id") {
					_selectedLocations = uniqBy([...selectedLocations, option], "id");
				}
			} else {
				if (field === "location_id") {
					_selectedLocations = selectedLocations.filter((loc) => loc.id !== option.id);
				}
				_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(option.id));

				if (_currentFilters[field].length === 0) {
					// if no option is selected, select all
					handleCheckAll(true, field);
					return;
				}
			}

			if (field === "location_id") {
				setSelectedLocations(_selectedLocations);
			}

			updateAnalyticsFiltersState({
				currentFilters: _currentFilters
			});

			// track event
			trackFiltersEvent(_currentFilters);
		};

		const handleCheckAll = (state, field, options, props) => {
			// redux state updates
			let _currentFilters = { ...currentFilters };
			const _optionUpdates = { ...optionUpdates };
			const _isCheckedAllUpdates = { ...isCheckedAll };

			_isCheckedAllUpdates[field] = state;
			_currentFilters[field] = state ? ["all"] : [];
			_optionUpdates[field] = {};

			setOptionUpdates(_optionUpdates);
			setIsCheckedAll(_isCheckedAllUpdates);

			if (field === "location_id") {
				setSelectedLocations([]);
			}

			updateAnalyticsFiltersState({
				currentFilters: _currentFilters
			});
		};

		const trackFiltersEvent = (data = []) => {
			const filtersApplied = {};
			Object.keys(data).forEach((key) => {
				const entityArr = data[key] || [];
				entityArr.forEach((entity) => {
					if (entity?.id !== "all") {
						filtersApplied[key.split("_")[0]] = true;
					}
				});
			});
			if (Object.keys(filtersApplied).length > 0) {
				trackEvent("analytics_filter_applied", { ...filtersApplied, module });
			}
		};

		if (showBrands || showLocations || showPlatforms || showDateCompare) {
			return (
				<div className="analytics-filters" ref={filtersContainerRef}>
					<div className="filters-left">
						{isMultibrandEnabled && showBrands && (
							<React.Fragment>
								<SelectFilterCustom
									options={
										brandsData.items?.filter(
											(brand) =>
												brand.id !== "all" &&
												(filteredBrands?.length > 0
													? filteredBrands?.includes(brand.name)
													: brand)
										) || []
									}
									field={"brand_id"}
									isLoading={brandsData.isLoading}
									labelKey={"name"}
									valueKey={"id"}
									iconKey={"image"}
									multi={true}
									showCheckBox={true}
									requiredLabel={true}
									showSelectAllOption
									showIcon={true}
									ellipsizedLength={40}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									placeholder={"Select brands"}
									selectAllPlaceholder={"All Brands"}
									countDisplayText={"brand"}
									handleSelectFilterState={handleSelectFilterState}
								/>
							</React.Fragment>
						)}
						{showLocations && (
							<SelectFilterCustom
								options={
									searchVal
										? searchedLocations?.filter(
												(store) =>
													store.id !== "all" &&
													(filteredBrandLocations?.length > 0
														? filteredBrandLocations?.includes(store.name)
														: store)
										  ) || []
										: locationsData?.items?.filter(
												(store) =>
													store.id !== "all" &&
													(filteredBrandLocations?.length > 0
														? filteredBrandLocations?.includes(store.name)
														: store)
										  ) || []
								}
								field={"location_id"}
								labelKey={"name"}
								valueKey={"id"}
								multi={true}
								showCheckBox={true}
								requiredLabel={true}
								showSelectAllOption
								ellipsizedLength={40}
								isLoading={locationsData?.isLoading || isSelectFilterOperationsLoading}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder={"Select locations"}
								selectAllPlaceholder="All Locations"
								countDisplayText={"location"}
								handleSelectFilterState={handleSelectFilterState}
								handleSearch={handleSearchLocations}
								isSearchExternal={true}
								maxSelectable={15}
							/>
						)}
						{showPlatforms && (
							<SelectFilterCustom
								options={platformsData.items?.filter((plf) => plf.id !== "all") || []}
								field={"platform_names"}
								labelKey={"platformName"}
								valueKey={"id"}
								iconKey={"image"}
								multi={true}
								showCheckBox={true}
								requiredLabel={true}
								showSelectAllOption
								showIcon={true}
								ellipsizedLength={40}
								isLoading={platformsData.isLoading}
								optionUpdates={optionUpdates}
								handleCheck={handleCheck}
								isCheckedAll={isCheckedAll}
								handleCheckAll={handleCheckAll}
								placeholder={"Select platforms"}
								selectAllPlaceholder="All Platforms"
								countDisplayText={"platform"}
								handleSelectFilterState={handleSelectFilterState}
							/>
						)}
					</div>
					<div className="filters-right" ref={filtersRightRef}>
						{showDateCompare && (
							<NewDateCompareFilter
								showDropdown={true}
								loading={loading}
								currentDateFilter={currentDateFilter}
								appliedDateFilter={appliedDateFilter}
								updateState={updateAnalyticsFiltersState}
								position={isDateCompareFilterWrapped ? "left" : "right"}
								module={module}
								includeAllTime={includeAllTime}
							/>
						)}
					</div>
				</div>
			);
		}
		return null;
	}
);
export default connect((store) => ({
	brands: store.configItems.brands,
	stores: store.configItems.stores,
	bizPlatforms: store.configItems.bizPlatforms,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	access: store.login.loginDetail.access,
	associatedLocations: store.login.loginDetail.associatedLocations,
	analyticsFiltersState: store.analyticsFiltersState,
	dimensions: store.configItems.dimensions
}))(Filters);

export const BreakdownByFilter = ({
	currValue,
	setFilter,
	labelKey = "label",
	valueKey = "value",
	isMultibrandEnabled = false,
	readOnly = false
}) => {
	if (!isMultibrandEnabled) {
		return null;
	}
	return (
		<SelectFilter
			options={[
				{ label: "Platform", value: "platform" },
				{ label: "Brand", value: "brand" }
			]}
			currValue={currValue}
			field="breakdownBy"
			setFilter={setFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Breakdown By <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			readOnly={readOnly}
			placeholder="Select breakdown by"
		/>
	);
};

export const CompareFilter = ({
	currValue,
	setFilter,
	labelKey = "label",
	valueKey = "value",
	options = [],
	module,
	metric,
	readOnly = false
}) => {
	const handleSetFilter = (field, value) => {
		setFilter(field, value);
		// track event
		trackEvent("analytics_compare_filter", {
			module,
			metric,
			compare: value.label
		});
	};
	return (
		<SelectFilter
			options={options}
			currValue={currValue}
			field="compare"
			setFilter={handleSetFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Compare <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			placeholder="Select compare"
			readOnly={readOnly}
		/>
	);
};

export const CategoryFilter = ({
	isLoading = false,
	options,
	currValue,
	setFilter,
	labelKey = "name",
	valueKey = "id"
}) => {
	useEffect(() => {
		fetchItemCategories(true);
	}, []);

	return (
		<SelectFilter
			isLoading={isLoading}
			options={options}
			currValue={currValue}
			field="category"
			setFilter={setFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Category <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			placeholder="Select catagory"
		/>
	);
};
