import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Breadcrumbs from "../_commons/Breadcrumbs";

// util
import history from "../../history";

// third party
import classNames from "classnames";

function Header({
	title = "",
	onCtaClick = () => {
		history.push("/onboarding");
	},
	totalSteps = 0,
	currentStep,
	loading = false
}) {
	return (
		<div className="onboarding-setup-header">
			<Breadcrumbs
				connectedLinks={[
					{
						title: "Setup",
						onClick: onCtaClick
					}
				]}
			/>

			<Text variant={TextVariant.HEADING2}>{title}</Text>
			{totalSteps ? (
				<div className="onboarding-steps">
					{loading ? (
						<div className="infinite-load-bar" />
					) : (
						Array.from({ length: totalSteps }).map((_, index) => (
							<div
								key={index}
								className={classNames("onboarding-steps__step", {
									"onboarding-steps__step--active": index <= currentStep ? true : false
								})}
							></div>
						))
					)}
				</div>
			) : null}
		</div>
	);
}

export default Header;
