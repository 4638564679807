import React, { useEffect, useRef, useState } from "react";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { connect } from "react-redux";
import { store } from "../../../store/configureStore";
import {
	fetchBizPlatforms,
	fetchBrands,
	fetchCities,
	fetchStores,
	fetchStoresDebounced
} from "../../../actions/actions";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { extractInitials, lS } from "../../../atlas-utils";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import ActivityLogTable from "./ActivityLogTable";
import { Topbar } from "../../../components/_commons/Topbar";
import { CATALOGUE_PLATFORMS_LOGO, PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";
import {
	fetchActivityLogItemDetails,
	fetchActivityLogLocationDetails,
	fetchDebouncedActivityLogItemDetails,
	fetchDebouncedActivityLogLocationDetails,
	fetchPeriscopeEnabledBrands,
	fetchPeriscopeEnabledLocations
} from "../../../actions/periscope";
import { scroll } from "../../../atlas-utils";
import MainHeader from "../../../components/Periscope/MainHeader";
import { ActionTypes } from "../../../actions/_types";

const ActivityLog = ({ configItems, periscopeActivityLogState, login, periscopeDataResponse, periscopeFilters }) => {
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});
	const bizPlatformsLoading = configItems?.bizPlatforms?.isLoading;

	const text = periscopeActivityLogState?.text || "";
	const brands = configItems?.brands?.items;
	const [tab, setTab] = useState("location");
	const tableRef = useRef(null);
	const stores = configItems?.stores?.items;
	const isMultibrandEnabled = login?.loggedInbizDetail?.isMultibrandEnabled;
	const [selectedPlatform, setSelectedPlatform] = useState(
		lS.get("platform") ? Object.values(lS.get("platform"))[0] : { id: "all", platformName: "All Platforms" }
	);

	const periscopeBrandIds = periscopeFilters?.brands;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredBrands, setFilteredBrands] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!isMultibrandEnabled || !brands || !periscopeBrandIds) return;

		const filteredBrands = brands.filter((brand) => {
			return periscopeBrandIds.includes(String(brand?.id)) || brand?.id === "all";
		});
		setFilteredBrands(filteredBrands);
	}, [isMultibrandEnabled, brands, periscopeBrandIds]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id)) || store?.id === "all";
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const setOffsetZero = () => {
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { offset: 0 }
		});
	};
	const handlePlatform = (value) => {
		setSelectedPlatform(value);
		lS.set("platform", {
			...(lS.get("platform") ?? {}),
			[login.loggedInbizDetail.id]: {
				id: value.id,
				platformName: value.platformName
			}
		});
		setOffsetZero();
	};
	const handlePlatformsLabelOption = (plf) => {
		return (
			<React.Fragment>
				<div className="logo">
					<img
						src={
							CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
				</div>
				<div title={plf.platformName}>
					{plf.platformName && plf.platformName.length > 25
						? plf.platformName.slice(0, 25) + "..."
						: plf.platformName}
				</div>
			</React.Fragment>
		);
	};
	const [selectedBrand, setSelectedBrand] = useState(
		isMultibrandEnabled
			? lS.get("brand") && lS.get("brand")?.id !== "all"
				? lS.get("brand")
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
			: null
	);
	const handleBrand = (brand) => {
		setSelectedBrand(brand);
		lS.set("brand", brand);
		setOffsetZero();
		handleReset();
	};
	const handleReset = () => {
		setLocationSelected({ id: "all", name: "All Locations" });
		setSelectedPlatform({ id: "all", platformName: "All Platforms" });
		setStatus("all");
	};
	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};
	const [locationSelected, setLocationSelected] = useState({ id: "all", name: "All Locations" });

	const handleLocation = (value) => {
		setLocationSelected(value);
		setOffsetZero();
	};

	useEffect(() => {
		fetchBizPlatforms(false, false, true);
		fetchBrands("", true);
		fetchStores("", 50, null, true);

		fetchPeriscopeEnabledBrands(String(login?.loggedInbizDetail?.id));
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));

		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);

	const [status, setStatus] = useState("all");

	const displayStatus = {
		all: "All",
		online: "Online",
		offline: "Offline",
		removed: "Removed"
	};

	const handleStatusFilter = (val) => {
		if (val?.value) {
			setStatus(val.value);
		}
		setOffsetZero();
	};

	const timeStatus = {
		last_30_days: "Last 30 Days",
		last_14_days: "Last 14 Days",
		last_7_days: "Last 7 Days",
		last_24_hours: "Last 24 Hours",
		last_12_hours: "Last 12 Hours",
		last_6_hours: "Last 6 Hours",
		last_2_hours: "Last 2 Hours",
		last_1_hour: "Last 1 Hour"
	};

	const [searchQuery, setSearchQuery] = useState("");

	const handleSearch = (field, value) => {
		setSearchQuery(value);
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { text: value, offset: 0 }
		});
		textDebouncedFetch(value);
	};

	const limit = periscopeActivityLogState?.limit;
	const offset = periscopeActivityLogState?.offset;
	const buildVariables = () => {
		let platformSelected =
			selectedPlatform?.platformName == "All Platforms" ? "all" : selectedPlatform?.platformName?.toLowerCase();
		return {
			bizId: String(login?.loggedInbizDetail?.id),
			brand: isMultibrandEnabled ? String(selectedBrand?.id || "all") : null,
			location: String(locationSelected?.id),
			status: status,
			platform: platformSelected,
			limit: limit,
			offset: offset,
			search: searchQuery
		};
	};

	const fetchData = (periscopeDataFetch = false) => {
		if (tab == "location") {
			fetchActivityLogLocationDetails(buildVariables(), isMultibrandEnabled, periscopeDataFetch);
		} else if (tab == "item") {
			fetchActivityLogItemDetails(buildVariables(), isMultibrandEnabled, periscopeDataFetch);
		}
	};

	useEffect(() => {
		fetchData();
	}, [selectedBrand?.id, locationSelected?.id, status, selectedPlatform?.platformName, limit, offset, tab]);
	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;
	useEffect(() => {
		if (periscopeDataFetch) {
			fetchData(periscopeDataFetch);
		}
	}, [periscopeDataFetch]);
	useEffect(() => {
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_STATE_FILTERS,
			payload: { offset: 0 }
		});
	}, [tab]);

	useEffect(() => {
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_GRAPH_DATA,
			payload: { loading: false, error: false, data: {} }
		});
	}, [tab]);

	const textDebouncedFetch = (textValue) => {
		let variables = buildVariables();
		variables = { ...variables, search: textValue };
		if (tab == "location") {
			fetchDebouncedActivityLogLocationDetails(variables, isMultibrandEnabled);
		} else if (tab == "item") {
			fetchDebouncedActivityLogItemDetails(variables, isMultibrandEnabled);
		}
	};

	const FORM_TABS = [
		{
			label: "Location Availability",
			value: "location"
		},
		{
			label: "Item Availability",
			value: "item"
		}
	];

	const handleFormTab = (tab) => {
		setTab(tab.value);
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	};

	const handleStoreSearch = (text) => {
		fetchStoresDebounced(text);
	};

	const handleFilterState = (isOpen, field) => {
		let filters = {
			locations: () => fetchStores("", 50, null, true)
		};
		if (isOpen) {
			const filterAction = filters[field];
			filterAction();
		}
	};

	return (
		<div className="periscope-container">
			<div
				ref={tableRef}
				className="section-container-common-periscope periscope-activity-log"
				data-testid="activity-log"
			>
				<MainHeader classname={"activity-div-header"} headerText="Activity Log" />
				<div className="filters-activity-log brand-state-filters">
					<div className="left-filters-log">
						{isMultibrandEnabled && (
							<SelectFilterCustom
								options={filteredBrands}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrand(value)}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={true}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
							/>
						)}

						<SelectFilterCustom
							options={filteredLocations}
							field="locations"
							currValue={locationSelected || filteredLocations[0]}
							setFilter={(f, value) => handleLocation(value)}
							labelKey={"name"}
							valueKey={"id"}
							isSearchable={true}
							placeholder="All Locations"
							isLoading={configItems?.stores?.isLoading}
							handleSearch={handleStoreSearch}
							handleSelectFilterState={handleFilterState}
						/>

						{bizPlatforms && bizPlatforms.length > 1 && (
							<SelectFilterCustom
								options={bizPlatforms}
								isLoading={bizPlatformsLoading}
								field="platforms"
								currValue={selectedPlatform || null}
								setFilter={(f, value) => handlePlatform(value)}
								labelKey={"platformName"}
								valueKey={"id"}
								isSearchable={true}
								customLabel={true}
								customOptions={true}
								renderLabel={handlePlatformsLabelOption}
								renderOptions={handlePlatformsLabelOption}
								placeholder="Select platform"
							/>
						)}
						<div style={{ width: "160px" }}>
							<SelectFilter
								options={[
									{ value: "all", valueForDisplay: "All" },
									{ value: "offline", valueForDisplay: "Offline" },
									{ value: "online", valueForDisplay: "Online" },
									{ value: "removed", valueForDisplay: "Removed" }
								]}
								field="status"
								currValue={{
									value: status,
									valueForDisplay: (
										<span className="status-main">
											<span className="status-heading">Status</span>
											<span className="status-display">{displayStatus[status] || "All"}</span>
										</span>
									)
								}}
								labelKey="valueForDisplay"
								valueKey="value"
								setFilter={(f, v) => handleStatusFilter(v)}
								isSearchable={false}
								isClearable={false}
							/>
						</div>
					</div>

					<div>
						<SearchFilter
							placeholder="Search"
							filterOption={{
								field: "name"
							}}
							setFilter={handleSearch}
							value={searchQuery}
						/>
					</div>
				</div>

				<div>
					<Topbar tabs={FORM_TABS} selectedTab={tab} switchTab={(tab) => handleFormTab(tab)} />
				</div>

				<ActivityLogTable
					fetchData={fetchData}
					tableRef={tableRef}
					tableName={tab}
					selectedBrand={selectedBrand}
				/>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeActivityLogState: state.periscopeActivityLogState,
	periscopeDataResponse: state.periscopeDataResponse,
	periscopeFilters: state.periscopeFilters
});
export default connect(mapStateToProps)(ActivityLog);
