import { combineReducers } from "redux";

// reducers
import login from "./login";
import loginformState from "./loginformState";
import banner from "./banner";
import { anaSummary, anaGraphData, anaOrderChannels } from "./anaSummary";
import { topPerfData, topPerfTopics } from "./topPerforming";
import { campaignListState, campaignList, campaignStatus } from "./campaignList";
import { createCampaign, emailTemplate, campaignUpdateState } from "./createCampaign";
import { suggestedSegmentList, createSegment } from "./segments";
import { configItems } from "./configItems";
import { campaignSideBar } from "./campaignSideBar";
import { creditsState } from "./credits";
import { selectedModule, dashboardState } from "./dashboard";
import { transactionsList, transactionsListState, transactionDetails } from "./transactions";
import { invoiceListViewState, invoiceDetailViewState, outstandingInvoicesState } from "./invoices";
import { locationsListState, locationsList, createLocationStatus, locationDetailsReducer } from "./locations";
import { categoriesListState, categoriesList, categoryItems } from "./categories";
import { modifierGroupsList, modifierGroupsListState } from "./modifierGroups";
import { modifiersList, modifiersListState } from "./modifiers";
import { itemsListState, itemsList } from "./items";
import { taxesList, taxesListState } from "./taxes";
import { chargesList, chargesListState } from "./charges";
import { itemTagsList, itemTagsListState, tagGroupsList, tagGroupsListState } from "./itemTags";
import { usersList, usersListState } from "./users";
import {
	analyticsFiltersState,
	revenueAnalytics,
	ordersAnalytics,
	operationsAnalytics,
	catalogueAnalytics,
	analyticsEntityDetail,
	compareAnalytics
} from "./analytics";
import { unifiedUsersList, unifiedUsersListState } from "./unifiedUsers";
import { apiAccountsList, apiAccountsListState } from "./apiAccounts";
import { rolesList, rolesListState, roleDetails } from "./roles";
import { apiRolesListState, apiRolesList, apiRoleDetails } from "./apiRoles";
import { merakiDetails } from "./meraki";
import { hubConfigurations } from "./hub";
import { dspPlatformsList, dspIntegration } from "./dspIntegration";
import { customIntegrations } from "./customIntegrations";
import { discountsList, discountsListState } from "./discounts";
import { couponsList, couponsListState } from "./coupons";
import { catalogueVerificationList, catalogueVerificationListState } from "./catalogueVerification";
import { merakiWebWorkspace, merakiWebThemesList, merakiWebThemeDetails, merakiWebSubscription } from "./merakiWeb";
import { webhooksList, webhooksListState } from "./webhooks";
import { galleriesList, galleriesListState } from "./gallery";
import {
	reportsList,
	reportsNewList,
	reportDetail,
	exportReportHistory,
	reportPreview,
	scheduleReportsList,
	scheduleReportCreate,
	scheduleReportDetail,
	scheduleReportHistory
} from "./reports";
import { referralsList, referralsListState, referralStats, referralStatsState } from "./referrals";
import {
	customerFeedbackList,
	customerFeedbackListState,
	customerFeedbackNps,
	customerFeedbackNpsState
} from "./customerFeedback";
import { acceptInviteDetail } from "./acceptInvite";
import { pendingInvitesList, pendingInvitesListState } from "./pendingInvites";
import { unifiedInvitesList, unifiedInvitesListState } from "./unifiedInvites";
import { notificationsList } from "./notifications";
import { ongoingTasks } from "./ongoingTasks";
import { piperAcademyState } from "./piperAcademy";
import { auditEventsList, auditEventsListState } from "./auditEvents";
import {
	reconciliationPayoutsList,
	reconciliationPayoutsState,
	reconciliationPayoutSheetDetails,
	reconciliationStats,
	reconciliationStatsState
} from "./reconciliation";
import {
	catalogueBackupsList,
	catalogueBackupsListState,
	catalogueBackupDetailsReducer,
	catalogueBackupsStatus
} from "./catalogueBackups";
import { developerTools } from "./developerTools";
import { globalNotificationsState } from "./globalNotifications";
import { brandsListState, brandsCreationLocationAssociationState } from "./brands";
import {
	menusListState,
	menuDetailsState,
	parentSectionsState,
	publishHistoryListsData,
	menuPreviewPlatformsLocationsList,
	menuPendingChangesWarningStates,
	menuPreviewStates,
	menuPreviewValidationStatus,
	menuPublishValidationStatus,
	menuCategoriesList
} from "./menus";
import { atlasOnboardingState } from "./onboarding";
import { itemGroupsListState, itemGroupsList, itemGroupDetails } from "./itemGroups";
import { locationGroupsListState, locationGroupsList, locationGroupDetails } from "./locationGroups";
import {
	timingGroupsListState,
	timingGroupsList,
	timingGroupDetails,
	holidayScheduleDetails,
	holidayScheduleList
} from "./timingGroups";
import {
	customersList,
	customersListState,
	customerDetails,
	customerFeedbacks,
	customerNotes,
	customerTimeline
} from "./customers";
import {
	salesAnalyticsState,
	salesAnalyticsOverview,
	salesAnalyticsTrendsChannels,
	salesAnalyticsTrendsGraph,
	salesAnalyticsPerformance
} from "./salesAnalytics";
import {
	operationalEfficiencyState,
	operationalEfficiencyTransactionsListState,
	operationalEfficiencyMetricsBizLevel,
	operationalEfficiencyMetricsBizLocationLevel,
	operationalEfficiencyMetricsBizLocationLevelListState,
	operationalEfficiencyFilters,
	operationalEfficiencyListView
} from "./operations";
import {
	periscopeDashboard,
	periscopeLocationAvailabilityState,
	periscopeLocationAvailabilityData,
	periscopeItemAvailabilityState,
	periscopeItemAvailabilityData,
	periscopeSpecificItemAvailabilityItemData,
	periscopeSpecificItemAvailabilityItemState,
	periscopeSpecificItemAvailabilityLocationState,
	periscopeSpecificItemAvailabilityLocationData,
	periscopeItemIssuesState,
	periscopeItemIssuesData,
	periscopeSpecificItemIssueState,
	periscopeSpecificItemIssueData,
	periscopeActivityLogState,
	periscopeActivityLogData,
	periscopeEarlyAccessState,
	periscopeEarlyAccessData,
	periscopeDataResponse,
	periscopeFilters
} from "./periscope";
import { ActionTypes } from "../actions/_types";
import { menusPullInfoState } from "./menuPull";

const globalMessage = (
	state = {
		show: false,
		message: "",
		timeout: 2000,
		errObject: null
	},
	action
) => {
	switch (action.type) {
		case ActionTypes.SHOW_GLOBAL_MESSAGE:
			return {
				...state,
				show: true,
				...action.payload
			};
		case ActionTypes.HIDE_GLOBAL_MESSAGE:
			return {
				...state,
				show: false,
				...action.payload
			};
		default:
			return state;
	}
};

const showCloseDrawerMessage = (
	state = {
		timeout: 3000,
		showInfo: false
	},
	action
) => {
	switch (action.type) {
		case "SHOW_CLOSE_DRAWER_INSTRUCTION":
			return {
				...state,
				showInfo: true,
				...action.payload
			};
		case "HIDE_CLOSE_DRAWER_INSTRUCTION":
			return {
				...state,
				showInfo: false,
				...action.payload
			};
		default:
			return state;
	}
};

const sidebarState = (
	state = {
		active: false
	},
	action
) => {
	switch (action.type) {
		case "TOGGLE_SIDE_BAR":
			return {
				...state,
				active: action.payload
			};
		default:
			return state;
	}
};

const globalLoaderState = (
	state = {
		active: false
	},
	action
) => {
	switch (action.type) {
		case "TOGGLE_GLOBAL_LOADER":
			return {
				...state,
				active: action.payload
			};
		default:
			return state;
	}
};

const freshChatWidgetState = (
	state = {
		busy: false,
		loaded: false
	},
	action
) => {
	switch (action.type) {
		case "FRESH_WIDGET_INIT":
			return {
				...state,
				loaded: true
			};
		case "TOGGLE_FRESHCHAT_STATE":
			return {
				...state,
				busy: action.payload
			};
		default:
			return state;
	}
};

const BREADCRUMBS_INIT_STATE = {
	isBreadcrumbsEnabled: false,
	breadcrumbsArray: []
};
const breadcrumbsState = (state = BREADCRUMBS_INIT_STATE, action) => {
	switch (action.type) {
		case ActionTypes.BREADCRUMBS_ENABLE:
			return {
				...state,
				isBreadcrumbsEnabled: true
			};

		case ActionTypes.BREADCRUMBS_DISABLE:
			return {
				...state,
				isBreadcrumbsEnabled: false
			};

		case ActionTypes.BREADCRUMBS_PUSH:
			return {
				...state,
				breadcrumbsArray: [...state.breadcrumbsArray, ...action.payload]
			};

		default:
			return state;
	}
};

const appReducer = combineReducers({
	login,
	loginformState,
	banner,
	selectedModule,
	dashboardState,
	analyticsFiltersState,
	revenueAnalytics,
	ordersAnalytics,
	operationsAnalytics,
	catalogueAnalytics,
	analyticsEntityDetail,
	compareAnalytics,
	anaSummary,
	anaGraphData,
	anaOrderChannels,
	topPerfData,
	topPerfTopics,
	globalMessage,
	showCloseDrawerMessage,
	sidebarState,
	campaignListState,
	campaignList,
	campaignSideBar,
	createCampaign,
	suggestedSegmentList,
	campaignStatus,
	createSegment,
	configItems,
	globalLoaderState,
	emailTemplate,
	campaignUpdateState,
	creditsState,
	transactionsList,
	transactionsListState,
	transactionDetails,
	customersList,
	customersListState,
	customerDetails,
	customerFeedbacks,
	customerNotes,
	customerTimeline,
	salesAnalyticsState,
	salesAnalyticsOverview,
	salesAnalyticsTrendsChannels,
	salesAnalyticsTrendsGraph,
	salesAnalyticsPerformance,
	operationalEfficiencyState,
	operationalEfficiencyMetricsBizLevel,
	operationalEfficiencyMetricsBizLocationLevel,
	operationalEfficiencyMetricsBizLocationLevelListState,
	operationalEfficiencyListView,
	operationalEfficiencyTransactionsListState,
	operationalEfficiencyFilters,
	invoiceListViewState,
	invoiceDetailViewState,
	outstandingInvoicesState,
	locationsListState,
	locationDetailsReducer,
	locationsList,
	createLocationStatus,
	categoriesListState,
	categoriesList,
	categoryItems,
	itemsListState,
	itemsList,
	modifierGroupsList,
	modifierGroupsListState,
	modifiersList,
	modifiersListState,
	taxesList,
	taxesListState,
	itemGroupsListState,
	itemGroupsList,
	itemGroupDetails,
	locationGroupsListState,
	locationGroupsList,
	locationGroupDetails,
	timingGroupsListState,
	holidayScheduleList,
	timingGroupsList,
	timingGroupDetails,
	holidayScheduleDetails,
	chargesList,
	chargesListState,
	itemTagsList,
	itemTagsListState,
	tagGroupsList,
	tagGroupsListState,
	usersList,
	usersListState,
	unifiedUsersList,
	unifiedUsersListState,
	apiAccountsList,
	apiAccountsListState,
	rolesList,
	rolesListState,
	roleDetails,
	apiRolesList,
	apiRolesListState,
	apiRoleDetails,
	merakiDetails,
	hubConfigurations,
	dspPlatformsList,
	dspIntegration,
	customIntegrations,
	discountsList,
	discountsListState,
	couponsList,
	couponsListState,
	referralsList,
	referralsListState,
	referralStats,
	referralStatsState,
	catalogueVerificationList,
	catalogueVerificationListState,
	merakiWebWorkspace,
	merakiWebThemesList,
	merakiWebThemeDetails,
	merakiWebSubscription,
	webhooksList,
	webhooksListState,
	galleriesList,
	galleriesListState,
	reportsList,
	reportsNewList,
	reportDetail,
	exportReportHistory,
	reportPreview,
	scheduleReportsList,
	scheduleReportCreate,
	scheduleReportDetail,
	scheduleReportHistory,
	customerFeedbackList,
	customerFeedbackListState,
	customerFeedbackNps,
	customerFeedbackNpsState,
	acceptInviteDetail,
	pendingInvitesList,
	pendingInvitesListState,
	unifiedInvitesList,
	unifiedInvitesListState,
	notificationsList,
	ongoingTasks,
	piperAcademyState,
	auditEventsList,
	auditEventsListState,
	reconciliationPayoutsList,
	reconciliationPayoutsState,
	reconciliationPayoutSheetDetails,
	reconciliationStats,
	reconciliationStatsState,
	catalogueBackupsList,
	catalogueBackupsListState,
	catalogueBackupDetailsReducer,
	catalogueBackupsStatus,
	developerTools,
	freshChatWidgetState,
	globalNotificationsState,
	brandsListState,
	brandsCreationLocationAssociationState,
	breadcrumbsState,
	menusListState,
	menuDetailsState,
	parentSectionsState,
	publishHistoryListsData,
	menuPreviewPlatformsLocationsList,
	menuPendingChangesWarningStates,
	menuPreviewStates,
	menuPreviewValidationStatus,
	menuPublishValidationStatus,
	menuCategoriesList,

	//onboarding
	atlasOnboardingState,
	menusPullInfoState,
	periscopeDashboard,
	periscopeLocationAvailabilityState,
	periscopeLocationAvailabilityData,
	periscopeItemAvailabilityState,
	periscopeItemAvailabilityData,
	periscopeSpecificItemAvailabilityItemState,
	periscopeSpecificItemAvailabilityItemData,
	periscopeSpecificItemAvailabilityLocationState,
	periscopeSpecificItemAvailabilityLocationData,
	periscopeItemIssuesState,
	periscopeItemIssuesData,
	periscopeSpecificItemIssueState,
	periscopeSpecificItemIssueData,
	periscopeActivityLogState,
	periscopeActivityLogData,
	periscopeEarlyAccessState,
	periscopeEarlyAccessData,
	periscopeDataResponse,
	periscopeFilters
});

const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		state = undefined;
	}
	return appReducer(state, action);
};
export default rootReducer;
