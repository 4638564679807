import React, { useEffect, useState } from "react";

// store
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

// components
import Onboardingnextup, { NextUpStates } from "@urbanpiper-engineering/horizon/dist/cells/onboardingnextup";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import StatusCard, { StatusCardState } from "@urbanpiper-engineering/horizon/dist/cells/statuscard";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";
import ProgressBar from "@urbanpiper-engineering/horizon/dist/atoms/progressbar";

// utils
import history from "../history";

// constants
import { ATLAS_ONBOARDING_STEPS } from "../client-config";

const ALL_STEPS_LIST = [
	{
		id: ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS,
		state: StatusCardState.UnChecked,
		title: "Complete company details",
		subTitle: "Add your restaurant's basic information",
		onCtaClick: () => {}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CREATE_BRANDS,
		state: StatusCardState.UnChecked,
		title: "Create brands",
		subTitle: "Add all your restaurant's brands",
		onCtaClick: () => {
			history.push("/onboarding/brands");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS,
		state: StatusCardState.UnChecked,
		title: "Configure locations",
		subTitle: "Add the locations where your restaurant operates",
		onCtaClick: () => {
			history.push("/onboarding/locations");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS,
		state: StatusCardState.UnChecked,
		title: "Configure menus",
		subTitle: "Create menus for your locations",
		onCtaClick: () => {
			history.push("/onboarding/menu");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.TAXES_CHARGES,
		state: StatusCardState.UnChecked,
		title: "Configure taxes and charges",
		subTitle: "Configure all taxes and charges that are applicable on your menu item",
		onCtaClick: () => {
			history.push("/taxes/new");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS,
		state: StatusCardState.UnChecked,
		title: "Go live on all locations",
		subTitle: "Go live on delivery platforms with all your locations",
		onCtaClick: () => {
			history.push("/onboarding/go-live");
		}
	},
	{
		id: ATLAS_ONBOARDING_STEPS.INVITE_USERS,
		state: StatusCardState.UnChecked,
		title: "Invite users",
		subTitle: "Invite staff to your UrbanPiper account",
		onCtaClick: () => {
			history.push("/unified-access/invite");
		}
	}
];

const ONBOARDING_HELPER_LIST = [
	{
		title: "How does DSP sync and POS services work?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "How to set up locations and menus manually?",
		ctaText: "Watch video",
		ctaUrl: "/"
	},
	{
		title: "View the list of all our Frequently Asked Questions",
		ctaText: "View FAQs",
		ctaUrl: "/piper-academy"
	}
];

function Onboarding({ isLoading, flowData, flowSteps }) {
	const [allStepsList, setAllStepsList] = useState([]);
	const completedSteps = flowSteps?.filter((step) => step.status === "complete").length;
	const totalSteps = allStepsList.length;
	const [nextUpState, setNextUpState] = useState({
		state: NextUpStates.COMPLETE_COMPANY_DETAILS,
		onCTAClick: () => {}
	});

	const firstActiveStep = flowSteps?.find((step) => step.status === "inprogress");
	useEffect(() => {
		if (firstActiveStep?.step?.name) {
			setNextUpState({
				state: NextUpStates[firstActiveStep.step.name],
				onCTAClick: ALL_STEPS_LIST.find((step) => step.id === firstActiveStep.step.name)?.onCtaClick
			});
		}
	}, [firstActiveStep]);

	useEffect(() => {
		if (flowData?.steps?.length) {
			let _allStepsList = cloneDeep(ALL_STEPS_LIST);
			const validSteps = flowData?.steps?.map((step) => step.name) || [];
			_allStepsList = _allStepsList.filter((step) => validSteps.includes(step.id));
			setAllStepsList(_allStepsList);
		}
	}, [flowData]);

	useEffect(() => {
		if (allStepsList?.length) {
			for (let i = 0; i < allStepsList.length; i++) {
				const flowStep = flowSteps.find((step) => step?.step?.name === allStepsList[i].id);
				// if(flowStep?.status !== "complete") {
				// 	setNextUpState({
				// 		state: allStepsList[i].id,
				// 		onCTAClick: allStepsList[i].onCtaClick
				// 	});
				// 	break;
				// }
			}
		}
	}, [flowSteps]);

	const getStepState = (stepId) => {
		if (flowSteps?.length) {
			const step = flowSteps.find((step) => step?.step?.name === stepId);
			if (step) {
				return step.status === "active"
					? StatusCardState.UnChecked
					: step.status === "inprogress"
					? StatusCardState.Pending
					: StatusCardState.Checked;
			}
		}
		return StatusCardState.UnChecked;
	};

	const getInfoComponent = (stepId) => {
		if (flowSteps?.length) {
			const step = flowSteps.find((step) => step?.step?.name === stepId);
			switch (step?.step?.name) {
				case ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS:
					return step?.status === "complete" ? (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-complete-chip" variant={TextVariant.BODY1}>
								Completed
							</Text>
						</div>
					) : null;

				case ATLAS_ONBOARDING_STEPS.CREATE_BRANDS:
					const numberOfBrandsCreated = step?.metadata?.completed || 0;
					return numberOfBrandsCreated ? (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
								<div className="step-progress-indicator">{/* indicator empty div */}</div>{" "}
								{numberOfBrandsCreated} {numberOfBrandsCreated === 1 ? "brand" : "brands"} created
							</Text>
						</div>
					) : null;

				case ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS:
					const numberOfTotalLocations = step?.metadata?.total || 0;
					const numberOfCompletedLocations = step?.metadata?.completed || 0;

					if (["inprogress", "complete"].includes(step?.status)) {
						if (numberOfTotalLocations === numberOfCompletedLocations && step?.status === "complete") {
							return (
								<div className="onboarding-step__info-component">
									<Text className="onboarding-step-complete-chip" variant={TextVariant.BODY1}>
										Completed
									</Text>
								</div>
							);
						} else if (numberOfTotalLocations > 0) {
							return (
								<div className="onboarding-step__info-component">
									<div className="onboarding-step__progress-bar-wrapper">
										<ProgressBar className="progress-bar" height={7} progress={100} width={40} />
										<ProgressBar className="progress-bar" height={7} progress={0} width={60} />
										<Text
											className="onboarding-step-locations-text"
											variant={TextVariant.BODY1}
										>{`${numberOfCompletedLocations} of ${numberOfTotalLocations} brand-locations created`}</Text>
									</div>
								</div>
							);
						}
					}
					return null;

				case ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS:
					const numberOfTotalMenus = step?.metadata?.total || 0;
					return (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
								<div className="step-progress-indicator">{/* indicator empty div */}</div>{" "}
								{numberOfTotalMenus} {numberOfTotalMenus === 1 ? "menu" : "menus"} created
							</Text>
						</div>
					);

				case ATLAS_ONBOARDING_STEPS.TAXES_CHARGES:
					return (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-text" variant={TextVariant.BODY1}>
								<div className="step-progress-indicator">{/* indicator empty div */}</div>{" "}
							</Text>
						</div>
					);

				case ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS:
					const numberOfTotalLocationsToGoLive = step?.metadata?.total || 0;
					const numberOfLocationsLive = step?.metadata?.completed || 0;
					if (["inprogress", "complete"].includes(step?.status)) {
						if (numberOfTotalLocationsToGoLive === numberOfLocationsLive && step.status === "complete") {
							return (
								<div className="onboarding-step__info-component">
									<Text className="onboarding-step-complete-chip" variant={TextVariant.BODY1}>
										Completed
									</Text>
								</div>
							);
						} else if (numberOfTotalLocationsToGoLive > 0) {
							return (
								<div className="onboarding-step__info-component">
									<div className="onboarding-step__progress-bar-wrapper">
										<ProgressBar className="progress-bar" height={7} progress={100} width={40} />
										<ProgressBar className="progress-bar" height={7} progress={0} width={60} />
										<Text
											className="onboarding-step-locations-text"
											variant={TextVariant.BODY1}
										>{`${numberOfLocationsLive} of ${numberOfTotalLocationsToGoLive} locations live`}</Text>
									</div>
								</div>
							);
						}
					}
					return null;

				case ATLAS_ONBOARDING_STEPS.INVITE_USERS:
					return step?.status === "complete" ? (
						<div className="onboarding-step__info-component">
							<Text className="onboarding-step-complete-chip" variant={TextVariant.BODY1}>
								Completed
							</Text>
						</div>
					) : null;

				default:
					return null;
			}
		}
	};

	return (
		<div className="onboarding-container section-container-common">
			{isLoading ? (
				<div className="onboarding-loader">
					<div className="P(10px)">
						<div className="shimmer H(100px) Mb(20px)" />
						<div className="shimmer H(200px) Mb(10px)" />
					</div>
				</div>
			) : (
				<React.Fragment>
					<div className="onboarding-step">
						<Text className="onboarding-step-heading" variant={TextVariant.HEADING4}>
							Complete setup to get started with UrbanPiper
						</Text>
						<Onboardingnextup
							className="onboarding-nextup-card"
							currentState={nextUpState.state}
							onCtaClick={nextUpState.onCTAClick || (() => {})}
							isComplete={totalSteps === completedSteps}
						/>
					</div>
					<div className="onboarding-all-steps">
						<Text className="onboarding-all-steps-heading" variant={TextVariant.HEADING4}>
							{`${completedSteps} of ${totalSteps} steps completed`}
						</Text>
						<div className="onboarding-all-steps-list">
							{allStepsList.map((step) => {
								return (
									<StatusCard
										key={step.title}
										className="onboarding-status-card"
										state={getStepState(step.id)}
										title={step.title}
										subTitle={step.subTitle}
										onCtaClick={() =>
											getStepState(step.id) !== StatusCardState.UnChecked && step.onCtaClick()
										}
										infoComponent={getInfoComponent(step.id)}
									/>
								);
							})}
						</div>
					</div>

					<div className="onboarding-helper">
						<Text className="onboarding-helper-heading" variant={TextVariant.HEADING4}>
							Don’t know where to start?
						</Text>

						<div className="onboarding-helper-list">
							{ONBOARDING_HELPER_LIST.map((helper) => {
								return (
									<div key={helper.title} className="onboarding-helper-card">
										<img
											className="onboarding-helper-card-icon"
											src="/assets/icons/icon-question-two.svg"
										/>
										<Text className="onboarding-helper-card-title" variant={TextVariant.HEADING5}>
											{helper.title}
										</Text>
										<Button
											onClick={() => history.push(helper.ctaUrl)}
											className="onboarding-helper-card-cta"
											variant={ButtonVariant.Link}
											size={ButtonSize.MD}
											rightIcon={
												<img
													className="onboarding-helper-card-cta-icon"
													src="/assets/icons/icon-right-two.svg"
												/>
											}
										>
											{helper.ctaText}
										</Button>
									</div>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	isLoading: store.atlasOnboardingState.isLoading,
	flowData: store.atlasOnboardingState.flowData,
	flowSteps: store.atlasOnboardingState.flowSteps
});

export default connect(mapStateToProps)(Onboarding);
