import React, { useEffect, useState } from "react";
import Image from "./Image";

const StatusBox = ({ status, menuInfo = {}, handleDrawerOpen = () => {} }) => {
	const [isOpen, setIsOpen] = useState(true);
	useEffect(() => setIsOpen(true), [status]);

	const getStatusInfo = () => {
		switch (status) {
			case "inprogress":
				return {
					header: "Menu Import in Progress",
					description: "Your menu is currently being imported and will be updated shortly.",
					imgSrc: "/assets/icons/info.png"
				};
			case "failed":
				return {
					header: "Menu Import Failed",
					description: menuInfo?.message || "Please try again later.",
					imgSrc: "/assets/icons/error-red-icon.svg"
				};
			case "success":
				return {
					header: "Menu Import Completed",
					description: "You can now view your menu.",
					imgSrc: "/assets/icons/success-green-icon.svg"
				};
			default:
				return {
					header: "",
					description: "",
					imgSrc: ""
				};
		}
	};

	const { header, description, imgSrc } = getStatusInfo();

	return (
		<>
			{isOpen && (
				<div className={`status-box-wrapper ${status}`}>
					<div className="details-wrapper">
						<Image className="status-box-img" src={imgSrc} alt="Status Icon" />
						<div className="details">
							<p className="detail-header">{header}</p>
							<p className="detail-description">{description}</p>
							{status === "failed" &&
								menuInfo?.report &&
								menuInfo?.report?.structuredIssues?.length > 0 && (
									<span
										className="hyperlink"
										style={{ width: "fit-content" }}
										onClick={() => {
											handleDrawerOpen();
										}}
									>
										View menu errors <img src="/assets/icons/ic_right_arrow_blue.svg" />
									</span>
								)}
						</div>
					</div>
					{status !== "inprogress" && (
						<Image
							className="close-icon"
							src="/assets/icons/cancel.png"
							alt="remove rule attribute"
							onClick={() => setIsOpen(false)}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default StatusBox;
