import React, { useEffect, useState } from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";
import { CustomTable } from "../_commons/CustomTable";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import MonogramGenerator from "../_commons/MonogramGenerator";

// third party
import { connect } from "react-redux";
import _debounce from "lodash/debounce";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchCitiesList } from "../../actions/locations";

// utils
import { lS } from "../../atlas-utils";

// constants
import { countrySpecificPopularCities } from "../../client-config";
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { CONFIGURE_LOCATIONS_MANUALLY_STEPS } from "../../containers/OnboardingLocations";
const ADD_LOCATION_DETAILS_COLUMN = [
	{
		name: "Name",
		field: "name",
		isRequired: true,
		render: (record, i, rest) => (
			<div className="table-cell name" key={i}>
				<InputWithLabel
					value={record.bizLocationNickname}
					onChange={(e) => rest.handleChange("bizLocationNickname", e.target.value, rest.rowIndex)}
					placeholder={rest.rowIndex === 0 ? "Ex: Indiranagar Store" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.bizLocationNickname || ""}
				/>
			</div>
		)
	},
	{
		name: "Address",
		field: "address",
		render: (record, i, rest) => (
			<div className="table-cell address" key={i}>
				<InputWithLabel
					value={record.bizAddress}
					onChange={(e) => rest.handleChange("bizAddress", e.target.value, rest.rowIndex)}
					placeholder={rest.rowIndex === 0 ? "Ex: Unit#123, Forum Mall, Indiranagar" : ""}
					validationMessage={rest.validations?.[rest.rowIndex]?.bizAddress || ""}
				/>
			</div>
		)
	},
	{
		name: "City",
		field: "city",
		isRequired: true,
		render: (record, i, rest) => {
			const cityValidationMessage = rest.validations?.[rest.rowIndex]?.city;
			return (
				<div className="table-cell city" key={i}>
					<SelectFilter
						options={rest.citiesList.items}
						isLoading={rest.citiesList.isLoading}
						field="city"
						currValue={record.city}
						setFilter={(field, value) => rest.handleChange(field, value, rest.rowIndex)}
						labelKey="valueForDisplay"
						valueKey="value"
						isAsync={true}
						handleSearch={rest.searchCitiesDebounced}
					/>
					{cityValidationMessage ? (
						<span className="city-validation-message">{cityValidationMessage}</span>
					) : null}
				</div>
			);
		}
	}
];

const CONFIGURE_LOCATIONS_PLATFORMS_COLUMN = [
	{
		name: "LOCATIONS",
		field: "locations",
		render: (record, i, rest) => {
			return (
				<div className="table-cell name" key={i}>
					<Text variant={TextVariant.Body1} className="location-name">
						{record?.bizLocationNickname || ""}
					</Text>
				</div>
			);
		}
	},
	{
		name: "BRANDS",
		field: "brands",
		render: (record, i, rest) => {
			const brands = record?.brands ?? [];
			return (
				<div className="table-cell brands" key={i}>
					{brands.map((brand, j) => {
						return (
							<div className="brand-info" key={j}>
								<div className="brand-container">
									{brand?.image ? (
										<img className="brand-image" src={brand?.image} alt={brand.name} />
									) : (
										<MonogramGenerator value={brand.name} />
									)}
									<Text variant={TextVariant.Body1} className="brand-name">
										{brand.name}
									</Text>
								</div>
								{brand?.platforms?.length > 1 &&
									brand?.platforms
										?.slice(0, -1)
										?.map((plf, k) => <div className="brand-container" key={k}></div>)}
							</div>
						);
					})}
				</div>
			);
		}
	},
	{
		name: "PLATFORM",
		field: "aggr-platforms",
		render: (record, i, rest) => {
			const platforms = record?.platforms ?? [];
			const brands = record?.brands ?? [];
			const isMultibrandEnabled = rest.isMultibrandEnabled || false;

			return (
				<div className="table-cell aggr-platforms" key={i}>
					{isMultibrandEnabled ? (
						brands?.map((brand, j) => (
							<div className="brand-platforms" key={j}>
								{brand?.platforms?.map((plf, k) => (
									<div className="plf-name" key={k}>
										<Text variant={TextVariant.Body1} className="platform-name">
											{plf?.platformName}
										</Text>
									</div>
								))}
							</div>
						))
					) : (
						<div className="brand-platforms">
							{platforms?.map((plf, j) => (
								<div className="plf-name" key={j}>
									<Text variant={TextVariant.Body1} className="platform-name">
										{plf?.platformName}
									</Text>
								</div>
							))}
						</div>
					)}
				</div>
			);
		}
	},
	{
		name: "Platform ID",
		field: "platform-id",
		render: (record, i, rest) => {
			const isMultibrandEnabled = rest.isMultibrandEnabled || false;
			const platforms = record?.platforms ?? [];
			const brands = record?.brands ?? [];
			return (
				<div className="table-cell platform-id" key={i}>
					{isMultibrandEnabled ? (
						brands?.map((brand, j) => (
							<div className="brand-platforms" key={j}>
								{brand?.platforms?.map((plf, k) => (
									<InputWithLabel
										key={k}
										value={plf.platformId}
										type="number"
										onChange={(e) =>
											rest.handleChange(
												"platformId",
												e.target.value,
												plf.id,
												brand.id,
												rest.rowIndex
											)
										}
										validationMessage={
											rest.validations?.[rest.rowIndex]?.platforms?.[brand.id]?.[plf.id]
												?.platformId || ""
										}
									/>
								))}
							</div>
						))
					) : (
						<div className="brand-platforms">
							{platforms?.map((plf, j) => (
								<InputWithLabel
									key={j}
									value={plf.platformId}
									type="number"
									onChange={(e) =>
										rest.handleChange("platformId", e.target.value, plf.id, null, rest.rowIndex)
									}
									validationMessage={
										rest.validations?.[rest.rowIndex]?.platforms?.[plf.id]?.platformId || ""
									}
								/>
							))}
						</div>
					)}
				</div>
			);
		}
	},
	{
		name: "Platform URL",
		field: "platform-url",
		render: (record, i, rest) => {
			const platforms = record?.platforms ?? [];
			const brands = record?.brands ?? [];
			const isMultibrandEnabled = rest.isMultibrandEnabled || false;

			return (
				<div className="table-cell platform-url" key={i}>
					{isMultibrandEnabled ? (
						brands?.map((brand, j) => (
							<div className="brand-platforms" key={j}>
								{brand?.platforms?.map((plf, k) => (
									<InputWithLabel
										key={k}
										value={plf.platformUrl}
										onChange={(e) =>
											rest.handleChange(
												"platformUrl",
												e.target.value,
												plf.id,
												brand.id,
												rest.rowIndex
											)
										}
										validationMessage={
											rest.validations?.[rest.rowIndex]?.platforms?.[brand.id]?.[plf.id]
												?.platformUrl || ""
										}
									/>
								))}
							</div>
						))
					) : (
						<div className="brand-platforms">
							{platforms?.map((plf, j) =>
								plf.platformName !== "Meraki" ? (
									<InputWithLabel
										key={j}
										value={plf.platformUrl}
										onChange={(e) =>
											rest.handleChange(
												"platformUrl",
												e.target.value,
												plf.id,
												null,
												rest.rowIndex
											)
										}
										validationMessage={
											rest.validations?.[rest.rowIndex]?.platforms?.[plf.id]?.platformUrl || ""
										}
									/>
								) : null
							)}
						</div>
					)}
				</div>
			);
		}
	}
];

function ConfigureManually({
	bizPlatforms,
	bizCountry,
	addLocationDetailsFormValidations,
	locationFormData,
	handleAddLocationDetailsFormData,
	handleAddMoreRows,
	selectedPlatforms,
	setSelectedPlatforms,
	selectedMethodStep,
	setSelectedMethodStep,
	handlePLAFormData,
	isMultibrandEnabled,
	PLAFormValidations
}) {
	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry?.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? [])];
	const [addMoreRows, setAddMoreRows] = useState(5);
	const [citiesList, setCitiesList] = useState({
		isLoading: false,
		items: INIT_CITY_ARRAY_VALUES
	});

	const searchCities = async (searchCity) => {
		if (searchCity === "") {
			setCitiesList({
				isLoading: false,
				items: INIT_CITY_ARRAY_VALUES
			});
			return;
		}
		setCitiesList({
			...citiesList,
			isLoading: true
		});
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesList({
				isLoading: false,
				items: [
					...updatedCitiesList.map((city) => ({
						value: city.name,
						valueForDisplay: `${city.name}, ${city.state_name}, ${city.country_name}`
					}))
				]
			});
		} else {
			setCitiesList({
				...citiesList,
				isLoading: false
			});
		}
	};
	const searchCitiesDebounced = _debounce((searchCity) => searchCities(searchCity), 300);

	const handlePlatformsCheck = (state, field, option) => {
		const updatedPlatforms = { ...selectedPlatforms };
		updatedPlatforms[option.id] = state;
		setSelectedPlatforms(updatedPlatforms);
	};

	return (
		<div className="configure-manually">
			<Text variant={TextVariant.HEADING4} className="configure-manually__title">
				{selectedMethodStep === CONFIGURE_LOCATIONS_MANUALLY_STEPS.ADD_LOCATION_DETAILS
					? "Add Location Details"
					: selectedMethodStep === CONFIGURE_LOCATIONS_MANUALLY_STEPS.SELECT_PLATFORMS
					? "Select Platforms"
					: "Configure Locations Platforms"}
			</Text>
			{selectedMethodStep === CONFIGURE_LOCATIONS_MANUALLY_STEPS.ADD_LOCATION_DETAILS ? (
				<AddLocationDetails
					locationFormData={locationFormData}
					handleAddLocationDetailsFormData={handleAddLocationDetailsFormData}
					citiesList={citiesList}
					searchCitiesDebounced={searchCitiesDebounced}
					addLocationDetailsFormValidations={addLocationDetailsFormValidations}
					setAddMoreRows={setAddMoreRows}
					handleAddMoreRows={handleAddMoreRows}
					addMoreRows={addMoreRows}
				/>
			) : (
				<SelectAndConfigurePlatforms
					bizPlatforms={bizPlatforms}
					platformUpdates={selectedPlatforms}
					locationFormData={locationFormData}
					handleCheck={handlePlatformsCheck}
					selectedMethodStep={selectedMethodStep}
					handlePLAFormData={handlePLAFormData}
					isMultibrandEnabled={isMultibrandEnabled}
					PLAFormValidations={PLAFormValidations}
				/>
			)}
		</div>
	);
}

const AddLocationDetails = ({
	locationFormData,
	handleAddLocationDetailsFormData,
	citiesList,
	searchCitiesDebounced,
	addLocationDetailsFormValidations,
	addMoreRows,
	setAddMoreRows,
	handleAddMoreRows
}) => {
	return (
		<React.Fragment>
			<CustomTable
				columns={ADD_LOCATION_DETAILS_COLUMN}
				data={locationFormData}
				handleChange={handleAddLocationDetailsFormData}
				citiesList={citiesList}
				searchCitiesDebounced={searchCitiesDebounced}
				validations={addLocationDetailsFormValidations}
				classes="configure_manually__add-location-details-table"
			/>

			<div className="configure-manually__add-more-rows">
				<InputWithLabel
					value={addMoreRows}
					type="number"
					onChange={(e) =>
						setAddMoreRows(
							e.target.value && parseInt(e.target.value) <= 100 ? parseInt(e.target.value) : ""
						)
					}
				>
					Add more rows
				</InputWithLabel>

				<Button
					className="add-more__button"
					size={ButtonSize.MD}
					variant={ButtonVariant.PRIMARY}
					onClick={() => handleAddMoreRows(addMoreRows)}
				>
					Add
				</Button>
			</div>
		</React.Fragment>
	);
};

const SelectAndConfigurePlatforms = ({
	bizPlatforms,
	platformUpdates,
	handleCheck,
	locationFormData,
	selectedMethodStep,
	handlePLAFormData,
	isMultibrandEnabled,
	PLAFormValidations
}) => {
	const getCurrValue = () => {
		return bizPlatforms.items?.filter((platform) => platformUpdates[platform.id]);
	};
	return (
		<div className="select-and-configure-platforms">
			<SelectFilterCustom
				classes="select-platforms"
				title="Select Platforms"
				options={bizPlatforms.items}
				isLoading={bizPlatforms.isLoading}
				field="platforms"
				currValue={getCurrValue()}
				renderLabel={handleDropdownLabel}
				labelKey={"platformName"}
				valueKey={"id"}
				customLabel={true}
				iconKey="image"
				showIcon={true}
				multi={true}
				showCheckBox={true}
				showSelectAllOption={false}
				optionUpdates={{ platforms: platformUpdates }}
				handleCheck={handleCheck}
				placeholder="Select one or more"
				isSearchable={false}
			/>

			{selectedMethodStep === CONFIGURE_LOCATIONS_MANUALLY_STEPS.CONFIGURE_LOCATIONS_PLATFORMS ? (
				<div className="configure-platforms">
					<CustomTable
						classes="configure-platforms__table multi"
						columns={CONFIGURE_LOCATIONS_PLATFORMS_COLUMN}
						data={locationFormData}
						handleChange={handlePLAFormData}
						hideColumns={isMultibrandEnabled ? [] : ["brands"]}
						isMultibrandEnabled={isMultibrandEnabled}
						validations={PLAFormValidations}
					/>
				</div>
			) : null}
		</div>
	);
};

const handleDropdownLabel = (currValue, handleCheck, field, props) => {
	return (
		<React.Fragment>
			{currValue?.map?.((platform, i) => (
				<div className="platform-tag" key={i}>
					<Text variant={TextVariant.Body1} className="platform-text">
						{platform?.platformName}
					</Text>
					<img
						className="platform-cross-icon"
						onClick={(e) => {
							handleCheck(false, field, platform, props);
							e.stopPropagation();
						}}
						src="/assets/icons/icon-cross-blue.svg"
						alt="cross"
					/>
				</div>
			))}
		</React.Fragment>
	);
};

const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	bizCountry: store.login.loggedInbizDetail.country,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	bizPlatforms: store?.configItems.bizPlatforms
});

export default connect(mapStateToProps)(ConfigureManually);
