import React, { useEffect, useMemo, useRef } from "react";
import CreateIcon from "../../_commons/CreateIcon";
import { connect } from "react-redux";
import { fetchBizPlatforms, fetchBrands } from "../../../actions/analytics";
import SelectInput from "./SelectInput";
import { ButtonIcon } from "../../_commons/ButtonIcon";
import { store } from "../../../store/configureStore";
import { extractInitials, nthFyDate, trackEvent } from "../../../atlas-utils";
import Store from "../../_icons/Store";
import Item from "../../_icons/Item";
import Platform from "../../_icons/Platform";
import Brand from "../../_icons/Brand";
import { fetchItems, fetchLocationGroups, fetchLocationList } from "../../../actions/analytics";

const EnterLocation = ({
	colIndex,
	configItems,
	metric,
	compareAnalytics,
	handleSelect,
	setDropDownStatus,
	dropDownStatus,
	analyticsFiltersState
}) => {
	const columnText = compareAnalytics.columnData[metric]?.data;
	const count = compareAnalytics.columnData[metric]?.count;

	let columnData = compareAnalytics.columnData[metric]?.data;

	let tableData = compareAnalytics.tableData?.[metric];

	const brandsArray = configItems?.brands?.items;
	const bizPlatformsArray = configItems?.bizPlatforms?.items;
	const itemsArray = compareAnalytics?.itemList?.objects;
	const locationArray = compareAnalytics?.locationList?.objects;
	const loadingColumnData = compareAnalytics?.columnData?.loading;

	const dropDownMetric = useMemo(
		() => ({
			brand: {
				searchText: true,
				details: [
					{
						info: brandsArray,
						displayImages: true,
						valueForDisplay: "name",
						value: "id",
						image: "image",
						loading: configItems?.brands?.isLoading,
						error: configItems?.brands?.error
					}
				]
			},
			platform: {
				searchText: true,
				details: [
					{
						info: bizPlatformsArray,
						valueForDisplay: "platformName",
						value: "id",
						image: "image",
						displayImages: true,
						loading: configItems?.bizPlatforms?.isLoading,
						error: configItems?.bizPlatforms?.error
					}
				]
			},
			item: {
				searchText: true,
				details: [
					{
						info: itemsArray,
						valueForDisplay: "title",
						value: "id",
						displayImages: false,
						loading: compareAnalytics?.itemList?.loading,
						error: compareAnalytics?.itemList?.error
					}
				]
			},
			location: {
				searchText: true,
				details: [
					// {
					// 	heading: "Location",
					// 	info: locationArray,
					// 	valueForDisplay: "title",
					// 	value: "id",
					// 	anotherValueDisplay: "city",
					// 	visibleRowCount: 3
					// },
					{
						heading: "Locations",
						info: locationArray,
						valueForDisplay: "title",
						value: "id",
						anotherValueDisplay: "city",
						loading: compareAnalytics?.locationList?.loading,
						error: compareAnalytics?.locationList?.error
					}
				]
			}
		}),
		[
			configItems?.brands,
			configItems?.bizPlatforms,
			compareAnalytics?.itemList,
			compareAnalytics?.locationList,
			metric
		]
	);

	const headerRef = useRef(null);

	const fetchConfig = () => {
		const fetchFunctions = {
			brand: () => fetchBrands("", true),
			platform: () => fetchBizPlatforms(false, true, true),
			item: () => fetchItems("", 50, 0),
			location: () => fetchLocationList("", 50, 0)
			// location: () => (fetchLocationList("", 50, 0) fetchLocationGroups())
		};
		const fetchFunction = fetchFunctions[metric];
		if (fetchFunction) {
			fetchFunction();
		}
	};
	useEffect(() => {
		fetchConfig();
	}, [metric, columnData, dropDownStatus]);
	const columnNames =
		compareAnalytics?.columnData?.[metric]?.data?.slice(1).map((curr) => curr?.["valueForDisplay"]) || [];

	const handleClose = (event, index) => {
		event.stopPropagation();
		const removedCol = columnData.splice(index, 1);
		let value = columnNames.reduce((acc, curr) => {
			if (removedCol?.[0]?.["valueForDisplay"] !== curr) {
				acc[curr] = tableData[curr];
			}
			return acc;
		}, {});

		store.dispatch({
			type: "UPDATE_COMPARE_ANALYTICS_CHANGE_COLUMN_DATA",
			payload: {
				metric,
				data: columnData,
				count: count - 1
			}
		});

		store.dispatch({
			type: "DELETE_COMPARE_ANALYTICS_TABLE_DATA",
			payload: { metric, data: value }
		});
		const { initialFiltersFromUrl } = analyticsFiltersState;
	};

	const images = {
		location: (variations) => <Store variations={variations} />,
		item: (variations) => <Item variations={variations} />,
		platform: (variations) => <Platform variations={variations} />,
		brand: (variations) => <Brand variations={variations} />
	};

	const handleOption = (image = "", color = "", name) => {
		if (name === "Prime") {
			image = "/assets/icons/prime-logo.svg";
		}
		return (
			<React.Fragment>
				<div className={"main-logo " + color}>
					{image ? <img src={image} alt="" /> : extractInitials(name?.split(" "))}
				</div>
			</React.Fragment>
		);
	};

	if (colIndex == 0) {
		return <div className="table-location-header main-text location">{columnText[0]}</div>;
	} else {
		const valueForDisplay = columnText[colIndex]?.valueForDisplay;
		return (
			<>
				<div className={`table-location-header ${metric ? metric : ""} `}>
					{valueForDisplay ? (
						<div
							className={` ${dropDownStatus == colIndex ? "dropdown-top" : ""}  main-text   `}
							onClick={() => handleSelect(colIndex)}
						>
							<div className="text-img">
								{metric == "platform" || metric == "brand" ? (
									<>
										{handleOption(
											columnText[colIndex]?.image,
											columnText[colIndex]?.color,
											valueForDisplay
										)}
									</>
								) : (
									<>
										<span className="img">{images?.[metric]({ fill: "#363636" })}</span>
									</>
								)}
								{valueForDisplay}
							</div>
							<div className="close-icon" onClick={(event) => handleClose(event, colIndex)}>
								<ButtonIcon icon={"close"} color={"#374F78"} />
							</div>
						</div>
					) : (
						<>
							{loadingColumnData ? (
								<div className={`${"shimmer"} W${100}%`} style={{ height: "33px" }} />
							) : (
								<div className="adding-data" onClick={() => handleSelect(colIndex)}>
									<div className="create">
										<CreateIcon color={"#8A8A8A"} />
										<span>{` Add ${nthFyDate(colIndex)} ${metric}`}</span>
									</div>
									<span className="menus-icon-div">{images?.[metric]({ fill: "#AAAAAA" })}</span>
								</div>
							)}
						</>
					)}

					{dropDownStatus == colIndex && (
						<div ref={headerRef}>
							{
								<SelectInput
									dropDownMetric={dropDownMetric}
									metric={metric}
									inputBox={true}
									setDropDownStatus={setDropDownStatus}
									dropDownStatus={dropDownStatus}
									colIndex={colIndex}
								/>
							}
						</div>
					)}
				</div>
			</>
		);
	}
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	compareAnalytics: state.compareAnalytics,
	analyticsFiltersState: state.analyticsFiltersState
});

export default connect(mapStateToProps)(EnterLocation);
