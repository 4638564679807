import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import MonogramGenerator from "../_commons/MonogramGenerator";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";

function ChooseMenuPullSource({ selectedBrand, dspPlatformsList = [], handlePlatformSelection = () => {} }) {
	return (
		<div className="onboarding-menus__choose-menu-pull-source">
			{selectedBrand?.id ? (
				<div className="selected-brand">
					<Text variant={TextVariant.HEADING4} className="">
						{`Select a service that best represents your menu for ${selectedBrand?.name}`}
					</Text>
					<div className="selected-brand__display">
						{selectedBrand.image ? (
							<img src={selectedBrand.image} alt={selectedBrand?.name} className="logo" />
						) : (
							<MonogramGenerator value={selectedBrand?.name} className="logo" />
						)}
						<Text variant={TextVariant.BODY1} className="brand-title">
							{" "}
							{selectedBrand.name && selectedBrand.name.length > 25
								? selectedBrand.name.slice(0, 25) + "..."
								: selectedBrand.name}
						</Text>
					</div>
				</div>
			) : null}

			<div className="food-delivery-platform">
				<Text variant={TextVariant.HEADING4} className="food-delivery-platform__heading">
					Food Delivery Platform
				</Text>
				{dspPlatformsList?.objects?.length ? (
					<React.Fragment>
						<div className="food-delivery-platform__list">
							{dspPlatformsList.objects.map((dspPlatform) => {
								return (
									<DspPlatformCard
										name={PLATFORM_MAP[dspPlatform?.platform] || dspPlatform?.platform}
										handlePlatformSelection={() => handlePlatformSelection(dspPlatform)}
									/>
								);
							})}
						</div>
					</React.Fragment>
				) : (
					<Text variant={TextVariant.BODY1}>Something is off, please try again</Text>
				)}
			</div>
		</div>
	);
}

function DspPlatformCard({ name = "", handlePlatformSelection = () => {} }) {
	return (
		<div onClick={handlePlatformSelection} className="dsp-platform-card">
			<img
				className="dsp-platform-card__image"
				src={CATALOGUE_PLATFORMS_LOGO[name] || "/assets/icons/icons8-globe-40.png"}
				alt=""
			/>
			<Text variant={TextVariant.BODY1} className="dsp-platform-card__name">
				{name}
			</Text>
			<img src="/assets/icons/icon-right-angled-arrow.svg" alt="right arrow" className="right-arrow-icon" />
		</div>
	);
}

export default ChooseMenuPullSource;
