// store
import { store } from "../store/configureStore";

// utils
import { formatDate } from "../atlas-utils";
import moment from "moment";

/**
 * for line charts with compare date filter
 */
export const getReadableDateFilter = (compare = false) => {
	const { appliedDateFilter } = store.getState().analyticsFiltersState;
	const currTimestamp = appliedDateFilter?.current?.dateFilter;
	const compTimestamp = appliedDateFilter?.compare?.dateFilter;

	let readableDateFilter = "";
	const presetMap = {
		TODAY: "Today",
		YESTERDAY: "Yesterday",
		THIS_WEEK: "This Week",
		THIS_MONTH: "This Month",
		THIS_YEAR: "This Year",
		LAST_7_DAYS: "Last 7D",
		LAST_15_DAYS: "Last 15D",
		LAST_30_DAYS: "Last 30D",
		LAST_90_DAYS: "Last 90D"
	};
	const comparePresetMap = {
		TODAY: "Yesterday",
		YESTERDAY: "Day Before Yesterday",
		THIS_WEEK: "Last Week",
		THIS_MONTH: "Last Month",
		THIS_YEAR: "Last Year",
		LAST_7_DAYS: "Prev 7D",
		LAST_15_DAYS: "Prev 15D",
		LAST_30_DAYS: "Prev 30D",
		LAST_90_DAYS: "Prev 90D"
	};

	if (compare) {
		if (comparePresetMap[currTimestamp]) {
			readableDateFilter = comparePresetMap[currTimestamp];
		} else {
			const dates = compTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	} else {
		if (presetMap[currTimestamp]) {
			readableDateFilter = presetMap[currTimestamp];
		} else {
			const dates = currTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	}
	return readableDateFilter;
};

export const parseValue = (value) => {
	const parsedValue = parseFloat(value);
	return isNaN(parsedValue) ? 0 : parsedValue;
};

export const calculatePercentages = (arrayValues, value = "value", sort = true) => {
	const totalSum = arrayValues.reduce((sum, val) => sum + parseValue(val?.[value]), 0);
	const percentages =
		totalSum === 0
			? arrayValues.map(() => 0)
			: arrayValues.map((val) => roundAndFormatNumber((parseValue(val?.[value]) / totalSum) * 100, 1));

	const finalValues = arrayValues.map((val, index) => {
		return {
			...val,
			percentage: percentages[index],
			value: Math.round(val?.[value])
		};
	});
	if (sort) {
		finalValues.sort((a, b) => (a[value] || 0) - (b[value] || 0));
	}
	return finalValues;
};

export const roundAndFormatNumber = (number, decimalPlaces = 2) => {
	if (isNaN(number)) return "0";
	let roundedNumber = number.toFixed(decimalPlaces);
	let formattedNumber = roundedNumber.replace(/(\.\d*[1-9])0+$|\.0*$/, "$1");
	return formattedNumber;
};

export const updatedFilters = (filters, updates) => {
	return updates.reduce(
		(acc, answer) => {
			const { field, value } = answer;
			if (field === "location_id") {
				acc.appliedFilters.location_id = value.split(",");
			} else if (field === "brand_id") {
				acc.appliedFilters.brand_id = value.split(",");
			} else if (field === "platform_names") {
				acc.appliedFilters.platform_names = value.split(",");
			}
			return acc;
		},
		{ ...filters }
	);
};
export const updateDurationFilter = (filters, update) => {
	if (update.custom) {
		const custom = update.custom;
		let datefilter = custom.startDate + "," + custom.endDate;
		filters.appliedDateFilter.current.dateFilter = datefilter;
		filters.appliedDateFilter.current.dateTypeSelected = {
			label: "Custom",
			value: "range"
		};
		filters.appliedDateFilter.current.presetTypeSelected = {
			label: "",
			value: "",
			title: "",
			meta_info: ""
		};
	}
	if (update?.preset) {
		filters.appliedDateFilter.current = dateKeys?.[update.preset];
	}
	return filters;
};

const createObjectDateKey = (timeFrame, label, title, metaInfo) => ({
	dateFilter: timeFrame,
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: `This ${label}`,
		value: timeFrame,
		title: title,
		meta_info: metaInfo
	}
});

const constructDateResponse = (text) => ({
	dateFilter: text.toUpperCase(),
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: text.charAt(0).toUpperCase() + text.slice(1),
		value: text.toUpperCase(),
		title: text.charAt(0).toUpperCase() + text.slice(1)
	}
});

const createDateKey = (days, title) => ({
	dateFilter: `LAST_${days}_DAYS`,
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: `${days} D`,
		value: `LAST_${days}_DAYS`,
		title: title,
		meta_info: `${days} days since`
	}
});

export const dateKeys = {
	LAST_90_DAYS: createDateKey(90, "Last 90 days"),
	LAST_30_DAYS: createDateKey(30, "Last 30 days"),
	LAST_15_DAYS: createDateKey(15, "Last 15 days"),
	LAST_7_DAYS: createDateKey(7, "Last 7 Days"),
	THIS_YEAR: createObjectDateKey("THIS_YEAR", "year", "This year", "A year since"),
	THIS_MONTH: createObjectDateKey("THIS_MONTH", "month", "This month", "A month since"),
	THIS_WEEK: createObjectDateKey("THIS_WEEK", "week", "This week", "A week since"),
	YESTERDAY: constructDateResponse("yesterday"),
	TODAY: constructDateResponse("today")
};

export const dateFormat = (date, format = "DD MMM, YYYY - hh:mm A") => {
	const localDate = moment(date).isValid() ? moment.utc(date).local() : null;
	return localDate ? localDate.format(format) : "";
};
