import gql from "graphql-tag";

export const GET_USER_FLOW_STEPS = gql`
	query GetUserFlowSteps($status: [status_enum!]) {
		identifier_flows(where: { status: { _in: $status } }) {
			flow {
				name
				steps {
					name
					metadata
					sort_order
				}
			}
		}
	}
`;

export const GET_USER_STEPS_STATUS = gql`
	query UserSteps {
		identifier_steps {
			status
			metadata
			step {
				name
			}
		}
	}
`;

export const SUBSCRIBE_TO_USER_STEPS = gql`
	subscription UserSteps {
		identifier_steps {
			status
			metadata
			step {
				name
			}
		}
	}
`;
