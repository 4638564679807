// third party
import moment from "moment";

// action types
import { ActionTypes } from "../actions/_types";

// constants
import { PRESET_TYPES, DATE_TYPES, COMPARE_DATE_TYPES } from "../components/_commons/NewDateCompareFilter";

// analytics filters
const getDefaultCompareStartEndDateRange = () => {
	// last 7 days
	let diff = 7;
	let rangeStartDate = moment().subtract(diff * 2 - 1, "d");
	let rangeEndDate = rangeStartDate.clone().add(diff - 1, "d");
	let dateFilter = rangeStartDate.format("YYYY-MM-DD") + "," + rangeEndDate.format("YYYY-MM-DD");
	return { rangeStartDate, rangeEndDate, dateFilter };
};
export const INITIAL_DATE_FILTER_STATE = {
	current: {
		dateFilter: PRESET_TYPES[6].value,
		dateTypeSelected: DATE_TYPES[0],
		presetTypeSelected: PRESET_TYPES[6],
		rangeStartDate: undefined,
		rangeEndDate: undefined
	},
	compare: {
		dateTypeSelected: COMPARE_DATE_TYPES[2],
		...getDefaultCompareStartEndDateRange()
	}
};
const INITIAL_STATE = {
	currentFilters: {
		brand_id: ["all"],
		location_id: ["all"],
		platform_names: ["all"]
	},
	appliedFilters: {
		brand_id: ["all"],
		location_id: ["all"],
		platform_names: ["all"]
	},
	initialFiltersFromUrl: {
		brand_id: ["all"],
		location_id: ["all"],
		platform_names: ["all"]
	},
	currentDateFilter: INITIAL_DATE_FILTER_STATE,
	appliedDateFilter: INITIAL_DATE_FILTER_STATE
};

export const analyticsFiltersState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.ANALYTICS_FILTERS_STATE_RESET:
			return INITIAL_STATE;
		default:
			return state;
	}
};

// revenue analytics
const INIT_REVENUE_ANALYTICS = {
	net_revenue: {
		metricsLoading: true,
		chartLoading: true,
		metrics: {
			order_revenue: {},
			order_avg_revenue_per_day: {},
			order_avg_order_value: {},
			order_completed_orders: {}
		},
		graphData: [],
		yScaleMax: "auto"
	},
	revenue_breakdown: {
		chartLoading: true,
		tableLoading: true,
		selectedChart: "pie",
		breakdownBy: { label: "Platform", value: "platform" },
		graphData: {
			pie: [],
			line: []
		},
		yScaleMax: "auto",
		legends: {},
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_REVENUE",
			order: "DESC"
		},
		sortedField: "ORDER_REVENUE",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		hideColumns: ["brand"]
	},
	avg_order_value: {
		metricsLoading: true,
		chartLoading: true,
		metrics: {
			order_avg_order_value: {}
		},
		graphData: [],
		yScaleMax: "auto"
	},
	revenue_by_location: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_REVENUE",
			order: "DESC"
		},
		sortedField: "ORDER_REVENUE",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		tableColumnsSelected: {
			columns: {}
		},
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	revenue_by_item: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ITEM_REVENUE",
			order: "DESC"
		},
		sortedField: "ITEM_REVENUE",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: true,
			rowLink: {
				to: "/analytics/revenue/view",
				entity: "item-performance"
			}
		},
		tableColumnsSelected: {
			columns: {}
		},
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	lost_revenue: {
		metricsLoading: true,
		chartLoading: true,
		tableLoading: true,
		showComparison: false,
		selectedChart: "bar",
		metrics: {
			order_lost_revenue: {}
		},
		graphData: {
			bar: [],
			line: []
		},
		maxValue: "auto",
		yScaleMax: "auto",
		limit: 10,
		offset: 0,
		sort: {
			field: "lost_revenue",
			order: "DESC"
		},
		sortedField: "lost_revenue",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		}
	},
	lost_revenue_breakdown: {
		chartLoading: true,
		tableLoading: true,
		showComparison: false,
		selectedChart: "bar",
		breakdownBy: { label: "Platform", value: "platform" },
		graphData: {
			bar: [],
			line: []
		},
		maxValue: "auto",
		yScaleMax: "auto",
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_LOST_REVENUE",
			order: "DESC"
		},
		sortedField: "ORDER_LOST_REVENUE",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		hideColumns: ["brand"]
	}
};
export const revenueAnalytics = (state = INIT_REVENUE_ANALYTICS, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_REVENUE_ANALYTICS_STATE:
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};
		default:
			return state;
	}
};

// order analytics
const INIT_ORDER_ANALYTICS = {
	orders_received: {
		metricsLoading: true,
		chartLoading: true,
		metrics: {
			order_received_orders: {},
			order_avg_orders_per_day: {},
			order_avg_order_value: {},
			order_lost_orders: {}
		},
		graphData: [],
		yScaleMax: "auto"
	},
	order_performance: {
		metricsLoading: true,
		chartLoading: true,
		metrics: {
			order_completed_orders: {},
			order_lost_orders_can_pre_ack: {},
			order_lost_orders_can_post_ack: {},
			rejected_orders: {}
		},
		graphData: {
			nodes: [],
			links: []
		},
		maxValue: undefined
	},
	orders_breakdown: {
		chartLoading: true,
		tableLoading: true,
		selectedChart: "pie",
		breakdownBy: { label: "Platform", value: "platform" },
		graphData: {
			pie: [],
			line: []
		},
		yScaleMax: "auto",
		legends: {},
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_COMPLETED_ORDERS",
			order: "DESC"
		},
		sortedField: "ORDER_COMPLETED_ORDERS",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		hideColumns: ["brand"]
	},
	orders_by_location: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_COMPLETED_ORDERS",
			order: "DESC"
		},
		sortedField: "ORDER_COMPLETED_ORDERS",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		tableColumnsSelected: {
			columns: {}
		},
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	order_frequency: {
		loading: true,
		compare: { label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
		applCompare: { label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
		compareOptions: [],
		compareFilterDisabled: false,
		showComparison: false,
		applShowComparison: false,
		graphData: [],
		maxValue: "auto"
	},
	orders_by_item: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ITEM_SOLD",
			order: "DESC"
		},
		sortedField: "ITEM_SOLD",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: true,
			rowLink: {
				to: "/analytics/orders/view",
				entity: "item-performance"
			}
		},
		tableColumnsSelected: {
			columns: {}
		},
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	lost_orders: {
		metricsLoading: true,
		chartLoading: true,
		tableLoading: true,
		showComparison: false,
		selectedChart: "bar",
		metrics: {
			order_lost_orders: {}
		},
		graphData: {
			bar: [],
			line: []
		},
		maxValue: "auto",
		yScaleMax: "auto",
		limit: 10,
		offset: 0,
		sort: {
			field: "lost_orders",
			order: "DESC"
		},
		sortedField: "lost_orders",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		}
	},
	lost_orders_breakdown: {
		chartLoading: true,
		tableLoading: true,
		showComparison: false,
		selectedChart: "bar",
		breakdownBy: { label: "Platform", value: "platform" },
		graphData: {
			bar: [],
			line: []
		},
		maxValue: "auto",
		yScaleMax: "auto",
		limit: 10,
		offset: 0,
		sort: {
			field: "ORDER_LOST_ORDERS",
			order: "DESC"
		},
		sortedField: "ORDER_LOST_ORDERS",
		tabularData: {
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		hideColumns: ["brand"]
	}
};
export const ordersAnalytics = (state = INIT_ORDER_ANALYTICS, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_ORDER_ANALYTICS_STATE:
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};
		default:
			return state;
	}
};

// operations analytics
const INIT_OPERATIONS_ANALYTICS = {
	operations_summary: {
		metricsLoading: true,
		metrics: {
			order_avg_delivery_time: {},
			order_avg_order_per_hour: {},
			order_failure_rate: {}
		}
	},
	order_completion_time: {
		metricsLoading: true,
		chartLoading: true,
		metrics: {
			order_avg_completion_time: {}
		},
		graphData: [],
		maxValue: "auto"
	},
	order_completion_funnel: {
		chartLoading: true,
		graphData: {
			nodes: [],
			links: []
		},
		maxValue: undefined
	},
	restaurant_availability: {
		loading: true,
		compare: { label: "Hours", value: "LOCATION_ACTION_DATE_HOUR", type: "hour" },
		applCompare: { label: "Hours", value: "LOCATION_ACTION_DATE_HOUR", type: "hour" },
		compareOptions: [],
		compareFilterDisabled: false,
		showComparison: false,
		applShowComparison: false,
		graphData: [],
		maxValue: "auto"
	}
};
export const operationsAnalytics = (state = INIT_OPERATIONS_ANALYTICS, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_OPERATIONS_ANALYTICS_STATE:
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};
		default:
			return state;
	}
};

// catalogue analytics
const INIT_CATALOGUE_ANALYTICS = {
	catalogue_metrics: {
		metricsLoading: true,
		metrics: {
			item_count: {},
			offline_items: {},
			offline_count: {},
			offline_time: {}
		}
	},
	category_performance: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ITEM_REVENUE",
			order: "DESC"
		},
		sortedField: "ITEM_REVENUE",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: false
		},
		tableColumnsSelected: {
			columns: {}
		},
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	},
	item_performance: {
		loading: true,
		limit: 10,
		offset: 0,
		sort: {
			field: "ITEM_REVENUE",
			order: "DESC"
		},
		sortedField: "ITEM_REVENUE",
		tabularData: {
			fields: [],
			columns: [],
			rows: [],
			count: 0,
			isRowClickable: true,
			rowLink: {
				to: "/analytics/catalogue/view",
				entity: "item-performance"
			}
		},
		tableColumnsSelected: {
			columns: {}
		},
		category: { value: "all", valueForDisplay: "All" },
		filters: [],
		searchKeywords: [],
		searchFieldSelected: { key: "default", valueForDisplay: "Default" },
		searchFieldValue: ""
	}
};
export const catalogueAnalytics = (state = INIT_CATALOGUE_ANALYTICS, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_CATALOGUE_ANALYTICS_STATE:
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};
		default:
			return state;
	}
};

// analytics entity detail
const INIT_ANALYTICS_ENTITY_DETAIL = {};
export const analyticsEntityDetail = (state = INIT_ANALYTICS_ENTITY_DETAIL, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_INIT_STATE:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_STATE:
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};
		case ActionTypes.RESET_ANALYTICS_ENTITY_DETAIL_STATE:
			return INIT_ANALYTICS_ENTITY_DETAIL;
		default:
			return state;
	}
};

const COMPARE_ANALYTICS = {
	columnData: {
		loading: false,
		brand: {
			image: "/assets/analytics/compare/",
			data: ["Brand"],
			count: 0
		},
		platform: {
			image: "",
			data: ["Platform"],
			count: 0
		},
		item: {
			image: "/assets/analytics/compare/store.svg",
			data: ["Item"],
			count: 0
		},
		location: {
			image: "/assets/analytics/compare/store.svg",
			data: ["Location"],
			count: 0
		}
	},

	itemList: {
		objects: [],
		loading: false,
		error: false
	},
	locationList: {
		objects: [],
		loading: false,
		error: false
	},
	displayInfo: "totalRevenue",
	savedComparisons: {
		loading: true,
		error: false,
		comparisons: []
	},
	recentlyViewed: {
		loading: true,
		error: false,
		comparisons: []
	},
	tableData: {
		location: {},
		item: {},
		platform: {},
		brand: {}
	},
	breakdown: {
		totalRevenue: {
			type: "breakdown",
			highlight: "max",
			platform: 0
		},
		lostRevenue: {
			type: "breakdown",
			highlight: "min",
			platform: 0
		},
		avgRevenuePerDay: {
			type: "",
			highlight: "max"
		},
		avgOrderValue: {
			type: "",
			highlight: "max"
		},
		totalOrders: {
			type: "breakdown",
			highlight: "max",
			platform: 0
		},
		completedVsLost_Orders: {},
		lostOrders: {
			type: "breakdown",
			highlight: "min",
			platform: 0
		},
		avgOrdersPerDay: { type: "", highlight: "max" },
		bestSellingItems: {},
		bestPerformingLocations: {},
		worstSellingItems: {},
		worstPerformingLocations: {},
		orderCompletionTime: {
			type: "breakdown",
			highlight: "min",
			platform: 1
		},
		locationAvailability: {},
		itemAvailability: {},
		snoozedItems: {
			type: "",
			highlight: "min",
			platform: 1
		},
		totalSnoozeCount: {
			type: "",
			highlight: "min",
			platform: 1
		},
		totalSnoozeTime: {
			type: "",
			highlight: "min",
			platform: 1
		}
	}
};
export const compareAnalytics = (state = COMPARE_ANALYTICS, action) => {
	switch (action.type) {
		case "UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA":
			return {
				...COMPARE_ANALYTICS
			};
		case "UPDATE_COMPARE_ANALYTICS_COLUMN_DATA":
			if (action.payload.new) {
				return {
					...state,
					columnData: {
						...state.columnData,
						[action.payload.metric]: {
							...state.columnData[action.payload.metric],
							data: [...action.payload.data],
							count: action.payload.count
						}
					}
				};
			}
			return {
				...state,
				columnData: {
					...state.columnData,
					[action.payload.metric]: {
						...state.columnData[action.payload.metric],
						data: [...state.columnData[action.payload.metric]["data"], ...action.payload.data],
						count: action.payload.count
					}
				}
			};
		case "UPDATE_COMPARE_ANALYTICS_CHANGE_COLUMN_DATA":
			const { metric, replace, data } = action.payload;
			if (replace) {
				return {
					...state,
					columnData: {
						...state.columnData,
						[metric]: {
							...state.columnData[metric],
							data: [...data]
						}
					}
				};
			}
			return {
				...state,
				columnData: {
					...state.columnData,
					[metric]: {
						...state.columnData[metric],
						data: [...action.payload.data],
						count: action.payload.count
					}
				}
			};
		case "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE":
			return {
				...state,
				...action.payload
			};

		case "UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE":
			return {
				...state,
				[action.payload.metric]: {
					...state[action.payload.metric],
					...action.payload.data
				}
			};

		case "UPDATE_COMPARE_ANALYTICS_TABLE_DATA":
			if (action.payload.feature) {
				const { feature, metric, name, data } = action.payload;
				return {
					...state,
					tableData: {
						...state.tableData,
						[metric]: {
							...state.tableData?.[metric],
							[name]: {
								...state.tableData?.[metric]?.[name],
								[feature]: {
									...state.tableData?.[metric]?.[name]?.[feature],
									...data
								}
							}
						}
					}
				};
			}
			return {
				...state,
				tableData: {
					...state.tableData,
					[action.payload.metric]: {
						...state.tableData?.[action.payload.metric],
						[action.payload.name]: {
							...state.tableData?.[action.payload.metric]?.[action.payload.name],
							...action.payload.data
						}
					}
				}
			};

		case "DELETE_COMPARE_ANALYTICS_TABLE_DATA":
			return {
				...state,
				tableData: {
					...state.tableData,
					[action.payload.metric]: {
						...action.payload.data
					}
				}
			};

		case "UPDATE_SAVED_COMPARISONS":
			let view = action.payload.view === "saved" ? "savedComparisons" : "recentlyViewed";
			return {
				...state,
				[view]: {
					...state?.[view],
					...action.payload.data
				}
			};

		default:
			return state;
	}
};
