import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

// history
import history from "../../history";

function CommonOnboardingBanner({ onCtaClick }) {
	return (
		<div className="common-onboarding-banner">
			<div className="common-onboarding-banner__left">
				<img src="/assets/icons/tip-icon.svg" className="tip-icon" alt="tip-icon" />
			</div>
			<div className="common-onboarding-banner__right">
				<Text className="title" variant={TextVariant.HEADING4}>
					All your menus will show up here
				</Text>
				<Text className="subtitle" variant={TextVariant.BODY1}>
					To learn more about menus.{" "}
					<Button variant={ButtonVariant.Link} size={ButtonSize.MD}>
						Click here
					</Button>
				</Text>
				<Button
					className="back-to-setup-cta"
					variant={ButtonVariant.Link}
					size={ButtonSize.MD}
					onClick={onCtaClick ? onCtaClick : () => history.push("/onboarding/menu")}
				>
					Back to Setup
				</Button>
			</div>
		</div>
	);
}

export default CommonOnboardingBanner;
