import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import { Topbar } from "../components/_commons/Topbar";
import { SearchFilter } from "../components/_commons/SearchFilter";
import Overview from "../components/Reports/Overview";
import ExportReportHistory from "../components/Reports/ExportReportHistory";
import Schedules from "../components/Reports/Schedules";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import history from "../history";

// utils
import { scroll, isFeatureFlagEnabled } from "../atlas-utils";

// actions
import { fetchReportsNewList } from "../actions/reports";

// constants
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const REPORT_TABS = [
	{
		label: "Overview",
		value: "overview"
	},
	{
		label: "Export History",
		value: "export-history"
	},
	{
		label: "Schedules",
		value: "schedules"
	}
];

const ReportsList = ({ reportsList, match }) => {
	const [formTab, setFormTab] = useState(match?.params?.tab || REPORT_TABS[0]?.value);
	const [currSearchFilter, setCurrSearchFilter] = useState("");
	const [applSearchFilter, setApplSearchFilter] = useState("");
	const [reports, setReports] = useState({
		count: 0,
		objects: []
	});
	const { loading, data } = reportsList;
	const topRef = useRef();

	useEffect(() => {
		fetchReportsNewList();
	}, []);

	const handleReports = useCallback(() => {
		if (formTab === REPORT_TABS[0]?.value) {
			let count = data.reduce((val, g) => g?.reports?.length + val, 0);
			let reports = JSON.parse(JSON.stringify(data));
			if (applSearchFilter) {
				count = 0;
				const regex = new RegExp(applSearchFilter, "gi");
				reports = reports.map((g) => {
					const filteredRepors = g.reports?.filter(
						(r) => r.name.match(regex) !== null || r.description.match(regex) !== null
					);
					count += filteredRepors?.length;
					g.reports = filteredRepors;
					return g;
				});
			}
			setReports({
				count,
				objects: reports
			});
		}
	}, [data, applSearchFilter, formTab]);

	useEffect(() => {
		handleReports();
	}, [handleReports]);

	const switchTab = (tab) => {
		setFormTab(tab?.value);

		// update url
		history.replace(`/reports/list/${tab?.value}`);

		// scroll to the top
		if (topRef?.current) {
			scroll({ top: topRef?.current?.offset - 57, left: 0 });
		}
	};

	const applySearchFilter = useCallback(
		debounce((searchKeywords) => {
			setApplSearchFilter(searchKeywords);
		}, 300),
		[]
	);

	const handleSearch = useCallback(
		(field, value) => {
			setCurrSearchFilter(value);
			applySearchFilter(value);
		},
		[applySearchFilter]
	);

	return (
		<div className="reports-list-container section-container-common">
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Reports</div>
					<div className="subheader-text">View all your reports here</div>
				</div>
				<SearchFilter
					filterOption={{ field: "currSearchFilter" }}
					value={currSearchFilter}
					setFilter={(field, value) => handleSearch(field, value.replace(/[\\+*()?]/g, ""))}
					placeholder="Search"
				/>
			</div>
			<Topbar
				tabs={REPORT_TABS.map((tab) => ({
					...tab,
					showCount: !!applSearchFilter && formTab === REPORT_TABS[0].value && formTab === tab.value,
					count: reports?.count
				}))}
				selectedTab={formTab}
				switchTab={switchTab}
				isStickyOnTop={true}
				hiddenTabs={
					isFeatureFlagEnabled("atlas-schedule-reports") || ENVIRONMENT_FLAG === "staging"
						? []
						: ["schedules"]
				}
			/>
			{formTab === REPORT_TABS[0]?.value && (
				<Overview reports={reports?.objects} loading={loading} searchKeyword={applSearchFilter} />
			)}
			{formTab === REPORT_TABS[1]?.value && <ExportReportHistory searchKeyword={applSearchFilter} />}
			{formTab === REPORT_TABS[2]?.value && <Schedules searchKeyword={applSearchFilter} />}
		</div>
	);
};
const mapStateToProps = (store) => ({
	reportsList: store.reportsNewList
});
export default connect(mapStateToProps)(ReportsList);
