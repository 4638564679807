import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import { CheckBox } from "../_commons/CheckBox";
import MonogramGenerator from "../_commons/MonogramGenerator";

// third party
import classNames from "classnames";

function ChooseBrands({
	brandsList = [],
	setSelectedBrand = () => {},
	selectedBrand = {},
	disabledBrands = [],
	loading = false
}) {
	return (
		<div className="choose-onboarding-brands">
			{loading ? (
				<div className="choose-onboarding-brands--loading">
					<div className="P(10px)">
						<div className="shimmer H(100px) Mb(20px)" />
						<div className="shimmer H(200px) Mb(10px)" />
					</div>
				</div>
			) : (
				<React.Fragment>
					<Text variant={TextVariant.HEADING4}>Select a brand to proceed</Text>
					{brandsList?.length ? (
						<div className="brands-list">
							{brandsList.map((brand) => {
								const isDisabled = disabledBrands.includes(brand.id);
								return (
									<div
										key={brand.id}
										className={classNames("brand-card", { "brand-card--disabled": isDisabled })}
										onClick={() => {
											isDisabled || setSelectedBrand(brand);
										}}
										title={brand.name}
									>
										<div className="brand-image-wrapper">
											{brand.image ? (
												<img src={brand.image} alt="" />
											) : (
												<MonogramGenerator value={brand?.name} />
											)}
											<CheckBox
												checked={selectedBrand?.id === brand.id}
												clickHandler={(e) => {
													isDisabled || setSelectedBrand(brand.id);
													e.stopPropagation();
												}}
											/>
										</div>
										<Text variant={TextVariant.BODY1} className="brand-name">
											{brand.name}
										</Text>
									</div>
								);
							})}
						</div>
					) : (
						<div className="no-brands-found">
							<Text variant={TextVariant.HEADING5}>No brands found</Text>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
}

export default ChooseBrands;
