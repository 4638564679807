import React, { useState, useEffect, useMemo, useCallback } from "react";

// components
import { Button } from "../_commons/Button";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import { Modal } from "../_commons/Modal";
import { NumericalStepDisplay } from "../_commons/NumericalStepDisplay";
import { FormContainer } from "../../containers/Meraki";
import BrandSelection from "./BrandSelection";
import ReviewLocationAssociation from "./ReviewLocationAssociation";
import AssociateMenu from "./AssociateMenu";
import PublishAndActivate from "./PublishAndActivate";
import FixMenuError from "./FixMenuErrors";
import { InputWithLabel } from "../_commons/InputWithLabel";

// third party
import { connect } from "react-redux";
import history from "../../history";
import { debounce } from "lodash";

// store
import { store } from "../../store/configureStore";

// services
import NotificationServices from "../../services/NotificationService";

// actions
import { fetchBizPlatforms } from "../../actions/actions";
import { ActionTypes } from "../../actions/_types";
import { fetchStoresDebounced, fetchMenusDebounced, fetchMenus } from "../../actions/actions";
import {
	fetchDspPlatformLocationsList,
	fetchLocationsList,
	handleDspUpdatePlatformLocations,
	handleDspUpdatePlatformStoreLocations,
	handleDspActivateStoresAndPublishMenu,
	handleDspPlatformSwitchAccount
} from "../../actions/dspIntegration";

// constants
import { DSP_INTEGRATION_INITIAL_STATE } from "../../reducers/dspIntegration";
import { businessBasedDisabledFields, CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";
import { capitaliseText, validatePlatformUrl } from "../../atlas-utils";
import Popover from "../_commons/Popover";

const INIT_CARD_PROPS = {
	header: {
		text: "",
		subText: "",
		icon: ""
	},
	footer: {
		primary: "",
		primaryHandler: () => {}
	}
};

const LocationsAndMenu = ({
	match,
	editMode = false,
	dspIntegration = {},
	bizPlatforms,
	stores,
	menus,
	isMultibrandEnabled = false,
	renderBrandSelection,
	tempBrandSelected = null,
	setTempBrandSelected,
	brands
}) => {
	const {
		loading,
		limit,
		offset,
		data,
		platform,
		selectedTab,
		searchFieldValue,
		appliedFilters,
		mappedLocations,
		associationUpdates,
		selectedBrand
	} = dspIntegration;

	const storeStatusCount = useMemo(
		() =>
			PLATFORM_MAP[platform]
				? {
						all: data?.mappedStoresCount + data?.activeStoresCount + data?.unmappedStoresCount,
						mapped: data?.mappedStoresCount,
						active: data?.activeStoresCount,
						unmapped: data?.unmappedStoresCount,
						failed: data?.mappedStoresCount - data?.activeStoresCount
					}
				: {
						all: data?.rtglProgress?.total,
						mapped: data?.rtglProgress?.notLive,
						active: data?.rtglProgress?.live,
						unmapped: data?.rtglProgress?.disabled,
						failed: data?.rtglProgress?.failed
					},
		[data?.mappedStoresCount, data?.activeStoresCount, data?.unmappedStoresCount, data?.rtglProgress]
	);

	const [modalOpen, setModalOpen] = useState(false);
	const [brandSelectionOpen, setBrandSelectionOpen] = useState(false);
	const [partialUrlAssociation, setPartialUrlAssociation] = useState(false);
	const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
	const [cardProps, setCardProps] = useState(INIT_CARD_PROPS);
	const [activationSuccess, setActivationSuccess] = useState(undefined);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isFixMenuOpen, setIsFixMenuOpen] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [brandEdit, setBrandEdit] = useState(editMode);

	useEffect(() => {
		fetchMenus("", selectedBrand?.id, "no-cache");
		if (bizPlatforms?.items.length === 0) fetchBizPlatforms();
	}, []);

	useEffect(() => {
		if ((isMultibrandEnabled && selectedTab !== 1) || !isMultibrandEnabled) {
			fetchStoresDebounced("", 50, selectedBrand?.id, false, "no-cache");
			fetchMenusDebounced("", selectedBrand?.id, "no-cache");
		}
	}, [selectedBrand, selectedTab]);

	useEffect(() => {
		if (isMultibrandEnabled && !selectedBrand && !editMode) {
			updateDspIntegrationState({ selectedBrand: brands?.items?.[0] || null });
		}
	}, [brands?.items]);

	useEffect(() => {
		setLoading(true);
		if (tempBrandSelected) {
			//setBrandSelectionOpen(true);
			handleBrandSelectionModalAction(true);
		}
		setLoading(false);
	}, [tempBrandSelected]);

	useEffect(() => {
		setHasError(data?.rtglProgress?.failed);
	}, [data?.rtglProgress?.failed]);

	useEffect(() => {
		setLoading(true);
		if (PLATFORM_MAP[platform]) {
			fetchDspPlatformLocationsList(isMultibrandEnabled ? selectedTab === 3 : selectedTab === 2);
		} else if (bizPlatforms.items.length && (!isMultibrandEnabled || selectedBrand)) {
			fetchLocationsList();
		}
		setLoading(false);
	}, [platform, limit, offset, appliedFilters, selectedBrand, selectedTab, bizPlatforms]);

	const updateDspIntegrationState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload
		});
	};

	const handlePagination = (page) => {
		// set new offset
		const offset = (page - 1) * limit;
		updateDspIntegrationState({
			offset
		});
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateDspIntegrationState({
				[field]: size.value,
				offset: 0
			});
		}
	};

	const handleStatusFilter = (field, value) => {
		const filters = {
			...appliedFilters
		};
		filters[field] = value;
		updateDspIntegrationState({
			currentFilters: filters,
			appliedFilters: filters,
			offset: 0
		});
	};

	const applySearchFilter = PLATFORM_MAP[platform]
		? debounce(
				() => fetchDspPlatformLocationsList(isMultibrandEnabled ? selectedTab === 3 : selectedTab === 2),
				500
			)
		: debounce(() => fetchLocationsList(), 1000);

	const handleSearchFilter = (field, value) => {
		updateDspIntegrationState({
			[field]: value,
			offset: 0
		});

		applySearchFilter();
	};

	const handleAssociationUpdates = (record, field, value, prevValue = null) => {
		// update association updates
		const newAssociationUpdates = { ...associationUpdates };

		// check if the 'value' is same as already associated location. If yes, then
		// avoid/remove 'value' from association updates and 'prevValue' from mapped locations
		if (PLATFORM_MAP[platform]) {
			if (field === "location" && record?.location?.id === value?.id) {
				delete newAssociationUpdates[record?.id];
			} else {
				const updates = associationUpdates?.[record?.id];
				newAssociationUpdates[record?.id] = {
					...(updates ?? {}),
					id: record?.id,
					externalId: updates ? updates?.externalId : record?.externalId || "--",
					externalUrl: updates ? updates?.externalUrl : record?.externalUrl || "--",
					brandLocationId: record?.brandLocation?.id,
					locationName: record?.locationName,
					platformName: record?.platformName,
					location: record?.location || null,
					isStoreMapped: record?.isStoreMapped,
					[field]: value
				};
			}

			// update mapped locations
			const updatedMappedLocations = { ...mappedLocations };
			if (field === "location") {
				updatedMappedLocations[value?.id] = true;
				if (prevValue && prevValue?.id !== value?.id) {
					updatedMappedLocations[prevValue?.id] = false;
				}
			}

			// update dsp integration state
			updateDspIntegrationState({
				associationUpdates: newAssociationUpdates,
				mappedLocations: updatedMappedLocations
			});
		} else {
			const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === platform);
			const updates = associationUpdates?.[record?.id];
			newAssociationUpdates[record?.id] = {
				...(updates ?? {}),
				id: record?.id,
				externalId: updates ? updates?.externalId : currentPlatform?.externalId || "",
				externalUrl: (updates ? updates?.externalUrl : currentPlatform?.outletUrl) || "",
				brandLocationId: record?.brandLocation?.id,
				locationName: record?.name,
				platformName: currentPlatform?.platformName,
				location: record?.location || null,
				isStoreMapped: record?.isStoreMapped,
				[field]: value
			};

			if (field === "externalUrl") {
				const platformUrlValidation =
					(businessBasedDisabledFields.platformUrl[platform.toLowerCase()]
						? true
						: validatePlatformUrl(platform, newAssociationUpdates[record?.id]?.externalUrl)) ||
					value === "";

				newAssociationUpdates[record?.id] = {
					...newAssociationUpdates[record?.id],
					invalidUrl: !platformUrlValidation
				};
			}

			// update mapped locations
			const updatedMappedLocations = { ...mappedLocations };
			if (field === "externalUrl") {
				updatedMappedLocations[record?.id] = true;
				if (value === "") {
					delete updatedMappedLocations[record?.id];
				}
			}
			// update dsp integration state
			updateDspIntegrationState({
				associationUpdates: newAssociationUpdates,
				mappedLocations: updatedMappedLocations
			});
		}

		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleModalAction = (primary = false) => {
		if (primary && editMode) {
			updateDspIntegrationState({
				associationUpdates: {},
				mappedLocations: {}
			});
			history.push(match.url?.replace("/edit", ""));
		}
		if (primary && !editMode) {
			history.push(`${match?.url}/edit`);
		}
		setModalOpen(false);
	};

	const handleCreateLocation = () => {
		//history.push(`${match?.url}/edit`);
		history.push(`/locations/new`);
	};

	const handleURLModalAction = (primary = false) => {
		if (primary) handleSubmit(true);
		setPartialUrlAssociation(false);
	};

	const resetDspIntegrationState = (selectedTab = 1, props = {}) => {
		updateDspIntegrationState({
			selectedTab,
			offset: 0,
			limit: 10,
			currentFilters: DSP_INTEGRATION_INITIAL_STATE.currentFilters,
			appliedFilters: DSP_INTEGRATION_INITIAL_STATE.appliedFilters,
			searchFieldValue: "",
			associationUpdates: {},
			mappedLocations: {},
			...props
		});
	};

	const handleCancel = (reset = false) => {
		if (isFixMenuOpen) {
			setIsFixMenuOpen(false);
			resetDspIntegrationState(1);
			if (reset) history.push("/hub/integrations");
			// updateDspIntegrationState({
			// 	associationUpdates: {},
			// 	mappedLocations: {},
			// 	selectedTab: 1
			// });
			return;
		}
		if (isMultibrandEnabled) {
			if (selectedTab === 1 || reset) {
				if (reset) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
				}
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			if (selectedTab === 2) {
				if (Object.keys(associationUpdates)?.length === 0) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
					return;
				}
				// show modal confirmation to discard association updates
				setModalOpen(true);
				return;
			}
			if (selectedTab === 3) {
				resetDspIntegrationState(2);
				return;
			}
		}
		if (selectedTab === 1 || reset) {
			if (Object.keys(associationUpdates)?.length === 0 || reset) {
				updateDspIntegrationState({
					associationUpdates: {},
					mappedLocations: {},
					selectedTab: 1
				});
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			// show modal confirmation to discard association updates
			setModalOpen(true);
			return;
		}
		if (selectedTab === 2) {
			resetDspIntegrationState(1);
			return;
		}
	};

	const handleSubmit = async (overrideURL = false) => {
		if (isMultibrandEnabled && selectedTab === 1 && !isFixMenuOpen) {
			updateDspIntegrationState({ selectedTab: 2 });
			return;
		}
		if (
			((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) &&
			!isFixMenuOpen
		) {
			// handle dsp update platform locations if any associations are done
			if (!overrideURL && !PLATFORM_MAP[platform] && Object.keys(mappedLocations)?.length !== data?.count) {
				setPartialUrlAssociation(true);
				return;
			}
			if (
				(Object.keys(associationUpdates)?.length > 0 &&
					Object?.values(associationUpdates)?.filter(
						(loc) => (loc?.isStoreMapped && loc?.location === null) || loc?.location !== null
					)?.length > 0) ||
				!PLATFORM_MAP[platform]
			) {
				if (!PLATFORM_MAP[platform]) {
					// validate if all association updates have the valid Url according to the platform.
					let isValid = true;
					for (let id in associationUpdates) {
						// invalidate only if the association already existed previously, but has been removed now.
						if (associationUpdates[id]?.invalidUrl) {
							isValid = false;
							break;
						}
					}
					if (!isValid) {
						NotificationServices.pushNotification({
							message: "Please check and add correct URL for storefronts.",
							timeout: 5000,
							type: "error",
							isClosable: true,
							theme: "dark"
						});
						return;
					}
					//proceed once validation is done
				}
				setLoading(true);
				const status =
					(PLATFORM_MAP[platform]
						? await handleDspUpdatePlatformLocations()
						: await handleDspUpdatePlatformStoreLocations()) || {};
				if (status?.success) {
					NotificationServices.pushNotification({
						message: "Progress Saved!",
						timeout: 5000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					setLoading(false);
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Something went wrong.",
							timeout: 2000,
							error: true
						}
					});
					setLoading(false);
					return;
				}
			}
			// reset state for menu association step
			resetDspIntegrationState(isMultibrandEnabled ? 3 : 2);
			return;
		}
		if (
			(!isMultibrandEnabled && selectedTab === 2) ||
			(isMultibrandEnabled && selectedTab === 3) ||
			isFixMenuOpen
		) {
			// handle dsp menu association, publish and store activation
			setLoading(true);
			const status = (await handleDspActivateStoresAndPublishMenu()) || {};
			if (status?.success) {
				setCardProps({
					header: {
						text: "Request Initiated",
						subText:
							"Verification takes a while. Post activation, you will start receiving online orders on our  platform and/or in your POS. ",
						icon: "/assets/icons/activate.svg"
					},
					footer: {
						primary: "Go back to Integrations",
						primaryHandler: () => handleCancel(true)
					}
				});
				setActivationSuccess(true);
			} else {
				setCardProps({
					header: {
						text: status?.messages?.[0]?.message || "Something went wrong!",
						subText: "Publish and Store activation failed.",
						icon: "/assets/icons/icon-cross.svg"
					},
					footer: {
						primary: "Go back to Integrations",
						primaryHandler: () => handleCancel(true)
					}
				});
				setActivationSuccess(false);
			}
			setLoading(false);
		}
	};

	const handleDisableFormSubmit = () => {
		if (isMultibrandEnabled && selectedTab === 1 && selectedBrand) {
			return false;
		}
		if (isMultibrandEnabled && selectedTab === 1 && !selectedBrand) {
			return true;
		}
		if (data?.objects.length === 0) return true;

		if (loading) return true;

		if (selectedTab === 1 || (isMultibrandEnabled && selectedTab === 2)) {
			return false;
		}
		if (
			((!isMultibrandEnabled && selectedTab === 2) || (isMultibrandEnabled && selectedTab === 3)) &&
			Object.keys(associationUpdates)?.length > 0 &&
			Object.values(associationUpdates)?.filter((loc) => loc?.associatedMenu !== null)?.length > 0
		) {
			return false;
		}
		return true;
	};

	const handleSwitchAccount = async (modalConfirmed = false) => {
		if (modalConfirmed) {
			setLoading(true);
			const status = await handleDspPlatformSwitchAccount();
			if (status?.success) {
				NotificationServices.pushNotification({
					message: "Account logged out successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				history.push("/hub/integrations");
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: status?.messages?.[0]?.message || "Something went wrong.",
						timeout: 3000,
						error: true
					}
				});
			}
			setLoading(false);
		}
		setSwitchAccountOpen(!switchAccountOpen);
	};

	const handleBrandSelectionModalAction = (primary = false) => {
		setLoading(true);
		if (primary) {
			resetDspIntegrationState(1, { selectedBrand: tempBrandSelected });
		}
		setBrandSelectionOpen(false);
		setTempBrandSelected(null);
		setLoading(false);
	};

	const renderTitle = () => {
		if (isMultibrandEnabled && selectedTab === 1) {
			return "Select a brand";
		}
		if ((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) {
			return PLATFORM_MAP[platform]
				? `Map Locations for ${capitaliseText(platform)}`
				: isMultibrandEnabled
					? `Add ${capitaliseText(platform)} platform URLs for ${selectedBrand.name}`
					: `Add ${capitaliseText(platform)} platform URLs`;
		}
		if ((!isMultibrandEnabled && selectedTab === 2) || (isMultibrandEnabled && selectedTab === 3)) {
			return "Associate Menu to Mapped Storefronts";
		}
	};

	const handleFormSubmitTitle = () => {
		if (isMultibrandEnabled) {
			if (selectedTab === 1) {
				return "Continue to Associate Location";
			}
			if (selectedTab === 2) {
				return "Continue to Associate Menu";
			}
			if (selectedTab === 3) {
				return "Publish & Activate Store";
			}
		}
		if (selectedTab === 1) {
			return "Continue to Associate Menu";
		}
		if (selectedTab === 2) {
			return "Publish & Activate Store";
		}
	};

	if (activationSuccess !== undefined) {
		return (
			<PublishAndActivate
				activationSuccess={activationSuccess}
				cardProps={cardProps}
				dspIntegration={dspIntegration}
				fetchDspPlatformLocationsList={fetchDspPlatformLocationsList}
				handleSearchFilter={handleSearchFilter}
				handleStatusFilter={handleStatusFilter}
				handlePagination={handlePagination}
				handlePageSize={handlePageSize}
				handleAssociationUpdates={handleAssociationUpdates}
				updateDspIntegrationState={updateDspIntegrationState}
				handleCancel={handleCancel}
			/>
		);
	}
	// if (loading &&  data?.objects?.length === 0) {
	// 	return (
	// 		<div>
	// 			<div className="shimmer H(60px) Mb(10px)" />
	// 			<div className="shimmer H(60px) Mb(10px)" />
	// 		</div>
	// 	);
	// }

	return (
		<div className="dsp-locations">
			<FormContainer
				cancel={handleCancel}
				classes="dsp-location-container"
				footerClass=""
				cancelTitle={isFixMenuOpen ? "Go Back" : selectedTab === 1 ? "Back" : "Previous"}
				submit={handleSubmit}
				submitLoading={isLoading}
				disableSubmit={handleDisableFormSubmit()}
				submitTitle={handleFormSubmitTitle()}
				hideSubmitAction={isFixMenuOpen}
				submitClass={isMultibrandEnabled && selectedTab === 1 ? "W(250px)" : "W(200px)"}
				hideActions={!editMode && !isFixMenuOpen}
			>
				{editMode ? (
					<NumericalStepDisplay
						count={isMultibrandEnabled ? 3 : 2}
						selectedValue={selectedTab}
						hideProgress={true}
						showProgressBar={true}
						title={renderTitle()}
						renderContentRight={
							<div className="actions">
								{PLATFORM_MAP[platform] && (
									<div className="action" onClick={() => handleSwitchAccount(false)}>
										Switch Account
									</div>
								)}
							</div>
						}
					/>
				) : !isFixMenuOpen ? (
					<div className="credits-section-header hub-config-header">
						<div className="header-text">
							<div className="title">Request to go live on {capitaliseText(platform)}</div>
							<div className="subtitle">
								Manage location mapping requests for all brands from {capitaliseText(platform)} here
							</div>
						</div>
						<div className="header-action-button">
							{PLATFORM_MAP[platform] && (
								<div className="action" onClick={() => handleSwitchAccount(false)}>
									Switch Account
								</div>
							)}
							{data?.objects.length > 0 && (
								<Button
									clickHandler={() => setModalOpen(true)}
									classes={data?.count === 0 ? "disabled" : ""}
								>
									<span>Map More Stores</span>
								</Button>
							)}
						</div>
					</div>
				) : (
					<div className="credits-section-header hub-config-header">
						<div className="header-text">
							<div className="title">Fix Menu Issues</div>
						</div>
					</div>
				)}

				{!editMode && !isFixMenuOpen && (
					<div className="form-content">
						{hasError && !loading ? (
							<div
								className="status-alert-container failure-alert-box"
								style={{
									borderLeft: `6px solid #D64949`,
									backgroundColor: "#FFF5F5"
								}}
							>
								<div className="error-icon">
									<img src="/assets/icons/error-big-red.svg" />
								</div>

								<div className="error-text-link">
									<div className="main-error-header">
										{data?.rtglProgress?.failed} locations were not mapped to{" "}
										{capitaliseText(platform)} due to errors in menu verification
									</div>
									<div className="main-error-desc">
										Create a new request for all unmapped locations
									</div>
									<br />
									<span
										className="hyperlink"
										style={{ width: "fit-content" }}
										onClick={() => {
											setIsFixMenuOpen(true);
										}}
									>
										Fix errors <img src="/assets/icons/ic_right_arrow_blue.svg" />
									</span>
								</div>
							</div>
						) : null}
						<Filters
							searchFieldValue={searchFieldValue}
							handleSearchFilter={handleSearchFilter}
							loading={loading}
							statusFilter={
								PLATFORM_MAP[platform]
									? data?.filters?.find((f) => f.field === "store_status")
									: data?.filters?.find((f) => f.field === "state") || {}
							}
							handleStatusFilter={handleStatusFilter}
							statusValue={
								(PLATFORM_MAP[platform] ? appliedFilters["store_status"] : appliedFilters["state"]) ||
								null
							}
							renderBrandSelection={renderBrandSelection}
							isMultibrandEnabled={isMultibrandEnabled}
							brandEdit={brandEdit}
						/>
						{!editMode &&
						!loading &&
						!bizPlatforms.isLoading &&
						data?.objects?.length === 0 &&
						(!appliedFilters?.state || appliedFilters?.state?.value === "all") ? (
							<div className="wrapper-dsp">
								<div className="dsp-locations-none">
									<img src="/assets/icons/no-item.svg" alt="" />
									<div className="header-text">No locations added yet!</div>
									<div className="no-items-placeholder">
										No locations found, please add locations to go live.
									</div>
									<Button clickHandler={() => handleCreateLocation()}>
										<span>Create Location</span>
									</Button>
								</div>
							</div>
						) : (
							<div>
								<div className="locations-status-count">
									<div className="container">
										<div className="header">Storefront Status Overview</div>
										<div className="status-overview">
											<div className="status-item">
												<div className="status-title">Storefronts Live</div>
												{loading || bizPlatforms.isLoading ? (
													<div className="shimmer W(100px) H(20px)"></div>
												) : (
													<div className="status-value">
														{storeStatusCount.active}/{storeStatusCount.all}
													</div>
												)}
											</div>
											{!PLATFORM_MAP[platform] && (
												<div className="status-item">
													<div className="status-title">Failed Requests</div>
													{loading || bizPlatforms.isLoading ? (
														<div className="shimmer W(100px) H(20px)"></div>
													) : (
														<div className="status-value">{data?.rtglProgress?.failed}</div>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="primary-location-table">
									{!!PLATFORM_MAP[platform] && (
										<CustomTable
											bordered={true}
											loading={loading || bizPlatforms.isLoading}
											data={data?.objects || []}
											columns={getColumns(
												match?.params?.dsp,
												editMode,
												isMultibrandEnabled,
												selectedBrand
											)}
											classes="locations-table-container no-top-margin"
											content="locations"
											stores={stores}
											showLoadingForce={loading}
											menus={menus}
											mappedLocations={mappedLocations}
											hideColumns={[
												"up-stores-non-dsp",
												"menu",
												"store-platform-id",
												"store-platform-url",
												"store-platform-id-display",
												"store-platform-url-display"
											]}
										/>
									)}
									{!PLATFORM_MAP[platform] && (
										<CustomTable
											bordered={true}
											loading={loading || bizPlatforms.isLoading}
											data={data?.objects || []}
											columns={getColumns(
												match?.params?.dsp,
												editMode,
												isMultibrandEnabled,
												selectedBrand
											)}
											classes="locations-table-container no-top-margin"
											content="locations"
											showLoadingForce={loading}
											stores={stores}
											menus={menus}
											mappedLocations={mappedLocations}
											hideColumns={[
												"dsp-stores",
												"up-stores",
												"menu",
												"store-platform-id",
												"store-platform-url"
											]}
										/>
									)}
									<Paginator
										limit={limit}
										offset={offset}
										loading={loading}
										count={data?.count || 0}
										goToPage={handlePagination}
										setPageSize={handlePageSize}
										showPageSize={true}
									/>
								</div>
							</div>
						)}
					</div>
				)}
				{editMode && !isFixMenuOpen && (
					<React.Fragment>
						{selectedTab === 1 &&
							(isMultibrandEnabled ? (
								<BrandSelection
									dspIntegration={dspIntegration}
									updateDspIntegrationState={updateDspIntegrationState}
								/>
							) : (
								<ReviewLocationAssociation
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									stores={stores}
									handleSearchFilter={handleSearchFilter}
									handleStatusFilter={handleStatusFilter}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									editMode={editMode}
									mappedLocations={mappedLocations}
									isMultibrandEnabled={isMultibrandEnabled}
									renderBrandSelection={renderBrandSelection}
								/>
							))}
						{selectedTab === 2 &&
							(isMultibrandEnabled ? (
								<ReviewLocationAssociation
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									stores={stores}
									handleSearchFilter={handleSearchFilter}
									handleStatusFilter={handleStatusFilter}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									editMode={editMode}
									mappedLocations={mappedLocations}
									isMultibrandEnabled={isMultibrandEnabled}
									renderBrandSelection={renderBrandSelection}
								/>
							) : (
								<AssociateMenu
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									menus={menus}
									stores={stores}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									updateDspIntegrationState={updateDspIntegrationState}
									editMode={editMode}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							))}
						{isMultibrandEnabled && selectedTab === 3 && (
							<AssociateMenu
								dsp={match?.params?.dsp}
								dspIntegration={dspIntegration}
								menus={menus}
								handlePagination={handlePagination}
								handlePageSize={handlePageSize}
								handleAssociationUpdates={handleAssociationUpdates}
								updateDspIntegrationState={updateDspIntegrationState}
								editMode={editMode}
								isMultibrandEnabled={isMultibrandEnabled}
							/>
						)}
					</React.Fragment>
				)}

				{isFixMenuOpen && (
					<FixMenuError
						dsp="someDsp"
						editMode={editMode}
						handleCancel={handleCancel}
						handleSubmit={handleSubmit}
						dspIntegration={dspIntegration}
						fetchLocationsList={fetchLocationsList}
						handlePagination={handlePagination}
						handlePageSize={handlePageSize}
						isFixMenuOpen={isFixMenuOpen}
						setIsFixMenuOpen={setIsFixMenuOpen}
					/>
				)}
			</FormContainer>
			<Modal
				isOpen={modalOpen}
				close={handleModalAction}
				title={`${editMode ? "Discard" : "Edit"} Location and Menu Associations`}
				showSubmitAction={true}
				submitTitle={editMode ? "Confirm" : "Continue"}
				submitAction={() => handleModalAction(true)}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				{editMode
					? "Location and Menu Associations will be discarded, are you sure you want to go back?"
					: "Are you sure you want to edit Location and Menu Associations?"}
			</Modal>

			<Modal
				isOpen={brandSelectionOpen}
				close={handleBrandSelectionModalAction}
				title="Alert"
				showSubmitAction={true}
				submitTitle="Yes"
				submitAction={() => handleBrandSelectionModalAction(true)}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				Switching the brand will loose your current progress and will reset the flow, do you want to continue?
			</Modal>
			<Modal
				isOpen={partialUrlAssociation}
				close={handleURLModalAction}
				title="Proceed without complete association?"
				showSubmitAction={true}
				submitTitle="Proceed Anyway"
				submitAction={() => handleURLModalAction(true)}
				showCancelAction={true}
				submitButtonWidth={"150"}
				cancelTitle="Add links"
				showCloseIcon={false}
			>
				<div className="urlModal">
					<div
						className="status-alert-container "
						style={{
							borderLeft: `6px solid #3B5FEB`,
							backgroundColor: "#F2F5FF"
						}}
					>
						<img src="/assets/icons/info-blue.svg" />

						<div className="error-text-link">
							<div className="main-desc">
								Only UrbanPiper locations associated with platform links will be requested to go live.
							</div>
						</div>
					</div>
					{/* <div className="main-desc">
				You have not added Zomato platform links to few UrbanPiper locations, Do you want to proceed with only mapped storefronts?
				</div> */}
					<div className="modal-desc">Are you sure you want to proceed?</div>
				</div>
			</Modal>
			<Modal
				isOpen={switchAccountOpen}
				close={() => handleSwitchAccount(false)}
				title="Switch Account"
				showSubmitAction={true}
				submitTitle="Continue"
				submitAction={() => handleSwitchAccount(true)}
				submitBtnLoading={isLoading}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				Are you sure you want to switch account? You will loose all location and menu associations for the
				current account.
			</Modal>
		</div>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	dspIntegration: store.dspIntegration,
	stores: store.configItems.stores,
	menus: store.configItems.menus,
	bizPlatforms: store.configItems.bizPlatforms
});
export default connect(mapStateToProps)(LocationsAndMenu);

export const Filters = ({
	searchFieldValue,
	handleSearchFilter,
	statusFilter = {},
	handleStatusFilter,
	statusValue,
	loading = false,
	showStoreStatus = true,
	showActivationStatus = false,
	renderBrandSelection = () => null,
	isMultibrandEnabled = false,
	brandEdit = false
}) => {
	return (
		<div className="filters-container">
			<div className="filters-left">
				{isMultibrandEnabled && renderBrandSelection({ readOnly: brandEdit })}
				{showStoreStatus && (
					<SelectFilter
						options={[{ valueForDisplay: "All", value: "all" }, ...(statusFilter?.values ?? [])]}
						currValue={statusValue}
						field={statusFilter?.field || ""}
						setFilter={handleStatusFilter}
						isClearable={false}
						customDropdownLabel={
							statusValue ? (
								<div className="custom-value">
									Status <span>{statusValue?.valueForDisplay}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="Select Status"
					/>
				)}
				{showActivationStatus && (
					<SelectFilter
						options={[
							{ valueForDisplay: "All", value: "all" },
							{ valueForDisplay: "Failed", value: "failed" },
							{ valueForDisplay: "Success", value: "Success" }
						]}
						currValue={statusValue}
						field={statusFilter?.field || ""}
						// setFilter={handleStatusFilter}
						setFilter={() => {}}
						isClearable={false}
						customDropdownLabel={
							statusValue ? (
								<div className="custom-value">
									Status <span>{statusValue?.valueForDisplay}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="Select Status"
					/>
				)}
			</div>
			<SearchFilter
				filterOption={{ field: "searchFieldValue" }}
				value={searchFieldValue}
				setFilter={handleSearchFilter}
				placeholder="Search"
			/>
		</div>
	);
};

export const getColumns = (dsp, editMode = false, isMultibrandEnabled = false, selectedBrand = null) => {
	const columns = [
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} Store Locations</div>
				</div>
			),
			field: "dsp-stores",
			render: (record, i) => (
				<div className="table-cell dsp-stores" title={record?.locationName} key={i}>
					<div>{record?.locationName || "--"}</div>
					{record?.externalId && <div className="ssioId">{record?.externalId}</div>}
				</div>
			)
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">UrbanPiper Store Locations</div>
				</div>
			),
			field: "up-stores",
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.location
					: record?.location;
				return (
					<div className="table-cell up-stores" title={"Select a UrbanPiper Store"} key={i}>
						<SelectFilter
							options={[
								...(rest?.stores?.items
									?.map((loc) => ({
										...loc,
										...(loc?.brandLocation ?? {})
									}))
									?.sort((a, b) =>
										a?.id === currValue?.id
											? -1
											: !!rest.mappedLocations[a?.id] - !!rest.mappedLocations[b?.id]
									) ?? [])
							]}
							isLoading={rest?.stores?.isLoading}
							field="location"
							currValue={currValue || null}
							setFilter={(field, value) =>
								!value || !rest?.mappedLocations?.[value?.id]
									? rest.handleAssociationUpdates(record, field, value, currValue)
									: null
							}
							isAsync={true}
							isSearchable={true}
							isClearable={true}
							isNull={record?.location && rest?.associationUpdates?.[record?.id]?.location === null}
							renderCustomOption={(children, props) => (
								<div
									className={
										"custom-option" + (!!rest.mappedLocations[props?.data?.id] ? " disabled" : "")
									}
								>
									{children || ""}
								</div>
							)}
							handleSearch={(search) => fetchStoresDebounced(search, 50, selectedBrand?.id)}
							labelKey="name"
							valueKey="id"
							readOnly={!editMode}
							placeholder="Associate Location"
							validationMessage={
								record?.location && rest?.associationUpdates?.[record?.id]?.location === null
									? "Location association is required"
									: ""
							}
						/>
					</div>
				);
			}
		},
		{
			name: <div className="name">Associated Menu</div>,
			field: "menu",
			render: (record, i, rest) => {
				const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
				const currMenu = rest?.menus?.items?.find(
					(menu) => menu.menuId === currentPlatform?.menu?.progress?.menuId
				);

				return (
					<div className="table-cell menu" title={"Associated Menu"} key={i}>
						<SelectFilter
							options={rest?.menus?.items || []}
							isLoading={rest?.menus?.isLoading}
							field="associatedMenu"
							currValue={
								rest?.associationUpdates?.[record?.id]
									? rest?.associationUpdates?.[record?.id]?.associatedMenu
									: currMenu
							}
							setFilter={(field, value) => rest.handleAssociationUpdates(record, field, value)}
							isAsync={true}
							isSearchable={true}
							isClearable={true}
							handleSearch={(search) => fetchMenusDebounced(search, selectedBrand?.id)}
							labelKey="name"
							valueKey="menuId"
							readOnly={!editMode}
							placeholder="Associate Menu"
						/>
					</div>
				);
			}
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">UrbanPiper Store Locations</div>
				</div>
			),
			field: "up-stores-non-dsp",
			render: (record, i) => {
				return (
					<div className="table-cell up-stores-non-dsp" title={record?.name} key={i}>
						<div className="store">{record?.name || "--"}</div>
					</div>
				);
			}
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} PLATFORM ID</div>
				</div>
			),
			field: "store-platform-id",
			render: (record, i, rest) => {
				const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
				return (
					<div className="table-cell store-platform-id" title={record?.externalId} key={i}>
						<InputWithLabel
							type="text"
							placeholder="Platform ID"
							field="externalId"
							value={
								rest?.associationUpdates?.[record?.id]
									? rest?.associationUpdates?.[record?.id]?.externalId
									: currentPlatform?.externalId
							}
							readOnly={!editMode}
							onChange={(e) => rest.handleAssociationUpdates(record, "externalId", e.target.value)}
						/>
					</div>
				);
			}
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} PLATFORM ID </div>
				</div>
			),
			field: "store-platform-id-display",
			render: (record, i, rest) => {
				const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
				const externalId = rest?.associationUpdates?.[record?.id]
					? rest?.associationUpdates?.[record?.id]?.externalId
					: currentPlatform?.externalId;
				return (
					<div className="table-cell store-platform-id-display" title={externalId} key={i}>
						{externalId ? (
							<div className="url">{externalId}</div>
						) : (
							<div className="text-light">No ID mapped</div>
						)}
					</div>
				);
			}
		},

		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} PLATFORM URL</div>
				</div>
			),
			field: "store-platform-url",
			render: (record, i, rest) => {
				const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
				const externalUrl = rest?.associationUpdates?.[record?.id]
					? rest?.associationUpdates?.[record?.id]?.externalUrl
					: currentPlatform?.outletUrl;

				return (
					<div className="table-cell store-platform-url" title={record?.externalUrl} key={i}>
						<InputWithLabel
							type="text"
							placeholder="Platform URL"
							field="outletUrl"
							value={externalUrl}
							readOnly={!editMode}
							onChange={(e) => {
								rest.handleAssociationUpdates(record, "externalUrl", e.target.value);
							}}
							validationMessage={rest.associationUpdates[record?.id]?.invalidUrl ? "Enter valid URL" : ""}
						/>
						{externalUrl && (
							<a
								href={
									["http://", "https://"].some((protocol) => externalUrl.includes(protocol))
										? externalUrl
										: `http://${externalUrl}`
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src="/assets/icons/icon-external-link.svg" alt="" width={13} height={13} />
							</a>
						)}
					</div>
				);
			}
		},
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO[dsp] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">{PLATFORM_MAP[dsp] || dsp} PLATFORM URL</div>
				</div>
			),
			field: "store-platform-url-display",
			render: (record, i, rest) => {
				const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
				const platformUrl = rest?.associationUpdates?.[record?.id]
					? rest?.associationUpdates?.[record?.id]?.externalUrl
					: currentPlatform?.outletUrl;
				return (
					<div className="table-cell store-platform-url-display" title={platformUrl} key={i}>
						{platformUrl ? (
							<div className="url">{platformUrl}</div>
						) : (
							<div className="text-light">No Url Mapped</div>
						)}
					</div>
				);
			}
		},

		{
			name: <div className="name">Requested Menu</div>,
			field: "menu-display",
			render: (record, i, rest) => {
				if (PLATFORM_MAP[dsp]) {
					return (
						<div
							className="table-cell menu-display"
							title={record?.associatedMenu?.name || "No Menu Assigned"}
							key={i}
						>
							{record?.associatedMenu?.name || "--"}
						</div>
					);
				} else {
					const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);
					const currMenu = rest?.menus?.items?.find(
						(menu) => menu.menuId === currentPlatform?.menu?.progress?.menuId
					);

					return (
						<div
							className="table-cell menu-display"
							title={currMenu?.name ? currMenu?.name : "No Menu Assigned"}
							key={i}
						>
							{currMenu?.name ? (
								<div className="menu-detail">
									{currMenu?.imageUrl ? (
										<img className="menu-img" src={currMenu.imageUrl} />
									) : (
										<img src="/assets/left-nav/icon-menus.svg" />
									)}
									<div className="menu-name">{currMenu?.name}</div>
								</div>
							) : (
								<div className="text-light">No Menu Assigned</div>
							)}
							<div>
								{currentPlatform?.menu?.progress?.menuAssociationStatus === "failed" && (
									<img src="/assets/icons/error-sign.svg" />
								)}
								{/* {currentPlatform?.menu?.progress?.menuAssociationStatus === "in-progress" && (
									<img src="/assets/icons/hourglass.svg" />
								)} */}
							</div>
						</div>
					);
				}
			}
		},

		{
			name: <div className="name">Status</div>,
			field: "status",
			render: (record, i) => {
				if (PLATFORM_MAP[dsp]) {
					return (
						<div className="table-cell status" title={record?.storeStatus || "Unmapped"} key={i}>
							<div className={"tag " + (record?.storeStatus?.toLowerCase() || "unmapped")}>
								{record?.storeStatus || "Unmapped"}
							</div>
						</div>
					);
				} else {
					const STATE_CLASS_MAP = {
						"Not Live": "unmapped",
						disconnected: "unmapped",
						success: "success",
						"in-progress": "mapped",
						failed: "failed",
						"not-live": "unmapped",
						enabled: "success",
						disabled: "mapped",
						unmapped: "unmapped"
					};
					const PLATFORM_STATE = {
						0: "not-live",
						1: "enabled",
						2: "disabled",
						3: "unmapped"
					};
					const status = record.locationPlatforms?.find((loc) => loc["platformName"] === dsp)?.state;

					const currentPlatform = record?.associatedPlatforms.find((loc) => loc["platformName"] === dsp);

					const currentMenuReqState = currentPlatform?.menu?.progress?.menuAssociationStatus;

					// return PLATFORM_STATE[status] ? (
					// 	<div
					// 		className="table-cell status"
					// 		title={PLATFORM_STATE[status]?.split("-")?.join(" ")}
					// 		key={i}
					// 	>
					// 		<div aria-label={status} data-balloon-pos={"down-left"}>
					// 			<span className={"tag " + STATE_CLASS_MAP[PLATFORM_STATE[status]]}>
					// 				{PLATFORM_STATE[status]?.split("-")?.join(" ")}
					// 			</span>
					// 		</div>
					// 	</div>
					// ) : (
					// 	<div className="table-cell status" title={"Unmapped"} key={i}>
					// 		<div aria-label={status} data-balloon-pos={"down-left"}>
					// 			<div className="tag unmapped">Unmapped</div>
					// 		</div>
					// 	</div>
					// );
					const renderPopover = () => {
						return (
							<div className="popover-info">
								<div className="popover-header">
									Storefront Status : {capitaliseText(PLATFORM_STATE[status]?.split("-")?.join(" "))}.
								</div>
								<div className="popover-message">
									{currentMenuReqState === "failed"
										? "The current request has failed"
										: currentMenuReqState === "in-progress"
											? "The current request is in progress."
											: currentMenuReqState === "success"
												? "The current request is successful."
												: " "}
								</div>
							</div>
						);
					};
					return (
						<div className="table-cell status" key={i}>
							<Popover showOnHover renderPopover={renderPopover} position="down-right">
								<div
									className={
										"tag " +
										(STATE_CLASS_MAP[currentPlatform?.menu?.progress?.menuAssociationStatus] ||
											STATE_CLASS_MAP[PLATFORM_STATE[status]] ||
											"unmapped")
									}
								>
									{currentPlatform?.menu?.progress?.menuAssociationStatus ??
										PLATFORM_STATE[status]?.split("-")?.join(" ")}
								</div>
							</Popover>
						</div>
					);
					// return (
					// 	<div className="table-cell status" key={i}>
					// 		<div aria-label={label} data-balloon-pos={"down-right"}>
					// 			<div
					// 				className={
					// 					"tag " +
					// 					(STATE_CLASS_MAP[currentPlatform?.menu?.progress?.menuAssociationStatus] ||
					// 						"unmapped")
					// 				}
					// 			>
					// 				{currentPlatform?.menu?.progress?.menuAssociationStatus || "Unmapped"}
					// 			</div>
					// 		</div>
					// 	</div>
					// );
				}
			}
		}
	];
	return columns;
};
