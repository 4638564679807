import React, { useState, useEffect } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { NumericalStepDisplay } from "../components/_commons/NumericalStepDisplay";
import ChooseReport from "../components/Reports/ChooseReport";
import SelectFilters from "../components/Reports/SelectFilters";
import SetupSchedule from "../components/Reports/SetupSchedule";

// third party
import history from "../history";
import { connect } from "react-redux";

// store
import { store } from "../store/configureStore";

// utils
import { trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { createScheduleReport, fetchScheduleReportsList } from "../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "../components/Reports/Overview";

const ScheduleReportCreate = ({
	reportsList,
	reportDetail,
	scheduleReportCreate,
	isMultibrandEnabled = false,
	bizId
}) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [selectedStep, setSelectedStep] = useState(1);
	const { data, optionUpdates, appliedFilters, emails, validations } = reportDetail;
	const { loading, scheduleReportData } = scheduleReportCreate;

	useEffect(() => {
		setTimeout(() => setFormOpen(true), 60);
		createScheduleReport();
	}, []);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const handleClose = () => {
		setFormOpen(false);
		setFormTouched(false);

		// reset report detail state
		store.dispatch({
			type: ActionTypes.RESET_REPORT_DETAIL
		});

		setTimeout(() => {
			history.push("/reports/list/schedules");
			fetchScheduleReportsList();
		}, 100);
	};

	const handleCancel = () => {
		if (selectedStep === 2) {
			// reset report detail state
			store.dispatch({
				type: ActionTypes.RESET_REPORT_DETAIL
			});
		}

		setSelectedStep((curr) => curr - 1);
	};

	const handleValidations = () => {
		// validations for required fields
		let validationsFound = false;
		const updatedValidations = { ...validations };
		const validationMessage = "This field is required.";

		data.filters
			.filter((f) => f.field !== "time_period")
			.forEach((f) => {
				const field = f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase());
				if (f.required && Object.values(optionUpdates[field])?.filter((val) => val)?.length === 0) {
					validationsFound = true;
					updatedValidations[field] = validationMessage;
				}
			});

		scheduleReportData.scheduleReportFilters
			.filter((f) => f.field === "showDataFor")
			.forEach((f) => {
				if (!appliedFilters[f.field]) {
					validationsFound = true;
					updatedValidations[f.field] = validationMessage;
				}
			});

		if (!appliedFilters["exportFormat"]) {
			validationsFound = true;
			updatedValidations["exportFormat"] = validationMessage;
		}
		if (!appliedFilters["scheduleFor"]) {
			validationsFound = true;
			updatedValidations["scheduleFor"] = validationMessage;
		}
		if (!emails.length) {
			validationsFound = true;
			updatedValidations["emails"] = validationMessage;
		}

		return { validationsFound, updatedValidations };
	};

	const handleSubmit = async () => {
		const { validationsFound, updatedValidations } = handleValidations();
		if (validationsFound) {
			updateReportDetail({
				validations: updatedValidations
			});
			return;
		}

		// submit and schedule report
		const variables = {
			name: data?.name,
			reportId: data?.id,
			group: data?.group,
			filters: {},
			scheduleFor: appliedFilters?.scheduleFor?.value,
			showDataFor: appliedFilters?.showDataFor?.value,
			exportFormat: appliedFilters?.exportFormat?.id,
			status: "active",
			emails
		};

		// add filters
		for (let filter in optionUpdates) {
			if (filter !== "brands" && filter !== "timePeriod") {
				variables.filters[filter] = [
					...(variables.filters[filter] ?? []),
					...Object.keys(optionUpdates[filter]).filter((key) => optionUpdates[filter][key])
				];
			}
		}

		const status = await createScheduleReport(variables);
		if (status?.success) {
			handleClose();

			// track event
			trackEvent("schedule_report_create", {
				report_name: variables.name,
				report_category: REPORTS_GROUP_MAP[variables?.group]?.name || "",
				frequency: variables.scheduleFor,
				report_format: variables.exportFormat?.toUpperCase()
			});
		}
	};

	return (
		<div className="schedule-report-create">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				loading={loading}
				submitTitle="Confirm and Schedule"
				submitButtonWidth={200}
				submit={handleSubmit}
				cancelTitle="Previous"
				cancel={handleCancel}
				disabled={reportDetail?.loading || false}
				hideHeader={true}
				hideActions={selectedStep === 1}
			>
				<NumericalStepDisplay
					count={2}
					selectedValue={selectedStep}
					showProgressBar={true}
					title="Create Schedule Report"
				/>
				<div className="form-content">
					{selectedStep === 1 && (
						<ChooseReport reportsList={reportsList} setSelectedStep={setSelectedStep} bizId={bizId} />
					)}
					{selectedStep === 2 && (
						<SelectFilters
							reportDetail={reportDetail}
							scheduleReportCreate={scheduleReportCreate}
							isFormTouched={isFormTouched}
							setFormTouched={setFormTouched}
							isMultibrandEnabled={isMultibrandEnabled}
						/>
					)}
					{selectedStep === 2 && (
						<SetupSchedule
							createView={true}
							reportDetail={reportDetail}
							scheduleReportCreate={scheduleReportCreate}
							isFormTouched={isFormTouched}
							setFormTouched={setFormTouched}
							isMultibrandEnabled={isMultibrandEnabled}
							bizId={bizId}
						/>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	reportsList: store.reportsNewList,
	reportDetail: store.reportDetail,
	scheduleReportCreate: store.scheduleReportCreate,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	bizId: store?.login?.loggedInbizDetail?.id
}))(ScheduleReportCreate);
