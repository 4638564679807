import React, { useCallback, useEffect, useRef, useState } from "react";

// components
import { Header } from "../components/TimingGroupsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import CreateIcon from "../components/_commons/CreateIcon";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import { Paginator } from "../components/_commons/Paginator";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import ContextMenu from "../components/_commons/ContextMenu";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { Topbar } from "../components/_commons/Topbar";

// clients
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBizPlatforms, fetchBrands } from "../actions/actions";
import { fetchHolidayScheduleList, fetchTimingGroupsList } from "../actions/timingGroups";

// utils
import {
	adjustNestedContainer,
	capitaliseText,
	extractInitials,
	getHolidayHoursPlatforms,
	getSortOrder
} from "../atlas-utils";
import history from "../history";

// constant
import { NESTED_ENTITY_TYPES } from "../client-config";
import moment from "moment";
import { CustomTable } from "../components/_commons/CustomTable";

const CONTEXT_MENU_INITIAL_STATE = {
	contextId: null
};

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const TIMING_GROUP_TABS = [
	{
		label: "Regular Schedule",
		value: "regular"
	},
	{
		label: "Holiday Schedule",
		value: "holiday"
	}
];

const TimingGroupsList = ({ configItems, access, timingGroupsList, biz, holidayScheduleList }) => {
	const searchFilterOption = timingGroupsList?.data?.filters?.find((fl) => fl.field === "title");
	const [searchTerm, setSearchTerm] = useState("");
	const [isActive, setIsActive] = useState(true);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [selectedTab, setSelectedTab] = useState(TIMING_GROUP_TABS[0].value);
	const [contextMenuData, setContextMenuData] = useState(CONTEXT_MENU_INITIAL_STATE);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [archRestData, setArchRestData] = useState([]);

	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [sort, setSort] = useState({ field: "title", order: "ASC" });

	const isHolidayTab = selectedTab === TIMING_GROUP_TABS[1].value;

	const holidayHourEnabledPlatforms = getHolidayHoursPlatforms(configItems?.bizPlatforms?.items);

	const handleActiveFilter = async (field, value) => {
		setIsActive(value.value);
	};

	const handlePageSize = async (field, size) => {
		setLimit(size?.value);
		if (isHolidayTab) {
			await fetchHolidayScheduleList(
				isActive,
				searchTerm,
				biz?.isMultibrandEnabled ? "all" : "",
				size?.value,
				offset,
				sort
			);
		} else {
			await fetchTimingGroupsList(
				isActive,
				searchTerm,
				biz?.isMultibrandEnabled ? "all" : "",
				size?.value,
				offset,
				sort
			);
		}
	};
	const handlePagination = async (page) => {
		const newOffset = (page - 1) * limit;
		setOffset(newOffset);
		if (isHolidayTab) {
			await fetchHolidayScheduleList(
				isActive,
				searchTerm,
				biz?.isMultibrandEnabled ? "all" : "",
				limit,
				newOffset,
				sort
			);
		} else {
			await fetchTimingGroupsList(
				isActive,
				searchTerm,
				biz?.isMultibrandEnabled ? "all" : "",
				limit,
				newOffset,
				sort
			);
		}
	};

	const placeholderContent = {
		placeholderText: "No schedules added yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-timing-group.svg",
		placeholderSubtext:
			"Manage your schedule windows for different days to apply schedules easily to different locations",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Create new Schedule</span>
			</>
		),
		placeholderButtonClickAction: () => {
			if (isHolidayTab) {
				history.push("/timing-groups/holiday-new");
			} else {
				history.push("/timing-groups/new");
			}
		},
		redirectionLink: "/piper-academy/timing-groups",
		redirectionLinkText: "learn more about Schedules",
		size: "medium"
	};

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "schedules"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const setSearchFilter = (field, value) => {
		setSearchTerm(value);
	};
	useEffect(() => {
		if (isHolidayTab) {
			fetchHolidayScheduleList(isActive, searchTerm, biz?.isMultibrandEnabled ? "all" : "", limit, offset, sort);
		} else {
			fetchTimingGroupsList(isActive, searchTerm, biz?.isMultibrandEnabled ? "all" : "", limit, offset, sort);
		}
	}, [searchTerm, isActive, sort, isHolidayTab]);

	useEffect(() => {
		fetchBizPlatforms();
		if (biz?.isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, [biz]);

	const handleArchiveRestoreModal = (toOpen, data) => {
		setArchRestData(data);
		setArchiveRestore(toOpen);
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div
					className={`action-item ${record.isActive ? "action-item--archive" : "action-item--restore"}`}
					onClick={() => handleArchiveRestoreModal(true, record)}
				>
					{record.isActive ? "Archive" : "Restore"}
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = (i) => {
		setContextMenuData((current) => ({ ...current, contextId: i }));
	};
	const handleClickOutsideContextMenu = () => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};
	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				store.dispatch({
					type: isHolidayTab
						? ActionTypes.UPDATE_HOLIDAY_SCHEDULE_DETAIL
						: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
					payload: {
						isActive: !archRestData.isActive
					}
				});
			}
			setArchiveRestore(false);
			if (isHolidayTab) {
				fetchHolidayScheduleList(
					isActive,
					searchTerm,
					biz?.isMultibrandEnabled ? "all" : "",
					limit,
					offset,
					sort
				);
			} else {
				fetchTimingGroupsList(isActive, searchTerm, biz?.isMultibrandEnabled ? "all" : "", limit, offset, sort);
			}
		},
		[archRestData]
	);
	const columns = [
		{
			name: "Name",
			field: "name",
			sortKey: "title",
			render: (record, i, archived) => (
				<div className="at-table-cell at-cell-text name" title={record.title} key={i}>
					<Link
						className={"link-text " + archived}
						to={
							isHolidayTab
								? `/timing-groups/holiday-edit/${record.id}`
								: `/timing-groups/edit/${record.id}`
						}
					>
						<span className="hyperlink hyperlink--black-color">{capitaliseText(record?.title)}</span>
					</Link>
				</div>
			)
		},
		{
			name: "Scheduled For",
			field: "schedule",
			sortKey: isHolidayTab ? "schedule" : "",
			render: (record, i) => (
				<div className="at-table-cell at-cell-text schedule" key={i}>
					{isHolidayTab ? moment(record?.date).format("DD MMM YYYY") : record?.scheduleType}
				</div>
			)
		},
		{
			name: "Time",
			field: "time",
			render: (record, i) => (
				<div className="at-table-cell at-cell-text time" key={i}>
					<div className="slot-list">
						{isHolidayTab ? (
							<>
								{record.slots && record.slots?.[0] && record.slots?.[0] === "00:00 - 23:59" ? (
									<p>Closed all day</p>
								) : (
									<>
										{record.slots?.map((slot, j) => (
											<p key={j}>{slot}</p>
										))}
									</>
								)}
							</>
						) : (
							<>
								{record.slots?.map((slot, j) => (
									<p key={j}>{slot}</p>
								))}
							</>
						)}
					</div>
				</div>
			)
		},
		{
			name: "Assoc. Locations",
			field: "location",
			render: (record, i) => {
				return (
					<div className="at-table-cell at-cell-text location" key={i}>
						{record?.associatedLocationsCount || 0}
					</div>
				);
			}
		},
		{
			name: "",
			field: "menu",
			render: (record, i) => {
				return (
					<div className="menu" key={i}>
						<ContextMenu
							isOpen={contextMenuData?.contextId === record?.id}
							data={{
								isActive: record?.isActive,
								id: record?.id,
								title: record?.title
							}}
							renderMenuItems={renderMenuItems}
							handleOutsideClick={
								contextMenuData?.contextId === record?.id
									? () => handleClickOutsideContextMenu()
									: () => {}
							}
							handleOpenMenu={(e) => {
								e.stopPropagation();
								openContextMenu(record?.id);
							}}
						/>
					</div>
				);
			}
		}
	];

	const handleSort = (field) => {
		if (field === "schedule" && isHolidayTab) {
			setOffset(0);
			setSort({
				field: "date",
				order: getSortOrder(sort, { field: "date" })
			});
		} else {
			const updatedSort = {
				field: field,
				order: getSortOrder(sort, { field })
			};
			setOffset(0);
			setSort(updatedSort);
		}
	};

	return (
		<div className="timing-groups-section section-container-common">
			<div className="header-wrapper">
				<Header
					isCatalogueManagement={access.isCatalogueManagement}
					handlePiperAcademy={handlePiperAcademy}
					isHolidayHourEnabled={holidayHourEnabledPlatforms && holidayHourEnabledPlatforms?.length > 0}
					selectedTab={selectedTab}
				/>
			</div>

			{holidayHourEnabledPlatforms && (
				<Topbar
					tabs={TIMING_GROUP_TABS}
					selectedTab={selectedTab}
					switchTab={(tab) => {
						setSelectedTab(tab.value);
					}}
				/>
			)}
			{isHolidayTab && (
				<div className="holiday-hour-banner">
					<img src="/assets/icons/info.png" alt="info" />
					<p>
						Supported for{" "}
						{holidayHourEnabledPlatforms.length === 1
							? capitaliseText(holidayHourEnabledPlatforms[0]?.platform?.name)
							: holidayHourEnabledPlatforms.map((plt) => capitaliseText(plt?.platform?.name)).join(", ")}
					</p>
				</div>
			)}
			<div className="timing-group-filters-wrapper">
				<div className="row-flex">
					{/* {biz?.isMultibrandEnabled && (
						<SelectFilterCustom
							options={configItems?.brands?.items}
							isLoading={configItems?.brands?.isLoading}
							field="brands"
							currValue={selectedBrand}
							setFilter={(f, value) => handleBrand(value)}
							labelKey={"name"}
							valueKey={"id"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
						/>
					)} */}
					<SelectFilter
						options={[
							{ value: true, valueForDisplay: "Active" },
							{ value: false, valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span> }
						]}
						field={"is_active"}
						currValue={{
							value: "active",
							valueForDisplay: (
								<span>
									<span style={{ color: "#979797" }}>Status </span>
									{isActive ? "Active" : <span style={{ color: "#ff425c" }}>Archived</span>}
								</span>
							)
						}}
						labelKey="valueForDisplay"
						valueKey="value"
						setFilter={handleActiveFilter}
						isSearchable={false}
						isClearable={false}
					/>
				</div>
				<SearchFilter
					filterOption={searchFilterOption}
					value={searchTerm}
					setFilter={setSearchFilter}
					placeholder="Search by name"
				/>
			</div>

			<div>
				<CustomTable
					loading={isHolidayTab ? holidayScheduleList?.loading : timingGroupsList.loading}
					data={isHolidayTab ? holidayScheduleList?.data?.objects : timingGroupsList.data.objects || []}
					columns={biz?.isMultibrandEnabled ? columns : columns.filter((cl) => cl.field !== "brands")}
					classes="timing-groups-list-container"
					content="Timing Groups"
					showPlaceholder
					placeholderContent={placeholderContent}
					sortList={handleSort}
					sortedField={sort?.field}
					sortedOrder={sort?.order}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={isHolidayTab ? holidayScheduleList?.data?.count : timingGroupsList?.data?.count || 0}
					goToPage={handlePagination}
					setPageSize={handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleNestedEntity(false)}
					nestedRef={nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
				<ArchiveRestore
					isOpen={archiveRestore}
					close={handleArchiveRestore}
					entityType={isHolidayTab ? CATALOGUE_ENTITY_TYPES[19] : CATALOGUE_ENTITY_TYPES[9]}
					entityName={archRestData?.title}
					object={archRestData}
					mode={archRestData.isActive ? "archive" : "restore"}
					isNested={true}
					overlaySidebarClasses="medium"
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	timingGroupsList: store.timingGroupsList,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	holidayScheduleList: store.holidayScheduleList
});
export default connect(mapStateToProps)(TimingGroupsList);
