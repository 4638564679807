import React from "react";

// utils
import { capitaliseText } from "../../atlas-utils";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
export const PLATFORM_MAP = {
	ubereats: "Uber Eats",
	doordash: "DoorDash"
};

function PreviewMenuPullSource({ selectedPlatform = {}, onProceed = () => {}, onBack = () => {} }) {
	return (
		<div className="onboarding-menus__preview-menu-pull-source">
			<div className="header">
				<div className="platform-logos">
					<div
						className="platform"
						title={PLATFORM_MAP[selectedPlatform?.platform] || capitaliseText(selectedPlatform?.platform)}
					>
						<img
							src={
								CATALOGUE_PLATFORMS_LOGO[selectedPlatform?.platform] ||
								"/assets/icons/icons8-globe-40.png"
							}
							alt=""
						/>
					</div>
					<div className="integration-logo">
						<img src="/assets/icons/icon-integrate.svg" alt="" />
					</div>
					<div className="platform" title="UrbanPiper">
						<img src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"]} alt="" />
					</div>
				</div>

				<Text className="header__title" variant={TextVariant.HEADING2}>
					Import from {PLATFORM_MAP[selectedPlatform?.platform] || capitaliseText(selectedPlatform?.platform)}
				</Text>
				<Text className="header__subtitle" variant={TextVariant.BODY1}>
					{PLATFORM_MAP[selectedPlatform?.platform] || capitaliseText(selectedPlatform?.platform)} is a
					leading online food ordering platform, currently supported in India on UrbanPiper
				</Text>
			</div>
			<div className="body">
				<Text className="header__subtitle" variant={TextVariant.BODY1}>
					You can import your menu from{" "}
					{PLATFORM_MAP[selectedPlatform?.platform] || capitaliseText(selectedPlatform?.platform)}, including
					key catalogue details such as:
				</Text>
				<div className="attributes">
					{["Categories", "Items", "Modifier groups", "Modifiers"].map((attr) => (
						<div className="attribute">
							<img src="/assets/icons/icon-spoon-fork.svg" alt="" />
							<Text className="header__subtitle" variant={TextVariant.BODY1}>
								{attr}
							</Text>
						</div>
					))}
				</div>
			</div>
			<div className="footer">
				<Button variant={ButtonVariant.Primary} size={ButtonSize.LG} onClick={onProceed}>
					Proceed
				</Button>
				<Button variant={ButtonVariant.Secondary} size={ButtonSize.LG} onClick={onBack}>
					Back
				</Button>
			</div>
		</div>
	);
}

export default PreviewMenuPullSource;
