import React from "react";

// components
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function Footer({ isContinueDisabled, handleBack, handleContinue, isLoading, showContinue = true }) {
	return (
		<div className="onboarding-footer">
			{showContinue ? (
				<Button
					disabled={isContinueDisabled || isLoading}
					variant={ButtonVariant.PRIMARY}
					onClick={handleContinue}
					size={ButtonSize.LG}
				>
					Continue
				</Button>
			) : null}
			<Button disabled={isLoading} onClick={handleBack} variant={ButtonVariant.Secondary}>
				Back
			</Button>
		</div>
	);
}

export default Footer;
