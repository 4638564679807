import React, { useEffect, useRef } from "react";
import history from "../../history";

//components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/atoms/text";
import Button, { ButtonVariant, ButtonSize } from "@urbanpiper-engineering/horizon/dist/atoms/button";

function UploadSpreadSheetSuccess({ autoRedirect = false, title }) {
	const redirectionTimeoutRef = useRef(null);

	useEffect(() => {
		if (!autoRedirect) return;

		redirectionTimeoutRef.current = setTimeout(() => {
			history.push("/onboarding");
		}, 2000);

		return () => {
			clearTimeout(redirectionTimeoutRef.current);
		};
	}, []);

	return (
		<div className="upload-spreadsheet-success">
			<img className="success-icon" src="/assets/icons/success-green-two-icon.svg" alt="" />
			<Text className="success-heading" variant={TextVariant.HEADING3}>
				{title}
			</Text>

			<Button
				className="back-to-setup--cta"
				variant={ButtonVariant.Secondary}
				size={ButtonSize.LG}
				onClick={() => {
					history.push("/onboarding");
				}}
			>
				Back to Setup
			</Button>

			{autoRedirect ? (
				<div className="redirection-message">
					<img className="redirection-icon" src="/assets/icons/redirection-icon.svg" alt="redirection" />
					<Text className="redirection-text" variant={TextVariant.BODY1}>
						You will be redirected to setup page
					</Text>
				</div>
			) : null}
		</div>
	);
}

export default UploadSpreadSheetSuccess;
