import React, { useEffect, useRef, useState } from "react";

// third party
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "../_commons/DatePicker";

// component
import { CheckBox } from "../_commons/CheckBox";
import DropdownCustom from "../_commons/DropdownCustom";
import { FormSidebar } from "../_commons/FormSidebar";
import FormTable from "../_commons/FormTable";
import Image from "../_commons/Image";
import { Paginator } from "../_commons/Paginator";
import Placeholder from "../_commons/Placeholder";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectAllFields } from "../_commons/SelectAllFields";
import { SelectFilter } from "../_commons/SelectFilter";

// client
import { clientMenu } from "../../client-menu";

import { client } from "../../client";

// actions
import { fetchMenusList, getMenuPublishValidationStatus, resetMenuPublishValidationStatus } from "../../actions/menus";

// graphql
import { GET_BIZ_PLATFORMS_MINI } from "../../graphql/locations";
import { GET_BIZ_CITIES, GET_BIZ_LOCATION_GROUPS, GET_PUBLISH_LOCATIONS_LIST, PUBLISH_MENU } from "../../graphql/menus";

// services
import NotificationServices from "../../services/NotificationService";

// utils
import { formatDate, getBrandLevelPublishPlatforms, trackEvent } from "../../atlas-utils";

// constants
import { CATALOGUE_PLATFORMS_LOGO, TRACKING_EVENT_NAMES } from "../../client-config";
import SatismeterService from "../../services/SatismeterService";
import FixErrorsDrawer from "../MenuEdit/FixErrorsDrawer";
import { InfiniteLoader } from "../SiteComp";

const LOCATIONS_LIST_INIT_STATE = {
	count: 0,
	objects: [],
	filters: []
};
const INIT_LOADING_STATES = {
	initLoading: true,
	loadingLocations: false,
	loadingPlatforms: false,
	loadingInventoryPlatforms: false,
	loadingCities: false,
	loadingLocationGroups: false
};

const MenuPublish = ({
	menuTitle = "",
	isOpen = false,
	brandId,
	publishType = "instant",
	showHideMenuPublish,
	menuId,
	isFromMenusList,
	handleItemOverridesUpdate,
	handleDetailsEditSection,
	parentSectionDetailsData,
	menuDetailsData,
	isPOSmenuType = false,
	menuPublishValidationStatus,
	isEditSchedulePublishFlow = false,
	isMultibrandEnabled = false
}) => {
	const [step, setStep] = useState(1);
	const [selectedLocations, setSelectedLocations] = useState({});
	const [isAllLocationsSelected, setIsAllLocationsSelected] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [selectedPlatforms, setSelectedPlatforms] = useState({});
	const [offset, setOffset] = useState(0);
	const [locationsData, setLocationsData] = useState(LOCATIONS_LIST_INIT_STATE);
	const [platformsData, setPlatformsData] = useState({});
	const [inventoryPlatformsData, setInventoryPlatformsData] = useState({});
	const [loadingStates, setLoadingStates] = useState(INIT_LOADING_STATES);

	const [selectedDate, setSelectedDate] = useState();
	const [expandedSections, setExpandedSections] = useState({});
	const [respState, setRespState] = useState("");
	const [processingRequest, setProcessingRequest] = useState(false);
	const [appliedFilters, setAppliedFilters] = useState({
		city: null,
		locationGroup: null,
		searchKey: ""
	});
	const [isFixErrorDrawerOpen, setIsFixErrorDrawerOpen] = useState(false);

	const [showpublishAnyway, setShowPublishAnyway] = useState(false);
	const [citiesData, setCitiesData] = useState([]);
	const [locationGroupsData, setlocationGroupsData] = useState([]);
	const debouncedSearchRef = useRef();
	const { searchKey, city, locationGroup } = appliedFilters;

	const menuPublishStepwiseSubtitle = {
		1: "Select which locations you want to publish this menu for",
		2: "Select which platforms you want to publish this menu for",
		3: ""
	};

	const menuPublishSubmitTitle = {
		1: "Next: Select Platforms",
		2: "Next: Schedule time",
		3: "Publish Anyway"
	};

	const stepwiseDisabledSubmitActionConditions = {
		1: Object.values(selectedLocations)?.filter((val) => val !== false)?.length === 0,
		2: Object.keys(selectedPlatforms).length === 0,
		3: !showpublishAnyway
	};

	const handleDateSelection = (type, value) => {
		if (type === "date") {
			setSelectedDate(value);
			if (expandedSections?.date) {
				handleSectionExpansion("date");
			}
			return;
		}
	};

	const handleSectionExpansion = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			} else {
				updatedExpandedSections[field] = true;
			}

			return updatedExpandedSections;
		});
	};

	const handleSectionOutsideClick = (field) => {
		setExpandedSections((current) => {
			const updatedExpandedSections = {
				...current
			};
			if (current[field]) {
				delete updatedExpandedSections[field];
			}
			return updatedExpandedSections;
		});
	};

	const fetchLocationsList = async (offset = 0) => {
		setLoadingStates((current) => ({
			...current,
			loadingLocations: true
		}));
		try {
			const variables = {
				limit: 10,
				offset: offset,
				filters: {
					brand: brandId ? String(brandId) : null
				},
				searchKeyword: appliedFilters?.searchKey || null,
				menuId,
				sortOptions: {
					name: null,
					showAssociatedLocationsFirst: true
				}
			};

			if (appliedFilters?.city) {
				variables.filters = {
					...variables?.filters,
					cities: [appliedFilters?.city?.value]
				};
			}
			if (appliedFilters?.locationGroup) {
				variables.filters = {
					...variables?.filters,
					locationGroupIds: [appliedFilters?.locationGroup?.value]
				};
			}

			const respLocation = await clientMenu.query({
				query: GET_PUBLISH_LOCATIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (respLocation?.data?.locations) {
				setLocationsData({
					objects: respLocation.data.locations?.objects || [],
					count: respLocation.data.locations?.totalObjectCount || 0
				});
				setIsAllLocationsSelected(false);
				const currentAssociatedLocations = {
					...selectedLocations
				};

				!isPOSmenuType
					? publishType === "instant"
						? (respLocation.data?.locations?.objects ?? []).forEach((loc) => {
								loc.publishedMenus.forEach((menu) => {
									if (
										menu.menuDetails.menuId === menuId &&
										currentAssociatedLocations[loc?.id] === undefined
									) {
										currentAssociatedLocations[loc?.id] = loc;
									}
								});
						  })
						: (respLocation.data?.locations?.objects ?? []).forEach((loc) => {
								loc.scheduledPublishes.forEach((menu) => {
									if (
										menu.menuDetails.menuId === menuId &&
										currentAssociatedLocations[loc?.id] === undefined
									) {
										currentAssociatedLocations[loc?.id] = loc;
									}
								});
						  })
					: (respLocation.data?.locations?.objects ?? []).forEach((loc) => {
							loc.ingestedMenus.forEach((menu) => {
								if (
									menu.menuDetails.menuId === menuId &&
									currentAssociatedLocations[loc?.id] === undefined
								) {
									currentAssociatedLocations[loc?.id] = loc;
								}
							});
					  });
				if (Object.keys(currentAssociatedLocations).length > 0) {
					setSelectedLocations(currentAssociatedLocations);
				}
			}
			setLoadingStates((current) => ({
				...current,
				initLoading: false,
				loadingLocations: false
			}));
		} catch (e) {
			console.log(e);
			setLoadingStates((current) => ({
				...current,
				initLoading: false,
				loadingLocations: false
			}));
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		fetchLocationsList((page - 1) * 10);
	};

	const fetchInventoryPlatformsList = async () => {
		setLoadingStates((current) => ({
			...current,
			loadingInventoryPlatforms: true
		}));
		try {
			const variables = {
				filters: [
					{
						field: "is_enabled",
						value: true
					},
					{
						field: "platform__kind",
						value: "inventory"
					}
				],
				includeUrbanpiper: false
			};
			const respPlatforms = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});

			setInventoryPlatformsData(respPlatforms?.data?.bizPlatforms ?? {});
			setLoadingStates((current) => ({
				...current,
				loadingInventoryPlatforms: false
			}));
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingInventoryPlatforms: false
			}));
			console.log(e);
		}
	};

	const fetchPlatformsList = async () => {
		setLoadingStates((current) => ({
			...current,
			loadingPlatforms: true
		}));
		try {
			const variables = {
				filters: [
					{
						field: "is_enabled",
						value: true
					}
				],
				includeUrbanpiper: false
			};
			const respPlatforms = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});

			setPlatformsData(respPlatforms?.data?.bizPlatforms ?? {});
			setLoadingStates((current) => ({
				...current,
				loadingPlatforms: false
			}));
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingPlatforms: false
			}));
			console.log(e);
		}
	};

	const fetchCityList = async () => {
		setLoadingStates((current) => ({
			...current,
			loadingCities: true
		}));
		try {
			const variables = {};
			const respCities = await clientMenu.query({
				query: GET_BIZ_CITIES,
				variables,
				fetchPolicy: "no-cache"
			});
			const transFormedCitiesData = respCities?.data?.cities
				?.filter((city) => city.length > 1)
				.map((city) => ({
					value: city,
					valueForDisplay: city
				}));
			setCitiesData(transFormedCitiesData ?? []);
			setLoadingStates((current) => ({
				...current,
				loadingCities: false
			}));
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingCities: false
			}));
			console.log(e);
		}
	};
	const fetchLocationGroupList = async () => {
		setLoadingStates((current) => ({
			...current,
			loadingLocationGroups: true
		}));
		try {
			const variables = {};
			if (isMultibrandEnabled) {
				variables.brandId = String(brandId);
			}
			const respLocationGroups = await clientMenu.query({
				query: GET_BIZ_LOCATION_GROUPS,
				variables,
				fetchPolicy: "no-cache"
			});
			const transFormedLocationGroupData = respLocationGroups?.data?.locationGroups.map((lg) => ({
				value: lg?.id,
				valueForDisplay: lg?.name
			}));

			setlocationGroupsData(transFormedLocationGroupData ?? []);
			setLoadingStates((current) => ({
				...current,
				loadingLocationGroups: false
			}));
		} catch (e) {
			setLoadingStates((current) => ({
				...current,
				loadingLocationGroups: false
			}));
			console.log(e);
		}
	};

	const handleStepChange = () => {
		if (step === 3 && publishType === "instant") {
			setRespState("success");
			setStep(5);
			return;
		}
		if (step === 4) {
			handleMenuPublish();
			return;
		}
		if (isEditSchedulePublishFlow && publishType !== "instant" && step === 2) {
			setStep(4); // skips validation
			return;
		}
		setStep((current) => current + 1);
	};

	const handleClose = () => {
		showHideMenuPublish(false, "", respState);
		setStep(1);
		setSelectedLocations({});
		setLocationsData(LOCATIONS_LIST_INIT_STATE);
		setSelectedPlatforms({});
		setShowAllFieldSelector(false);
		setAllSelected(false);
		setIsAllLocationsSelected(false);
		setLoadingStates(INIT_LOADING_STATES);
		if (showpublishAnyway) {
			resetMenuPublishValidationStatus();
		}
	};

	const handleMenuPublish = async () => {
		setProcessingRequest(true);
		try {
			const variables = {
				menuId,
				action: "publish",
				locationIds: Object.keys(selectedLocations)
					?.filter((key) => selectedLocations[key] !== false)
					?.map((loc) => parseInt(loc)),
				platformIds: Object.keys(selectedPlatforms).map((plf) => String(plf)),
				isActive: true,
				brand: String(brandId),
				scheduleTimestamp: publishType === "instant" ? null : moment(selectedDate).unix(),
				locationInfo: {
					locationIds: Object.keys(selectedLocations)
						?.filter((key) => selectedLocations[key] !== false)
						?.map((loc) => parseInt(loc)),
					brandId: String(brandId),
					allLocations: allSelected ? true : false,
					cities: appliedFilters?.city?.value ? [appliedFilters?.city?.value] : [],
					locationGroupIds: appliedFilters?.locationGroup?.value ? [appliedFilters.locationGroup.value] : []
				}
			};
			trackEvent(TRACKING_EVENT_NAMES.MENU_PUBLISH, {
				is_scheduled: publishType === "instant" ? false : true,
				no_of_locations: Object.keys(selectedLocations)?.filter((key) => selectedLocations[key] !== false)
					?.length,
				no_of_platforms: Object.keys(selectedPlatforms)?.length,
				time_to_publish: selectedDate
			});
			const publishMenuResp = await clientMenu.mutate({
				mutation: PUBLISH_MENU,
				variables
			});

			if (publishMenuResp?.data?.publishMenu?.status?.success) {
				setRespState("success");
				NotificationServices.pushNotification({
					message: "Publish request processed successfully",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				// satismeter event
				SatismeterService.menuOverCatalogueEvents();
				if (isFromMenusList) {
					fetchMenusList(brandId ? String(brandId) : null);
				}
			} else {
				setRespState("failure");
				NotificationServices.pushNotification({
					message: "Failed to trigger publish request",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			setStep(5);
		} catch (e) {
			console.log(e);
			setRespState("failure");
			NotificationServices.pushNotification({
				message: "Failed to trigger publish request",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			setStep(5);
		}
		setProcessingRequest(false);
	};

	const handleFilters = (field, value) => {
		setOffset(0);
		setAppliedFilters((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleSearch = (field, value) => {
		setOffset(0);
		setAppliedFilters((current) => ({
			...current,
			[field]: value
		}));
	};

	const handlePreviousClick = () => {
		if (step === 1 || respState) {
			handleClose();
			return;
		}

		setStep((current) => current - 1);
	};

	const triggerMenuPublish = async (validate) => {
		const getAction = () => {
			if (publishType === "instant") {
				return "publish";
			} else if (publishType !== "instant" && !validate) {
				return "publish";
			} else if (publishType !== "instant" && validate) {
				return "validate";
			} else {
				return "publish";
			}
		};
		const getScheduleTimestamp = () => {
			if (publishType === "instant") {
				return null;
			} else if (publishType !== "instant" && validate) {
				return null;
			} else {
				return moment(selectedDate).unix();
			}
		};
		try {
			const variables = {
				menuId,
				action: getAction(),
				locationIds: Object.keys(selectedLocations)
					?.filter((key) => selectedLocations[key] !== false)
					?.map((loc) => parseInt(loc)),
				platformIds: Object.keys(selectedPlatforms).map((plf) => String(plf)),
				isActive: true,
				brand: String(brandId),
				scheduleTimestamp: getScheduleTimestamp(),
				locationInfo: {
					locationIds: Object.keys(selectedLocations)
						?.filter((key) => selectedLocations[key] !== false)
						?.map((loc) => parseInt(loc)),
					brandId: String(brandId),
					allLocations: allSelected ? true : false,
					cities: appliedFilters?.city?.value ? [appliedFilters?.city?.value] : [],
					locationGroupIds: appliedFilters?.locationGroup?.value ? [appliedFilters.locationGroup.value] : []
				}
			};

			const publishMenuResp = await clientMenu.mutate({
				mutation: PUBLISH_MENU,
				variables
			});

			if (publishMenuResp?.data?.publishMenu?.status?.success) {
				return true;
			} else {
				setRespState("failure");
				NotificationServices.pushNotification({
					message: "Failed to trigger publish request",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
			setStep(5);
		} catch (e) {
			console.log(e);
			setRespState("failure");
			NotificationServices.pushNotification({
				message: "Failed to trigger publish request",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			setStep(5);
		}
	};

	const menuPublishStepwiseComponents = {
		1: () => (
			<LocationSelections
				selectedLocations={selectedLocations}
				setSelectedLocations={setSelectedLocations}
				locationsData={locationsData}
				offset={offset}
				handlePagination={handlePagination}
				isAllLocationsSelected={isAllLocationsSelected}
				setIsAllLocationsSelected={setIsAllLocationsSelected}
				loadingStates={loadingStates}
				handleFilters={handleFilters}
				handleSearch={handleSearch}
				appliedFilters={appliedFilters}
				isPOSmenuType={isPOSmenuType}
				citiesData={citiesData}
				locationGroupsData={locationGroupsData}
				allSelected={allSelected}
				setAllSelected={setAllSelected}
				showAllFieldSelector={showAllFieldSelector}
				setShowAllFieldSelector={setShowAllFieldSelector}
			/>
		),
		2: () => (
			<PlatformSelection
				platformsData={platformsData}
				inventoryPlatformsData={inventoryPlatformsData}
				selectedPlatforms={selectedPlatforms}
				setSelectedPlatforms={setSelectedPlatforms}
				selectedLocations={selectedLocations}
				menuId={menuId}
			/>
		),
		3: () => (
			<ValidationView
				menuId={menuId}
				setIsFixErrorDrawerOpen={setIsFixErrorDrawerOpen}
				handleStepChange={handleStepChange}
				showpublishAnyway={showpublishAnyway}
				setShowPublishAnyway={setShowPublishAnyway}
				triggerMenuPublish={triggerMenuPublish}
				handleClose={handleClose}
				menuPublishValidationStatus={menuPublishValidationStatus}
				publishType={publishType}
			/>
		),
		4: () => (
			<DateTimeSelection
				selectedDate={selectedDate}
				handleDateSelection={handleDateSelection}
				handleSectionExpansion={handleSectionExpansion}
				expandedSections={expandedSections}
				handleSectionOutsideClick={handleSectionOutsideClick}
				setSelectedDate={setSelectedDate}
			/>
		),
		5: () => (
			<RequestResponse
				respState={respState}
				selectedPlatformsCount={Object.keys(selectedPlatforms).length}
				selectedLocationsCount={
					Object.keys(selectedLocations)?.filter((key) => selectedLocations[key] !== false)?.length
				}
				handleClose={handleClose}
				handleMenuPublish={handleMenuPublish}
			/>
		)
	};

	useEffect(() => {
		if (isOpen) {
			setSelectedDate(moment().add(15, "minutes"));
			fetchPlatformsList();
			fetchInventoryPlatformsList();
			fetchCityList();
			fetchLocationGroupList();
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
				debouncedSearchRef.current = setTimeout(() => {
					fetchLocationsList();
				}, 500);
			} else {
				debouncedSearchRef.current = setTimeout(() => {
					fetchLocationsList();
				}, 500);
			}
		}

		return () => {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
			}
		};
	}, [searchKey, isOpen]);

	useEffect(() => {
		if (isOpen) {
			fetchLocationsList();
		}
	}, [city, locationGroup, isOpen]);

	return (
		<div className="menu-publish-container">
			<FormSidebar
				isOpen={isOpen}
				title={`Publish ${menuTitle ? menuTitle : "menu"}`}
				subTitle={menuPublishStepwiseSubtitle[step]}
				submitTitle={step === 2 && publishType === "instant" ? "Publish" : menuPublishSubmitTitle[step]}
				submitButtonWidth={200}
				submit={handleStepChange}
				close={handleClose}
				hideActions={step === 5 || step === 3}
				disabled={!!stepwiseDisabledSubmitActionConditions[step]}
				cancelTitle={step === 1 || respState ? "Cancel" : "Previous"}
				cancel={handlePreviousClick}
				overlaySidebarClasses={"large"}
			>
				{processingRequest ? (
					<div className="P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				) : (
					menuPublishStepwiseComponents[step]?.() || null
				)}
				{isFixErrorDrawerOpen && (
					<FixErrorsDrawer
						isOpen={isFixErrorDrawerOpen}
						menuTitle={menuTitle}
						menuId={menuId}
						handleDrawerClose={() => {
							setIsFixErrorDrawerOpen(false);
							handleClose();
						}}
						handleItemOverridesUpdate={handleItemOverridesUpdate}
						isFixPublishMenuFlow
						isNested
						handleStepChange={handleStepChange}
						menuDetailsData={menuDetailsData}
						parentSectionDetailsData={parentSectionDetailsData}
						handleDetailsEditSection={handleDetailsEditSection}
						publishType={publishType}
					/>
				)}
			</FormSidebar>
		</div>
	);
};

const mapStateToProps = (store) => ({
	menuPublishValidationStatus: store.menuPublishValidationStatus,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
});
export default connect(mapStateToProps)(MenuPublish);

const LocationSelections = ({
	locationsData = {},
	selectedLocations,
	setSelectedLocations,
	handlePagination,
	offset,
	isAllLocationsSelected,
	setIsAllLocationsSelected,
	loadingStates,
	handleFilters,
	handleSearch,
	appliedFilters,
	isPOSmenuType = false,
	citiesData = [],
	locationGroupsData = [],
	allSelected = false,
	setAllSelected = () => {},
	showAllFieldSelector = false,
	setShowAllFieldSelector = () => {}
}) => {
	const totalPages = Math.ceil((locationsData?.totalObjectCount || locationsData?.count) / 10);

	const locationsColumn = [
		{
			title: "location name",
			dataIndex: "name",
			render: (data) => data
		},
		{
			title: "city",
			dataIndex: "city",
			render: (data) => data
		}
	];

	const handleSingleFieldSelection = (id) => {
		if (selectedLocations[id]) {
			const updatedSelectedLocation = {
				...selectedLocations,
				[id]: false
			};
			setSelectedLocations({
				...updatedSelectedLocation
			});
			setIsAllLocationsSelected(false);
			return;
		}
		setSelectedLocations((current) => ({
			...current,
			[id]: locationsData?.objects.find((loc) => loc?.id === id)
		}));
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		setAllSelected(selectAllLocations);
		if (!selectAllLocations) {
			setShowAllFieldSelector(false);
			setIsAllLocationsSelected(false);

			// clear selection from all pages
			const updatedSelectedLocations = {};
			for (let loc in selectedLocations) {
				updatedSelectedLocations[loc] = false;
			}
			setSelectedLocations(updatedSelectedLocations);
		}
	};

	const handleAllFieldSelection = (state) => {
		setShowAllFieldSelector(true);
		if (state) {
			const updatedSelectedLocation = {
				...selectedLocations
			};
			locationsData.objects.forEach((loc) => {
				updatedSelectedLocation[loc?.id] = loc;
			});
			setSelectedLocations(updatedSelectedLocation);
			setIsAllLocationsSelected(true);
		} else {
			const updatedSelectedLocation = {
				...selectedLocations
			};
			locationsData.objects.forEach((loc) => {
				updatedSelectedLocation[loc?.id] = false;
			});
			setSelectedLocations(updatedSelectedLocation);
			setIsAllLocationsSelected(false);
			setAllSelected(false);
			setShowAllFieldSelector(false);
		}
	};

	return (
		<div className="locations-selection-table-container">
			<div className="filters-container">
				<div className="dropdown-filters-container">
					<SelectFilter
						placeholder="City"
						options={citiesData}
						valueKey="value"
						labelKey="valueForDisplay"
						setFilter={handleFilters}
						field="city"
					/>
					<SelectFilter
						placeholder="Location Group"
						options={locationGroupsData}
						valueKey="value"
						labelKey="valueForDisplay"
						setFilter={handleFilters}
						field="locationGroup"
					/>
				</div>
				<SearchFilter
					placeholder="Search"
					value={appliedFilters.searchKey}
					setFilter={handleSearch}
					filterOption={{ field: "searchKey" }}
				/>
			</div>
			{showAllFieldSelector && totalPages > 1 && (
				<SelectAllFields
					allSelected={allSelected}
					handleCompleteSelection={handleCompleteSelection}
					mainMessage={
						<span>
							{allSelected ? "All " : ""}
							<strong>
								{allSelected
									? locationsData?.totalObjectCount || locationsData?.count
									: locationsData?.objects?.length}{" "}
							</strong>
							location(s) {allSelected ? "on all pages are selected." : "on this page are selected."}
						</span>
					}
					linkMessage={
						allSelected ? (
							" Clear selection"
						) : (
							<span>
								Select <strong>{locationsData?.totalObjectCount || locationsData?.count}</strong>{" "}
								locations from all pages
							</span>
						)
					}
				/>
			)}
			<FormTable
				columns={locationsColumn}
				dataSource={locationsData?.objects}
				placeholderContent={{
					placeholderText: "No locations found!",
					placeholderImageUrl: "/assets/empty_states/graphics-empty-locations-main.svg"
				}}
				isSelectionEnabled
				handleSingleFieldSelection={handleSingleFieldSelection}
				handleAllFieldsSelection={handleAllFieldSelection}
				selectedFields={selectedLocations}
				isAllFieldSelected={isAllLocationsSelected}
				isLoading={loadingStates?.initLoading}
				contentTableClass={loadingStates?.loadingLocations || isPOSmenuType || allSelected ? "disabled" : ""}
			/>
			<Paginator
				count={locationsData?.totalObjectCount || locationsData?.count || 0}
				limit={10}
				offset={offset}
				goToPage={handlePagination}
				readOnly={loadingStates?.loadingLocations || allSelected}
			/>
		</div>
	);
};

const PlatformSelection = ({
	platformsData,
	inventoryPlatformsData,
	selectedPlatforms,
	setSelectedPlatforms,
	selectedLocations,
	menuId
}) => {
	const [isAllPlatformsSelected, setAllPlatformsSelected] = useState(false);
	const handlePlatformSelection = (id) => {
		if (selectedPlatforms[id]) {
			const updatedPlatforms = {
				...selectedPlatforms
			};
			delete updatedPlatforms[id];
			setSelectedPlatforms(updatedPlatforms);
			setAllPlatformsSelected(false);
			return;
		}
		setSelectedPlatforms((current) => ({
			...current,
			[id]: true
		}));
	};

	const handleAllFieldSelection = (state) => {
		if (!state) {
			setSelectedPlatforms({});
			setAllPlatformsSelected(false);
			return;
		}
		const updatedPlatforms = {};

		[...platformsData.objects, ...inventoryPlatformsData.objects].forEach((platform) => {
			updatedPlatforms[platform?.id] = true;
		});
		setAllPlatformsSelected(true);
		setSelectedPlatforms(updatedPlatforms);
	};

	const updateIntersectionPlatforms = (locationsData) => {
		const locIdsArray = Object.keys(locationsData)?.filter((key) => locationsData[key] !== false);
		const platforms = [];

		for (let i = 0; i < locIdsArray.length; i++) {
			(locationsData[locIdsArray[i]]?.publishedMenus ?? []).forEach((menu) => {
				if (menu.menuDetails.menuId === menuId) {
					platforms.push(menu?.platforms);
				}
			});
		}

		const platformsHashmap = {};

		platforms.forEach((platformsCombined) => {
			for (let i = 0; i < platformsCombined.length; i++) {
				if (platformsHashmap[platformsCombined[i]]) {
					platformsHashmap[platformsCombined[i]]++;
				} else {
					platformsHashmap[platformsCombined[i]] = 1;
				}
			}
		});

		let largest = 0;
		Object.keys(platformsHashmap).forEach((plf) => {
			if (platformsHashmap[plf] > largest) {
				largest = platformsHashmap[plf];
			}
		});

		if (!!largest) {
			const updatedPlatforms = {};

			Object.keys(platformsHashmap).forEach((plf) => {
				if (platformsHashmap[plf] === largest) {
					updatedPlatforms[plf] = true;
				}
			});

			const preselectedPlatforms = {};
			[...(platformsData?.objects ?? []), ...(inventoryPlatformsData?.objects ?? [])].forEach((plf) => {
				if (updatedPlatforms[plf?.platformName?.toLowerCase()]) {
					preselectedPlatforms[plf?.id] = true;
				}
			});
			setSelectedPlatforms(preselectedPlatforms);
		}
	};

	useEffect(() => {
		updateIntersectionPlatforms(selectedLocations);
	}, [selectedLocations]);

	const brandLevelPublishPlatforms = getBrandLevelPublishPlatforms(platformsData);

	const getWarningText = () => {
		if (brandLevelPublishPlatforms?.length && brandLevelPublishPlatforms?.length === 1) {
			return `Publishing to ${brandLevelPublishPlatforms[0]?.platformName} will update the menu across all locations as they don't support location specific publishes`;
		} else if (brandLevelPublishPlatforms?.length && brandLevelPublishPlatforms?.length > 1) {
			return `Publishing to ${brandLevelPublishPlatforms
				.map((platform) => platform.platformName)
				.join(
					", "
				)} will update the menu across all locations as they don't support location specific publishes`;
		}
	};

	return (
		<div className="platforms-selection-container">
			<div className="platforms-selections-section-wrapper">
				<div className="platforms-selection-section">
					{brandLevelPublishPlatforms?.length > 0 && (
						<div className="to-you-jahez-warning-wrapper">
							<div className="warning-icon">
								<img src={"/assets/icons/warning-icon.svg"} />
							</div>
							<div className="warning-text">{getWarningText()}</div>
						</div>
					)}
					<div className="platforms-header-actions-container">
						<div className="header">
							{inventoryPlatformsData?.objects?.length > 0 ? "ONLINE ORDERING PLATFORMS" : "PLATFORMS"}
						</div>
						<div className="select-all-cta-text">
							<span
								className={`hyperlink ${isAllPlatformsSelected ? "hyperlink--red-color" : ""}`}
								onClick={() => handleAllFieldSelection(isAllPlatformsSelected ? false : true)}
							>
								{isAllPlatformsSelected ? "Deselect All" : "Select All"}
							</span>
						</div>
					</div>
					{platformsData?.objects?.length > 0 ? (
						platformsData.objects.map((platform) => (
							<div className="platform-container">
								<div className="platform-selector-title">
									<CheckBox
										clickHandler={() => handlePlatformSelection(platform?.id)}
										checked={selectedPlatforms[platform?.id]}
									/>
									<span>{platform?.platformName}</span>
								</div>
								<div className="platform-logo-container">
									<Image
										src={CATALOGUE_PLATFORMS_LOGO[platform?.platformName.toLowerCase()]}
										className="platform-logo"
									/>
								</div>
							</div>
						))
					) : (
						<div className="no-platorms-found">
							{inventoryPlatformsData?.object?.length > 0
								? "No online ordering platforms found"
								: "No platforms found"}
						</div>
					)}
				</div>
				{inventoryPlatformsData?.objects?.length > 0 && (
					<div className="platforms-selection-section">
						<div className="platforms-header-actions-container">
							<div className="header">INVENTORY PLATFORMS</div>
						</div>
						{inventoryPlatformsData.objects.map((platform) => (
							<div className="platform-container">
								<div className="platform-selector-title">
									<CheckBox
										clickHandler={() => handlePlatformSelection(platform?.id)}
										checked={selectedPlatforms[platform?.id]}
									/>
									<span>{platform?.platformName}</span>
								</div>
								<div className="platform-logo-container">
									<Image
										src={CATALOGUE_PLATFORMS_LOGO[platform?.platformName.toLowerCase()]}
										className="platform-logo"
									/>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const DateTimeSelection = ({
	selectedDate,
	expandedSections,
	handleDateSelection,
	handleSectionExpansion,
	handleSectionOutsideClick,
	setSelectedDate
}) => {
	const INVALID_DATE_TIME_ERROR_MESSAGE = "Please select a time which is atleast 10 minutes from current time";

	const hoursList = [
		{
			label: "12",
			value: "12"
		},
		{
			label: "01",
			value: "01"
		},
		{
			label: "02",
			value: "02"
		},
		{
			label: "03",
			value: "03"
		},
		{
			label: "04",
			value: "04"
		},
		{
			label: "05",
			value: "05"
		},
		{
			label: "06",
			value: "06"
		},
		{
			label: "07",
			value: "07"
		},
		{
			label: "08",
			value: "08"
		},
		{
			label: "09",
			value: "09"
		},
		{
			label: "10",
			value: "10"
		},
		{
			label: "11",
			value: "11"
		}
	];

	const minutesList = [
		{
			label: "00",
			value: "00"
		},
		{
			label: "15",
			value: "15"
		},
		{
			label: "30",
			value: "30"
		},
		{
			label: "45",
			value: "45"
		}
	];

	const meridiemList = [
		{
			label: "AM",
			value: "AM"
		},
		{
			label: "PM",
			value: "PM"
		}
	];

	const handleTimestampFields = (field, value) => {
		if (field === "meridiem") {
			if (value?.value === "AM" && moment(selectedDate).hour() > 12) {
				const newTime = moment(selectedDate).set("hour", moment(selectedDate).hour() - 12);
				const currentTime = moment().add(10, "minutes");
				if (newTime.diff(currentTime) < 0) {
					NotificationServices.pushNotification({
						message: INVALID_DATE_TIME_ERROR_MESSAGE,
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
					return;
				}
				setSelectedDate(newTime);
			} else if (value?.value === "PM" && moment(selectedDate).hour() <= 12) {
				const newTime = moment(selectedDate).set("hour", moment(selectedDate).hour() + 12);
				const currentTime = moment().add(10, "minutes");
				if (newTime.diff(currentTime) < 0) {
					NotificationServices.pushNotification({
						message: INVALID_DATE_TIME_ERROR_MESSAGE,
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
					return;
				}
				setSelectedDate(newTime);
			}
		} else if (field === "minutes") {
			const newTime = moment(selectedDate).set("minute", parseInt(value?.value));
			const currentTime = moment().add(10, "minutes");
			if (newTime.diff(currentTime) < 0) {
				NotificationServices.pushNotification({
					message: INVALID_DATE_TIME_ERROR_MESSAGE,
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return;
			}
			setSelectedDate(newTime);
		} else if (field === "hours") {
			const newTime = moment(selectedDate).set(
				"hour",
				selectedDate?.format("A") === "PM" ? parseInt(value?.value) + 12 : parseInt(value?.value)
			);
			const currentTime = moment().add(10, "minutes");
			if (newTime.diff(currentTime) < 0) {
				NotificationServices.pushNotification({
					message: INVALID_DATE_TIME_ERROR_MESSAGE,
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return;
			}
			setSelectedDate(newTime);
		}
	};

	return (
		<div className="publish-date-selection-container">
			<DropdownCustom
				title="Date"
				selected={formatDate(selectedDate, "DD MMMM YYYY")}
				isOpen={expandedSections?.date}
				handleClick={() => handleSectionExpansion("date")}
				handleOutsideClick={() => handleSectionOutsideClick("date")}
				clickEvent="mousedown"
				showArrow
			>
				<DatePicker
					inline
					onChange={(date) => handleDateSelection("date", date)}
					selected={selectedDate}
					minDate={moment()}
				/>
			</DropdownCustom>
			<div className="time-fields-container">
				<div className="header">Time</div>
				<SelectFilter
					options={hoursList}
					field="hours"
					currValue={{
						label: selectedDate?.format("hh"),
						value: selectedDate?.format("hh")
					}}
					setFilter={handleTimestampFields}
					labelKey="label"
					valueKey="value"
					isClearable={false}
					placeholder="HH"
				/>
				<SelectFilter
					field="minutes"
					options={minutesList}
					currValue={{
						label: selectedDate?.format("mm"),
						value: selectedDate?.format("mm")
					}}
					setFilter={handleTimestampFields}
					labelKey="label"
					valueKey="value"
					isClearable={false}
					placeholder="MM"
				/>
				<SelectFilter
					field="meridiem"
					placeholder="AM/PM"
					currValue={{
						label: selectedDate?.format("A"),
						value: selectedDate?.format("A")
					}}
					options={meridiemList}
					isClearable={false}
					setFilter={handleTimestampFields}
				/>
			</div>
		</div>
	);
};

const RequestResponse = ({
	respState,
	selectedLocationsCount,
	selectedPlatformsCount,
	handleClose,
	handleMenuPublish
}) => {
	const RESP_STATE_IMAGES_URLS = {
		success: "/assets/icons/icon-request-success.svg",
		failure: "/assets/icons/icon-request-failure.svg"
	};

	const RESP_STATE_HEADER_TEXTS = {
		success: "Publish Request Sent!",
		failure: "Publish Request Failed"
	};

	const RESP_STATE_HEADER_SUBTEXT = {
		success: `Menu pushed to ${selectedLocationsCount} locations, ${selectedPlatformsCount} platforms`,
		failure: "Menu was not pushed due to some errors."
	};

	return (
		<Placeholder
			placeholderImageUrl={RESP_STATE_IMAGES_URLS[respState]}
			placeholderText={RESP_STATE_HEADER_TEXTS[respState]}
			placeholderSubtext={RESP_STATE_HEADER_SUBTEXT[respState]}
			placeholderButtonContent={respState === "success" ? "Close" : "Retry"}
			placeholderButtonClickAction={respState === "success" ? handleClose : handleMenuPublish}
			secondaryButtonContent={respState === "failure" ? "Close" : ""}
			secondaryButtonClickAction={handleClose}
		/>
	);
};

const ValidationView = ({
	menuId,
	setIsFixErrorDrawerOpen,
	handleStepChange,
	showpublishAnyway,
	setShowPublishAnyway,
	triggerMenuPublish,
	handleClose = () => {},
	menuPublishValidationStatus = {},
	publishType = "instant"
}) => {
	const fetchPublishErrors = async (interval) => {
		setTimeout(async () => {
			const res = await getMenuPublishValidationStatus(menuId, publishType !== "instant");
			if (res?.status === "SUCCESS") {
				interval = 80;
				handleStepChange();
			} else if (res?.status === "FAILURE") {
				interval = 80;
				if (
					!menuPublishValidationStatus.isLoading &&
					(menuPublishValidationStatus.totalErrors > 0 || menuPublishValidationStatus.totalWarnings > 0)
				) {
					setIsFixErrorDrawerOpen(true);
				} else {
					handleClose();
					NotificationServices.pushNotification({
						message: "Failed to verify publish errors. Try again",
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
				}
			} else if (res?.status === "PENDING") {
				if (interval < 80) {
					fetchPublishErrors(interval + 5);
				}
				if (interval === 20) {
					setShowPublishAnyway(true);
				}
			} else if (res?.status === "ERROR") {
				interval = 80;
				handleClose();
				handleStepChange();
			}
		}, interval * 1000);
	};
	const handleTriggerPublish = async () => {
		const res = await triggerMenuPublish(true);
		if (res) {
			fetchPublishErrors(0);
		}
	};
	useEffect(() => {
		handleTriggerPublish();
	}, []);

	return (
		<>
			<InfiniteLoader />
			<Placeholder
				placeholderImageUrl={
					showpublishAnyway
						? "/assets/empty-states/verification-in-progress-state-2.svg"
						: "/assets/empty-states/verification-in-progress.svg"
				}
				placeholderText={"Checking for errors and warnings"}
				placeholderSubtext={
					"We are checking your menu for errors and warnings to identify any issues before publishing."
				}
			/>
		</>
	);
};
