import React, { Component } from "react";

// components
import { Header } from "../components/LocationsList/Header";
import { CustomTable } from "../components/_commons/CustomTable";
import LocationsEntityAssociation from "../components/LocationsList/LocationsEntityAssociation";
import PublishCatalogue from "../components/LocationsList/PublishCatalogue";
import StoreToggle from "../components/LocationsList/StoreToggle";
import VerifyCatalogue from "../components/LocationsList/VerifyCatalogue";
import GoLive from "../components/LocationsList/GoLive";
import LpaLogs from "../components/LocationsList/LpaLogs";
import CatalogueVerification from "../components/LocationsList/CatalogueVerification";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { CheckBox } from "../components/_commons/CheckBox";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import Popover from "../components/_commons/Popover";
import { Modal } from "../components/_commons/Modal";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import TableColumnSelector from "../components/_commons/TableColumnSelector";
import { Accordion } from "../components/_commons/Accordion";
import CreateIcon from "../components/_commons/CreateIcon";
import { LocationPlatformMappingSidebar } from "../components/LocationsList/LocationPlatformMappingSidebar";

// graphql
import {
	GET_BIZ_PLATFORM_STATUS,
	GET_INVENTORY_AND_ONLINE_ORDERING_BIZ_PLATFORMS,
	GET_LPA_LOGS
} from "../graphql/locations";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";
import moment from "moment";
import PubSub from "pubsub-js";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { Transition, animated, config } from "react-spring/renderprops";

// actions
import { toggleGlobalLoader, fetchItemsDebounced, fetchBrands } from "../actions/actions";
import { fetchLocationsList } from "../actions/locations";
import { ActionTypes } from "../actions/_types";
import { clientMenu } from "../client-menu";
import { GET_MENUS_LIST } from "../graphql/menus";

// utils
import history from "../history";
import {
	scroll,
	parseFilters,
	adjustNestedContainer,
	trackEvent,
	capitaliseText,
	formatDate,
	extractInitials,
	lS
} from "../atlas-utils";

// constants
import { TRACK_EVENT } from "../atlas-utils/tracking";
import {
	CATALOGUE_PLATFORMS_LOGO,
	NESTED_ENTITY_TYPES,
	TRACKING_SOURCE,
	PLATFORM_NAME_MAP,
	ONBOARDING_FLOWS
} from "../client-config";

const TABLE_FIELDS = [
	{
		value: "title",
		valueForDisplay: "Name",
		readOnly: true
	},
	{
		value: "city",
		valueForDisplay: "City",
		readOnly: true
	},
	{
		value: "location_id",
		valueForDisplay: "Location ID",
		readOnly: true
	},
	{
		value: "associated_items",
		valueForDisplay: "Assoc. Items",
		readOnly: false
	},
	{
		value: "menu_status",
		valueForDisplay: "Menu Status",
		readOnly: false
	},
	{
		value: "location_status",
		valueForDisplay: "Location Status",
		readOnly: false
	},
	{
		value: "associated_platforms",
		valueForDisplay: "Assoc. Platform(s)",
		readOnly: false
	},
	{
		value: "associated_brands",
		valueForDisplay: "Assoc. Brands",
		readOnly: false
	}
];

const PLATFORM_STATE = {
	0: "not-live",
	1: "enabled",
	2: "disabled",
	3: "disconnected"
};
const VERIFICATION_STATE = {
	passing: "Success",
	error: "Failed",
	warning: "Warning"
};

const PRISM_VERIFICATION_STATE = {
	created: "Created",
	processing: "Processing",
	success: "Success",
	failed: "Failed",
	error: "Error",
	terminated: "Terminated"
};

const ACTION_STATE_TO_TEXT = {
	ACK_FAILED: "Failed",
	ACK_SUCCESS: "Success",
	FAILED: "Failed",
	NA: "N/A"
};

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "title",
		sortKey: "name",
		render: (record, i, rest) => {
			const status = rest.locations[record.id] === undefined ? rest.toCheck : rest.locations[record.id];
			return (
				<div className="table-cell title" title={record.title} key={i}>
					<div className="location-title">
						{rest.checkbox && (
							<CheckBox
								checked={status}
								clickHandler={(e) => rest.handleCheck(e, record.id, !status)}
								title={record.title || record.id}
								readOnly={rest.disableCheckbox}
							/>
						)}
						<Link className={"link-text " + rest.archived} to={`/locations/edit/${record.id}`}>
							<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
						</Link>
					</div>
					<div className={"tags" + (rest.checkbox ? " aligned" : "")}>
						{record.tags.length > 0 && (
							<div className="tags-container table-mode">
								<div className="list">
									{record.tags.map(
										(tag, i) =>
											i < 2 && (
												<span key={i} className="tag-item" title={tag}>
													{tag}
												</span>
											)
									)}
									{record.tags.length > 2 && (
										<span className="tag-item more-tags" title={record.tags.slice(2).join(", ")}>
											+{record.tags.length - 2} more
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			);
		}
	},
	{
		name: "City",
		field: "city",
		render: (record, i) => (
			<div className="table-cell city" key={i}>
				{record.city || "--"}
			</div>
		)
	},
	{
		name: "Location ID",
		field: "location_id",
		sortKey: "biz_location_id",
		render: (record, i, rest) => (
			<div className="table-cell location_id" title={record.bizLocationId} key={i}>
				<div>
					{!rest?.isMultibrandEnabled
						? record?.bizLocationId || "--"
						: rest?.selectedBrand?.id === "all"
						? "--"
						: record?.brandLocation?.id || "--"}
				</div>
				<div className="text--light desc-text">
					{!rest?.isMultibrandEnabled
						? record?.merchantRefId && Number(record?.merchantRefId) !== -1
							? `POS ID: ${record?.merchantRefId}`
							: ""
						: rest?.selectedBrand?.id !== "all"
						? record?.brandLocation?.merchantRefId && Number(record?.brandLocation?.merchantRefId) !== -1
							? `POS ID: ${record?.brandLocation?.merchantRefId}`
							: ""
						: ""}
				</div>
			</div>
		)
	},
	{
		name: "Assoc. Items",
		field: "associated_items",
		render: (record, i, rest) => (
			<div className="table-cell associated_items" key={i}>
				{!rest.isMultibrandEnabled || rest?.selectedBrand?.id !== "all" ? record.ilaCount || 0 : "--"}
			</div>
		)
	},
	{
		name: "Menu Status",
		field: "menu_status",
		render: (record, i, rest) => {
			const { req_to_go_live, publish, verify, verification } =
				rest.menuStatus?.status?.[
					rest?.isMultibrandEnabled && rest.selectedBrand?.id ? record?.brandLocation?.id : record?.id
				] || {};
			let created = [req_to_go_live?.created, publish?.created, verify?.created].filter(
				(timestamp) => timestamp !== undefined
			);
			const timestamp = created.length > 0 ? moment.max(created.map((timestamp) => moment(timestamp))) : null;
			return (
				<div className="table-cell menu_status" key={i}>
					<div className="verification-status">
						{rest.menuStatus.loading ? (
							<div>
								<div className="shimmer H(16px) W(100px) Mb(10px)"></div>
								<div className="shimmer H(16px) W(100px) Mb(10px)"></div>
							</div>
						) : verification ? (
							<div>
								Verification:
								<span className={verification !== "N/A" ? verification?.toLowerCase() : ""}>
									{" "}
									{verification}
								</span>
								{timestamp && verification !== "N/A" && (
									<span aria-label={"On " + formatDate(timestamp)} data-balloon-pos={"down-right"}>
										<img
											className="info"
											src="/assets/icons/info.png"
											onClick={(e) => e.stopPropagation()}
										/>
									</span>
								)}
							</div>
						) : (
							"--"
						)}
					</div>
					<div className="publish-status">
						{rest.menuStatus.loading ? null : publish ? (
							<React.Fragment>
								<div>
									Publish:{" "}
									<span className={publish?.finalStatus?.toLowerCase() || ""}>
										{ACTION_STATE_TO_TEXT[publish?.finalStatus]}
									</span>
								</div>
								<div className="time-stamp">
									{moment(publish?.created).format("DD MMM, YYYY - hh:mm A")}
								</div>
							</React.Fragment>
						) : null}
					</div>
					{rest.menuStatus.loading ? null : verification === "Failed" ? (
						<div
							className="link-text"
							onClick={() =>
								rest.handleCatalogueVerification("error", {
									id: record.id,
									brand: rest?.selectedBrand || null,
									name: record.name,
									publish: publish?.created,
									platform: rest.selectedPlatform
								})
							}
						>
							Fix Errors
						</div>
					) : null}
				</div>
			);
		}
	},
	{
		name: "Location Status",
		field: "location_status",
		render: (record, i, rest) => {
			const status = record.locationPlatforms?.find(
				(plf) => parseInt(plf.id) === parseInt(rest.menuStatus?.platform?.id)
			)?.state;
			const { enable, disable, disconnect } =
				rest.menuStatus?.status?.[
					rest?.isMultibrandEnabled && rest.selectedBrand?.id ? record?.brandLocation?.id : record?.id
				] || {};
			const { action, finalStatus, created } = enable || disable || disconnect || {};
			return (
				<div className="table-cell location_status" key={i}>
					<div className="current-status">
						{rest.menuStatus.loading ? (
							<div>
								<div className="shimmer H(16px) W(100px) Mb(10px)"></div>
								<div className="shimmer H(16px) W(100px) Mb(10px)"></div>
							</div>
						) : PLATFORM_STATE[status] ? (
							<div>
								<span className={"status " + PLATFORM_STATE[status]}>
									{PLATFORM_STATE[status]?.split("-")?.join(" ")}
								</span>
							</div>
						) : (
							"--"
						)}
					</div>
					<div className="last-action">
						{rest.menuStatus.loading ? null : enable || disable || disconnect ? (
							<div>
								Last Action:
								<span className={finalStatus?.toLowerCase() || ""}>
									{" "}
									{ACTION_STATE_TO_TEXT[finalStatus]}
								</span>
								<span
									aria-label={
										"Action: " +
										capitaliseText(action.toLowerCase()) +
										"\nOn " +
										formatDate(created)
									}
									data-balloon-pos={"down-right"}
								>
									<img className="info" src="/assets/icons/info.png" />
								</span>
							</div>
						) : null}
					</div>
				</div>
			);
		}
	},
	{
		name: "Assoc. platform(s)",
		field: "associated_platforms",
		render: (record, i, rest) => {
			const { verification } =
				rest.menuStatus?.status?.[
					rest?.isMultibrandEnabled && rest.selectedBrand?.id ? record?.brandLocation?.id : record?.id
				] || {};
			return (
				<div className="table-cell associated_platforms" key={i}>
					<div className="platforms-list">
						{record?.locationPlatforms?.length && rest.selectedBrand?.id !== "all"
							? record.locationPlatforms.map((plf, j) => (
									<Popover
										key={j}
										showOnClick={true}
										renderPopover={() =>
											rest.renderPopover(plf, record, rest.handleCatalogueVerification)
										}
										showCustomTooltip={true}
										tooltipInfo={`Click to see logs for ${capitaliseText(
											PLATFORM_NAME_MAP[plf?.platformName?.toLowerCase()] || plf.platformName
										)}`}
									>
										<div className="platform">
											<img
												src={
													plf?.logo ||
													CATALOGUE_PLATFORMS_LOGO[
														(
															PLATFORM_NAME_MAP[plf?.platformName?.toLowerCase()] ||
															plf.platformName
														).toLowerCase()
													] ||
													"/assets/icons/icons8-globe-40.png"
												}
												alt=""
											/>
											{rest.selectedBrand?.id !== "all" && (
												<div
													className={
														"state " +
														(PLATFORM_STATE[plf.state] ||
															plf.state?.toLowerCase()?.split(" ")?.join("-"))
													}
												></div>
											)}
											{parseInt(rest.menuStatus?.platform?.id) === parseInt(plf.id) ? (
												verification === "Failed" ? (
													<div className="verification-status">
														<img src="/assets/icons/alert.svg" alt="" />
													</div>
												) : null
											) : null}
										</div>
									</Popover>
							  ))
							: "--"}
					</div>
				</div>
			);
		}
	},
	{
		name: "Assoc. brands",
		field: "associated_brands",
		render: (record, i, rest) => (
			<div className="table-cell associated_brands" key={i}>
				<div className="brands-list">
					{record?.associatedBrandsData?.length
						? record.associatedBrandsData.map((brand, j) => (
								<div key={j} className={"brand-initials " + brand.color} title={brand.name}>
									{brand.image ? (
										<img src={brand.image} alt="" />
									) : (
										extractInitials(brand.name?.split(" "))
									)}
								</div>
						  ))
						: "--"}
				</div>
			</div>
		)
	}
];

@connect((store) => ({
	locationsList: store.locationsList,
	locationsListState: store.locationsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	user: store.login.loginDetail,
	isPrismEnabled: store.login.loggedInbizDetail.modulesEnabled.includes("PRISM"),
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	atlasOnboardingflowData: store.atlasOnboardingState.flowData
}))
export class LocationsList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		let { biz } = this.props;
		this.state = {
			isOpen: false,
			showFilters: false,
			toCheck: false,
			isCheckedAll: false,
			locations: {},
			locationsPlatformMapping: [],
			bizPlatforms: [],
			bizPlatformStatus: {},
			selectedPlatform: lS.get("platform")?.[biz.id] || { id: "allPlatforms", platformName: "All Platforms" },
			selectedBrand: this.props.isMultibrandEnabled
				? lS.get("brand") && lS.get("brand")?.id !== "all"
					? lS.get("brand")
					: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
				: null,
			tableColumnsSelected: {
				tableColumnsSelected: {
					title: true,
					city: true,
					location_id: true,
					associated_items: true,
					associated_platforms: lS.get("platform")?.[biz.id]?.id
						? lS.get("platform")?.[biz.id]?.id === "allPlatforms"
						: true,
					menu_status: true,
					location_status: true
				}
			},
			isLoading: true,
			associationSidebar: false,
			publishSidebar: false,
			toggleSidebar: false,
			toggleAction: { label: "Set Online", value: "enable" },
			verificationSidebar: false,
			verificationDetails: {},
			menuStatus: {
				platform: lS.get("platform")?.[biz.id] || { id: "allPlatforms", platformName: "All Platforms" },
				loading: true
			},
			isAccordionExpanded: false,
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			filtersUsed: {},
			cloningLocation: false,
			modifiersUpdate: false,
			contextMenuId: undefined,
			contextMenuData: {},
			contextMenu: { id: null },
			archiveRestore: false,
			goLiveSidebar: false,
			verifyCatalogueSidebar: false,
			isModalOpen: false,
			modalContent: {}
		};
	}

	async componentDidMount() {
		document.addEventListener("click", () => {
			this.setState({ contextMenuId: undefined });
		});
		// set tracking related info
		const eventName = "locations_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.locationsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		}

		// fetch Biz Platforms list
		const bizPlatform = await this.fetchBizPlatformsList();

		// fetch Locations list
		const locationsList = await fetchLocationsList(bizPlatform?.id, this.state?.selectedBrand?.id || null);

		if (bizPlatform && bizPlatform?.id !== "allPlatforms") {
			await this.fetchLpaLogs(bizPlatform, locationsList);
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });

		// the below code preloads the stores dropdown
		const { configItems } = this.props;
		if (!configItems.items.items.length) {
			fetchItemsDebounced("");
		}
		if (this.props.isMultibrandEnabled) {
			fetchBrands("", true);
			store.dispatch({
				type: ActionTypes.UPDATE_SELECTED_BRAND,
				payload: this.state.selectedBrand
			});
		}
	}

	componentWillUnmount = () => {
		let currentFilters = {
			...this.props.locationsListState.currentFilters
		};
		if (currentFilters.platforms) {
			delete currentFilters.platforms;
			this.updatelocationsListState({
				currentFilters,
				appliedFilters: currentFilters,
				offset: 0
			});
		}
		document.removeEventListener("click", () => {
			this.setState({ contextMenuId: undefined });
		});
	};

	fetchBizPlatformsList = async () => {
		try {
			store.dispatch(toggleGlobalLoader(true));
			const variables = {
				limit: 30,
				offset: 0,
				filters: [{ field: "is_enabled", value: "true" }]
			};
			const resp = await client.query({
				query: GET_INVENTORY_AND_ONLINE_ORDERING_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			let bizPlatforms = [];
			if (resp?.data?.onlineOrderingBizPlatforms?.objects?.length) {
				bizPlatforms = bizPlatforms.concat(resp.data.onlineOrderingBizPlatforms.objects);
			}
			if (resp?.data?.inventoryBizPlatforms?.objects?.length) {
				bizPlatforms = bizPlatforms.concat(resp.data.inventoryBizPlatforms.objects);
			}
			// insert an object for all platform manually
			bizPlatforms.unshift({
				id: "allPlatforms",
				platformName: "All Platforms"
			});
			if (
				lS.get("platform")?.[this.props.biz.id]?.id !== "allPlatforms" ||
				(this.props.locationsList.selectedPlatform &&
					this.props.locationsList.selectedPlatform !== "allPlatforms")
			) {
				const platform = bizPlatforms.find((plf) => plf.id == lS.get("platform")?.[this.props.biz.id]?.id);
				const selectedPlatform = platform?.id ? platform : bizPlatforms[0];
				this.setState({
					bizPlatforms: bizPlatforms,
					selectedPlatform: selectedPlatform,
					menuStatus: {
						...this.state.menuStatus,
						platform: selectedPlatform,
						loading: true
					},
					tableColumnsSelected: {
						tableColumnsSelected: {
							...this.state.tableColumnsSelected.tableColumnsSelected,
							associated_platforms: selectedPlatform?.id === "allPlatforms"
						}
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_SELECTED_PLATFORM,
					payload: selectedPlatform?.id
				});
				store.dispatch(toggleGlobalLoader(false));
				return selectedPlatform;
			} else {
				this.setState({
					bizPlatforms: bizPlatforms,
					selectedPlatform: bizPlatforms[0]
				});
				store.dispatch({
					type: ActionTypes.UPDATE_SELECTED_PLATFORM,
					payload: bizPlatforms[0].id
				});
				store.dispatch(toggleGlobalLoader(false));
				return bizPlatforms[0];
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	};

	fetchBizPlatformStatus = async (platformId = null) => {
		if (platformId && platformId !== "allPlatforms") {
			try {
				this.setState({
					isLoading: true
				});
				store.dispatch(toggleGlobalLoader(true));
				const variables = {
					id: parseInt(platformId)
				};
				// sidebar filters
				let flag = true;
				const filtersObject = parseFilters(this.props.locationsListState.appliedFilters);
				filtersObject.map((f) => {
					if (f.field === "is_active") {
						f.value = this.state.archivedFilter ? false : true;
						flag = false;
					}
					return f;
				});
				if (flag) {
					variables.filters = [
						...filtersObject,
						{ field: "is_active", value: this.state.archivedFilter ? false : true }
					];
				} else {
					variables.filters = filtersObject;
				}
				const resp = await client.query({
					query: GET_BIZ_PLATFORM_STATUS,
					variables,
					fetchPolicy: "no-cache"
				});
				this.setState({
					isLoading: false,
					bizPlatformStatus: resp.data.bizPlatform
				});
			} catch (error) {
				console.log(error);
				this.setState({ isLoading: false });
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
			}
			store.dispatch(toggleGlobalLoader(false));
		} else {
			this.setState({ isLoading: false });
		}
	};

	fetchNewData = async () => {
		const locationsList = await fetchLocationsList(this.state.selectedPlatform.id, this.state.selectedBrand?.id);
		this.fetchLpaLogs(this.state.selectedPlatform, locationsList);
	};

	fetchLpaLogs = async (platform, locations = []) => {
		if (platform && platform?.id !== "allPlatforms" && locations.length > 0) {
			store.dispatch(toggleGlobalLoader(true));
			this.setState({
				menuStatus: {
					...this.state.menuStatus,
					loading: true
				}
			});
			try {
				const variables = {
					platform: parseInt(platform.id),
					locations,
					action: "all"
				};
				if (this.props.isMultibrandEnabled && this.state.selectedBrand !== null) {
					variables.brand = this.state.selectedBrand?.id;
				}
				const resp = await client.query({
					query: GET_LPA_LOGS,
					variables,
					fetchPolicy: "no-cache"
				});
				const menuStatus = {
					platform: platform,
					loading: false,
					status: {}
				};
				resp.data.lpaLogs.forEach((log) => {
					menuStatus.status[log.location] = {
						...(menuStatus.status[log.location] ?? {}),
						verification: log?.verificationStatus
							? PRISM_VERIFICATION_STATE[log?.verificationStatus?.toLowerCase()] ||
							  log?.verificationStatus
							: "N/A",
						[log.action?.toLowerCase()]: {
							...log
						}
					};
				});
				this.setState({
					menuStatus: menuStatus
				});
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 5000,
						error: true,
						errObject: error
					}
				});
				this.setState({
					menuStatus: {
						...this.state.menuStatus,
						loading: false
					}
				});
			}
			store.dispatch(toggleGlobalLoader(false));
		} else {
			this.setState({
				menuStatus: {}
			});
		}
	};

	handlePlatform = async (platform) => {
		this.setState({
			selectedPlatform: platform,
			isLoading: true,
			locations: {},
			locationsPlatformMapping: [],
			isCheckedAll: false
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_PLATFORM,
			payload: platform.id
		});
		// update platform in local storage
		lS.set("platform", {
			...(lS.get("platform") ?? {}),
			[this.props.biz.id]: {
				id: platform.id,
				platformName: platform?.platform?.name || platform?.platformName
			}
		});
		let { currentFilters, appliedFilters } = this.props.locationsListState;
		if (["default", "meraki"].includes(platform?.id)) {
			delete currentFilters["state"];
			delete appliedFilters["state"];
		} else {
			delete currentFilters["meraki_state"];
			delete appliedFilters["meraki_state"];
		}
		this.updatelocationsListState({
			currentFilters,
			appliedFilters,
			offset: 0
		});
		const locationsList = await fetchLocationsList(platform.id, this.state.selectedBrand?.id);
		this.setState({
			menuStatus: {
				...this.state.menuStatus,
				platform,
				loading: true
			},
			tableColumnsSelected: {
				tableColumnsSelected: {
					...this.state.tableColumnsSelected.tableColumnsSelected,
					associated_platforms:
						(this.state.tableColumnsSelected?.tableColumnsSelected?.associated_platforms &&
							this.state.menuStatus?.platform &&
							this.state.menuStatus?.platform?.id !== "allPlatforms") ||
						platform.id === "allPlatforms"
				}
			}
		});
		await this.fetchLpaLogs(platform, locationsList);
	};

	handleBrand = async (brand) => {
		this.setState({
			selectedBrand: brand,
			isLoading: true,
			locations: {},
			locationsPlatformMapping: [],
			isCheckedAll: false
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
		this.updatelocationsListState({
			offset: 0
		});
		const locationsList = await fetchLocationsList(this.state.selectedPlatform.id, brand?.id);
		await this.fetchLpaLogs(this.state.selectedPlatform, locationsList);
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id,
					showCourses: true
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "locations"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	updatelocationsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.LOCATIONS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatelocationsListState({
			currentFilters: this.props.locationsListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.locationsListState.currentFilters
		};
		currentFilters[field] = value;
		if (currentFilters.platforms && currentFilters.platforms.value === "") {
			delete currentFilters.platforms;
		}
		this.updatelocationsListState({
			currentFilters
		});
		this.setState({
			filtersUsed: {
				...this.state.filtersUsed,
				[field]: true
			}
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.LOCATIONS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.LOCATIONS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.setState({
			filtersUsed: {
				...this.state.filtersUsed,
				[field]: true
			}
		});
		this.updatelocationsListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(async () => {
		await this.fetchNewData();
	}, 1000);

	handleActiveFilter = async (field, value) => {
		this.updatelocationsListState({
			currentFilters: {
				...this.props.locationsListState.appliedFilters,
				[field]: value.value
			},
			appliedFilters: {
				...this.props.locationsListState.appliedFilters,
				[field]: value.value
			},
			offset: 0
		});
		// apply filters
		await this.fetchNewData();
		this.setState({
			archivedFilter: value.value,
			locations: {},
			locationsPlatformMapping: [],
			isCheckedAll: false
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false,
			isLoading: true
		});
		this.updatelocationsListState({
			appliedFilters: {
				...this.props.locationsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "locations_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await this.fetchNewData();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().locationsListState;
		if (appliedFilters["is_active"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false,
				isLoading: true
			},
			async () => {
				this.updatelocationsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				this.setState({ archivedFilter: false });
				await this.fetchNewData();
			}
		);
	};

	handleCheck = (e, id, toSelect) => {
		e.stopPropagation();
		let locationUpdates = this.state.locationsPlatformMapping.filter((loc) => this.state.locations[loc.id]);
		if (toSelect) {
			locationUpdates.push(this.props.locationsList.data.objects.find((loc) => loc.id === id));
		} else {
			locationUpdates = locationUpdates.filter((loc) => loc.id !== id);
		}
		this.setState({
			locations: {
				...this.state.locations,
				[id]: toSelect
			},
			locationsPlatformMapping: locationUpdates
		});
		if (!toSelect) {
			this.setState({
				isCheckedAll: false
			});
		}
	};

	handleCheckAll = (e, toCheckAll) => {
		e.stopPropagation();
		if (toCheckAll) {
			let updates = {};
			this.props.locationsList.data.objects.forEach((loc) => {
				updates[loc.id] = toCheckAll;
			});
			let locationUpdates = this.props.locationsList.data.objects.filter((loc) => !this.state.locations[loc.id]);
			this.setState({
				isCheckedAll: toCheckAll,
				locations: {
					...this.state.locations,
					...updates
				},
				locationsPlatformMapping: [
					...this.state.locationsPlatformMapping.filter((loc) => this.state.locations[loc.id]),
					...locationUpdates
				]
			});
		} else {
			let updates = {};
			this.props.locationsList.data.objects.forEach((loc) => {
				updates[loc.id] = toCheckAll;
			});
			let locationUpdates = this.state.locationsPlatformMapping.filter((loc) => !(loc.id in updates));
			this.setState({
				isCheckedAll: toCheckAll,
				locations: {
					...this.state.locations,
					...updates
				},
				locationsPlatformMapping: [...locationUpdates]
			});
		}
	};

	handleCatalogueVerification = (status = "error", location = {}) => {
		let payload = {
			currentFilters: {
				error_status: status
			},
			appliedFilters: {
				error_status: status
			},
			offset: 0
		};
		if (!this.props.isPrismEnabled) {
			payload.currentFilters.channel = {
				valueForDisplay: this.state.selectedPlatform.platformName,
				value: this.state.selectedPlatform.platformName.toLowerCase()
			};
			payload.appliedFilters.channel = {
				valueForDisplay: this.state.selectedPlatform.platformName,
				value: this.state.selectedPlatform.platformName.toLowerCase()
			};
		}
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE,
			payload
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				selectedTab: status,
				location: location,
				platform: this.state.selectedPlatform.platformName
			}
		});
		setTimeout(() => {
			history.push("/verification");
		}, 100);
	};

	openAssociationSidebar = (modifier = false) => {
		this.setState({
			associationSidebar: true,
			modifiersUpdate: modifier
		});
		// track open update items event
		const eventMeta = {
			num_locations: Object.values(this.state.locations).filter((val) => val === true).length,
			action_taken: "Update Items",
			filters: this.state.filtersUsed
		};
		trackEvent("locations_selected", eventMeta);
	};

	openGoLiveSidebar = () => {
		this.setState({
			goLiveSidebar: true
		});
	};

	closeGoLiveSidebar = (success = false) => {
		this.setState({
			goLiveSidebar: false
		});
		if (success) {
			this.setState({
				locations: {}
			});
		}
	};

	openPublishSidebar = () => {
		// check if publish action is disabled for the biz
		const { biz } = this.props;
		if (biz?.scheduledActionsAndNotifications?.actions?.length > 0) {
			const action = biz?.scheduledActionsAndNotifications?.actions?.find(
				(action) => action.action === "deny_menu_publish"
			);
			if (action && action?.applicable) {
				this.setState({
					isModalOpen: true,
					modalContent: {
						title: "Publish Catalogue",
						message: action.message
					}
				});
				return;
			}
		}
		// open publish action side drawer
		this.setState({
			publishSidebar: true
		});
		// track open publish catalogue event
		const eventMeta = {
			num_locations: Object.values(this.state.locations).filter((val) => val === true).length,
			action_taken: "Publish Catalogue",
			filters: this.state.filtersUsed
		};
		trackEvent("locations_selected", eventMeta);
	};

	openToggleSidebar = (action) => {
		this.setState({
			toggleSidebar: true,
			toggleAction: action
		});
		// track open store toggle event
		const eventMeta = {
			num_locations: Object.values(this.state.locations).filter((val) => val === true).length,
			action_taken: "Store Toggle",
			filters: this.state.filtersUsed
		};
		trackEvent("locations_selected", eventMeta);
	};

	closeSidebar = async (refresh = false) => {
		if (refresh) {
			this.setState({
				associationSidebar: false,
				publishSidebar: false,
				toggleSidebar: false,
				isCheckedAll: false,
				locations: {},
				locationsPlatformMapping: [],
				filtersUsed: {},
				modifiersUpdate: false
			});
			await this.fetchNewData();
		} else {
			this.setState({
				associationSidebar: false,
				publishSidebar: false,
				toggleSidebar: false
			});
		}
	};

	openVerifyCatalogueSidebar = () => {
		this.setState({
			verifyCatalogueSidebar: true
		});
	};

	closeVerifyCatalogueSidebar = (success = false) => {
		this.setState({
			verifyCatalogueSidebar: false
		});
		if (success) {
			this.setState({
				locations: {}
			});
		}
	};

	closeVerificationSidebar = () => {
		this.setState({
			verificationSidebar: false,
			verificationDetails: {}
		});
	};

	clearSelection = () => {
		this.setState({
			isCheckedAll: false,
			locations: {},
			locationsPlatformMapping: [],
			filtersUsed: {}
		});
	};

	handlePagination = async (page) => {
		// set new offset
		const { limit } = this.props.locationsListState;
		const offset = (page - 1) * limit;
		this.updatelocationsListState({
			offset
		});
		// fetch new Locations list & ilpalogs
		await this.fetchNewData();
		this.setState({
			isCheckedAll: false
		});
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.locationsListState;
		if (size && size?.value !== limit) {
			this.updatelocationsListState({
				[field]: size.value
			});
			// fetch new Locations list
			await this.fetchNewData();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = async (field) => {
		const sort = {
			field
		};
		this.updatelocationsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.LOCATIONS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		await this.fetchNewData();
	};

	handleOpenUrl = (url) => {
		if (url) {
			window
				.open(
					["http://", "https://"].some((protocol) => url.includes(protocol)) ? url : `http://${url}`,
					"_blank"
				)
				.focus();
		}
	};

	handleColumnSelection = (isSelected, field, optKey) => {
		this.setState({
			tableColumnsSelected: {
				tableColumnsSelected: {
					...this.state.tableColumnsSelected.tableColumnsSelected,
					[optKey.value]: isSelected
				}
			}
		});
	};

	setContextMenuId = (id = undefined) => {
		this.setState({ contextMenuId: id });
	};

	setContextMenuData = (data = {}) => {
		this.setState({
			contextMenuData: {
				...data,
				bizLocationNickname: data?.name,
				merchantBizLocationId: data.merchantRefId,
				isActive: !data.isActive
			}
		});
	};

	handleArchiveRestore = async (success) => {
		this.setState({ archiveRestore: false, contextMenuData: {} });
		if (success) {
			await this.fetchNewData();
			this.setState({ locations: {}, contextMenuData: {}, locationsPlatformMapping: [], isCheckedAll: false });
		}
	};

	archiveTargetLocation = () => {
		this.setState({ archiveRestore: true, contextMenu: { id: null } });
	};

	handleCatalogueVerification = (status, location) => {
		let payload = {
			currentFilters: {
				error_status: status
			},
			appliedFilters: {
				error_status: status
			},
			offset: 0
		};
		if (!this.props.isPrismEnabled) {
			payload.currentFilters.channel = {
				valueForDisplay: location.platform.platformName,
				value: location.platform.platformName.toLowerCase()
			};
			payload.appliedFilters.channel = {
				valueForDisplay: location.platform.platformName,
				value: location.platform.platformName.toLowerCase()
			};
		}
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE,
			payload
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				selectedTab: status,
				location: location,
				platform: location.platform
			}
		});
		const verificationDetails = {
			selectedTab: status,
			location: location,
			platform: location.platform
		};
		this.setState({
			verificationSidebar: true,
			verificationDetails
		});
	};

	renderPopover = (platform, location, handleCatalogueVerification) => {
		return (
			<LpaLogs
				platform={platform}
				brand={this.state.selectedBrand}
				location={location}
				handleCatalogueVerification={handleCatalogueVerification}
			/>
		);
	};

	handleExpand = () => {
		this.setState({
			isAccordionExpanded: !this.state.isAccordionExpanded
		});
	};

	renderMenuItems = (record) => {
		if (this.state.archivedFilter) {
			return (
				<React.Fragment>
					<div className="action-item" onClick={this.archiveTargetLocation} style={{ color: "#2ecc71" }}>
						Restore
					</div>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				<div
					className={"action-item" + (this.state.selectedBrand?.id === "all" ? " disabled" : "")}
					onClick={() => this.openGoLiveSidebar(false, record)}
				>
					Request To Go Live
				</div>
				<Accordion
					title="Toggle Status"
					isExpanded={this.state.isAccordionExpanded}
					handleExpand={this.handleExpand}
					showDropdownArrow={true}
				>
					<div
						className="action"
						onClick={() => this.openToggleSidebar({ label: "Set Online", value: "enable" })}
					>
						Set Online
					</div>
					<div
						className="action"
						onClick={() => this.openToggleSidebar({ label: "Set Offline", value: "disable" })}
					>
						Set Offline
					</div>
				</Accordion>
				<div className="action-item" onClick={() => this.openAssociationSidebar(false, record)}>
					Update Items
				</div>
				<div className="action-item" onClick={() => this.openAssociationSidebar(true, record)}>
					Update Modifiers
				</div>
				{this?.props?.isPrismEnabled && (
					<div className="action-item" onClick={() => this.openVerifyCatalogueSidebar(record)}>
						Verify
					</div>
				)}
				{!this?.props?.biz?.isMenuOverCatalogueEnabled && (
					<div className="action-item" onClick={() => this.openPublishSidebar(record)}>
						Publish
					</div>
				)}
				<div className="action-item" onClick={this.archiveTargetLocation} style={{ color: "#FF425C" }}>
					Archive
				</div>
			</React.Fragment>
		);
	};

	openContextMenu = (record = {}) => {
		this.setState({
			contextMenu: {
				id: this.state.contextMenu.id && this.state.contextMenu.id === record.id ? null : record.id,
				brand:
					this.state.contextMenu.id && this.state.contextMenu.id === record.id
						? null
						: this.state.selectedBrand,
				platform:
					this.state.contextMenu.id && this.state.contextMenu.id === record.id
						? null
						: this.state.selectedPlatform
			},
			contextMenuData: { ...record },
			locationsPlatformMapping: [
				(this.props.locationsList.data.objects ?? []).find((locDetails) => locDetails.id === record.id)
			]
		});
	};

	closeContextMenu = () => {
		this.setState({
			isAccordionExpanded: false,
			contextMenu: {
				id: null,
				brand: null,
				platform: null
			}
		});
	};

	handleModal = () => {
		this.setState({
			isModalOpen: false,
			modalContent: {}
		});
	};

	render() {
		const {
			toCheck,
			isCheckedAll,
			locations,
			bizPlatforms,
			bizPlatformStatus,
			selectedPlatform,
			selectedBrand,
			isLoading,
			associationSidebar,
			publishSidebar,
			toggleSidebar,
			toggleAction,
			verificationSidebar,
			verificationDetails,
			menuStatus,
			archivedFilter,
			modifiersUpdate,
			contextMenuData,
			contextMenu,
			archiveRestore,
			goLiveSidebar,
			locationsPlatformMapping,
			tableColumnsSelected,
			verifyCatalogueSidebar,
			isModalOpen,
			modalContent
		} = this.state;

		const {
			locationsList,
			locationsListState,
			configItems,
			access = {},
			biz,
			isPrismEnabled = false,
			isMultibrandEnabled = false
		} = this.props;
		const { limit, offset, currentFilters, appliedFilters, sortedField } = locationsListState;
		let filterCount = 0;
		for (let f in appliedFilters) {
			if (f !== "name" && f !== "is_active") {
				if (f === "related_item_associations__item" && appliedFilters[f]?.id) {
					filterCount++;
				} else if (appliedFilters[f]?.value && appliedFilters[f]?.value !== "") {
					filterCount++;
				}
			}
		}

		let switchActions = false;
		for (let id in locations) {
			if (locations[id] === true) {
				switchActions = true;
				break;
			}
		}

		const publishFailures = {
			hide: false,
			field: "platforms",
			valueForDisplay: "Last Publish Failures",
			type: "MULTIPLE",
			values: bizPlatforms,
			labelKey: "platformName",
			valueKey: "id"
		};

		let index = undefined;
		const filterOptions = locationsList.data.filters
			.filter(
				(f) =>
					f.field !== "name" &&
					f.field !== "is_active" &&
					(["default", "meraki"].includes(selectedPlatform?.id)
						? f.field !== "state"
						: f.field !== "meraki_state")
			)
			.map((f, i) => {
				if (f.field === "is_active") {
					index = i;
					f.hide = false;
				} else if (f.field === "related_item_associations__item") {
					f = {
						...f,
						type: "SINGLE_STRICT",
						isAsync: true,
						isLoading: this.props.configItems.items.isLoading,
						values: this.props.configItems.items.items,
						handleAsyncSearch: fetchItemsDebounced,
						labelKey: "title",
						valueKey: "id"
					};
				}
				return f;
			});

		const placeholderContent = {
			placeholderText: "No locations added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-locations-main.svg",
			placeholderSubtext: "View and manage all your restaurant locations",
			size: "medium",
			placeholderButtonContent: (
				<span>
					{" "}
					<CreateIcon /> Add a new Location
				</span>
			),
			placeholderButtonClickAction: () => history.push("/locations/new")
		};
		const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;

		const getTableFields = () => {
			const excludedFields = [];

			if (!isMultibrandEnabled) {
				excludedFields.push("associated_brands");
			}

			if (isMenuOverCatalogueEnabled) {
				excludedFields.push("associated_items");
			}

			return TABLE_FIELDS.filter((column) => !excludedFields.includes(column.value));
		};

		return (
			<React.Fragment>
				<div
					className="locations-section section-container-common"
					ref={(ref) => (this.tableRef = ref)}
					style={{ position: "relative" }}
				>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filterOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						searchKeywords={locationsList.data.searchKeywords}
						searchFieldSelected={locationsList.data.searchFieldSelected}
						searchFieldValue={locationsList.data.searchFieldValue}
						handleSearchField={this.handleSearchField}
						setFilter={this.setSearchFilter}
						applySearchFilter={this.applySearchFilter}
						switchActions={switchActions}
						handlePiperAcademy={this.handlePiperAcademy}
						isAdmin={access.isAdmin}
						isHubManagement={access.isHubManagement}
						platforms={bizPlatforms}
						bizId={biz.id}
						bizCountry={biz.country}
						isInternalUser={this.props.user?.email?.includes("@urbanpiper.com")}
						onboardingFlowEnabled={
							this.props.atlasOnboardingflowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW
						}
					/>
					<FiltersAndSearch
						brands={configItems.brands}
						bizPlatforms={bizPlatforms}
						bizPlatformStatus={bizPlatformStatus}
						selectedPlatform={selectedPlatform}
						selectedBrand={selectedBrand}
						isLoading={isLoading}
						handlePlatform={this.handlePlatform}
						handleBrand={this.handleBrand}
						handleActiveFilter={this.handleActiveFilter}
						isActive={appliedFilters?.["is_active"] !== undefined ? appliedFilters?.["is_active"] : false}
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						dimensions={configItems.dimensions}
						searchKeywords={locationsList.data.searchKeywords}
						searchFieldSelected={locationsList.data.searchFieldSelected}
						searchFieldValue={locationsList.data.searchFieldValue}
						applySearchFilter={this.applySearchFilter}
						handleSearchField={this.handleSearchField}
						setFilter={this.setSearchFilter}
						isMultibrandEnabled={isMultibrandEnabled}
						tableFields={getTableFields().filter((column) =>
							menuStatus?.platform && menuStatus?.platform?.id !== "allPlatforms"
								? column
								: !["menu_status", "location_status"].includes(column.value)
						)}
						handleColumnSelection={this.handleColumnSelection}
						selectedColumns={tableColumnsSelected}
					/>
					<Transition
						native
						items={switchActions}
						from={{ opacity: 0, height: 0 }}
						enter={{ opacity: 1, height: "auto" }}
						leave={{ opacity: 0, height: 0 }}
						config={config.stiff}
					>
						{(isOpen) =>
							isOpen &&
							((props) => (
								<animated.div style={props}>
									<Actions
										handleGoLive={() => this.openGoLiveSidebar(false)}
										handleToggleStatus={this.openToggleSidebar}
										handleUpdateItems={() => this.openAssociationSidebar(false)}
										handleUpdateModifiers={() => this.openAssociationSidebar(true)}
										handlePublish={this.openPublishSidebar}
										handleArchiveRestore={this.archiveTargetLocation}
										openVerifyCatalogueSidebar={this.openVerifyCatalogueSidebar}
										archived={archivedFilter}
										clearSelection={this.clearSelection}
										selectedBrand={selectedBrand}
										count={locationsPlatformMapping.length || 0}
										isPrismEnabled={isPrismEnabled}
										isMenuOverCatalogueEnabled={isMenuOverCatalogueEnabled}
									/>
								</animated.div>
							))
						}
					</Transition>
					<CustomTable
						loading={locationsList.loading}
						data={locationsList.data.objects || []}
						columns={(isMenuOverCatalogueEnabled
							? columns.filter((col) => col.field !== "associated_items")
							: columns
						).filter(
							(column) =>
								tableColumnsSelected.tableColumnsSelected[column.field] &&
								(menuStatus?.platform && menuStatus?.platform?.id !== "allPlatforms"
									? column
									: !["menu_status", "location_status"].includes(column.field))
						)}
						sortList={this.sortList}
						sortedField={sortedField}
						checkbox={access.isAdmin || access.isHubManagement}
						toCheck={toCheck}
						isCheckedAll={isCheckedAll}
						locations={locations}
						bizPlatforms={bizPlatforms}
						handleCheck={this.handleCheck}
						handleCheckAll={this.handleCheckAll}
						handleOpenUrl={this.handleOpenUrl}
						archived={archivedFilter ? "archived" : ""}
						classes="locations-list-table-container"
						content="Locations"
						rightClickEnabled={true}
						setContextMenuId={this.setContextMenuId}
						setContextMenuData={this.setContextMenuData}
						showContextMenu={access.isAdmin || access.isHubManagement}
						disableContextMenu={switchActions}
						renderMenuItems={this.renderMenuItems}
						openContextMenu={this.openContextMenu}
						closeContextMenu={this.closeContextMenu}
						renderPopover={this.renderPopover}
						isMultibrandEnabled={isMultibrandEnabled}
						selectedBrand={selectedBrand}
						selectedPlatform={selectedPlatform}
						contextMenuId={contextMenu.id}
						isPrismEnabled={isPrismEnabled}
						handleCatalogueVerification={this.handleCatalogueVerification}
						menuStatus={menuStatus}
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={locationsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
					<LocationsEntityAssociation
						isOpen={associationSidebar}
						close={this.closeSidebar}
						locations={contextMenuData.id && !switchActions ? { [contextMenuData.id]: true } : locations}
						currencySymbol={this.props.biz.currencySymbol}
						modifiersUpdate={modifiersUpdate}
						selectedBrand={selectedBrand}
					/>
					<GoLive
						isOpen={goLiveSidebar}
						close={this.closeGoLiveSidebar}
						locations={locationsPlatformMapping}
						selectedBrand={selectedBrand}
					/>
					<PublishCatalogue
						isOpen={publishSidebar}
						close={this.closeSidebar}
						locations={locationsPlatformMapping}
						selectedBrand={selectedBrand}
					/>
					<StoreToggle
						isOpen={toggleSidebar}
						close={this.closeSidebar}
						locations={locationsPlatformMapping}
						selectedBrand={selectedBrand}
						action={toggleAction.value}
					/>
					<VerifyCatalogue
						isOpen={verifyCatalogueSidebar}
						close={this.closeVerifyCatalogueSidebar}
						locations={locationsPlatformMapping}
						selectedBrand={selectedBrand}
					/>
					<CatalogueVerification
						isOpen={verificationSidebar}
						close={this.closeVerificationSidebar}
						verificationDetails={verificationDetails}
					/>
					<ArchiveRestore
						isOpen={archiveRestore}
						close={this.handleArchiveRestore}
						mode={archivedFilter ? "restore" : "archive"}
						entityType={CATALOGUE_ENTITY_TYPES[0]}
						entity={locationsPlatformMapping.length > 1 ? "locations" : "location"}
						data={locationsPlatformMapping}
						showInModal={true}
						brand={this.state.selectedBrand}
						fromListView={true}
						sourceOfTrigger={TRACKING_SOURCE.LIST_VIEW_BULK}
					/>
					<Modal
						isOpen={isModalOpen}
						close={this.handleModal}
						title={modalContent.title}
						subTitle=""
						showSubmitAction={false}
						showCancelAction={true}
						cancelTitle="Dismiss"
					>
						{modalContent.message || ""}
					</Modal>
					<NestedEntityContainer
						show={this.state.nestedEntity.show}
						type={this.state.nestedEntity.type}
						id={this.state.nestedEntity.id}
						showCourses={this.state.nestedEntity.showCourses}
						closeNestedContainer={() => this.handleNestedEntity(false)}
						nestedRef={this.nestedRef}
						isNested={false}
						isForeignSource={true}
					/>
				</div>
			</React.Fragment>
		);
	}
}

const FiltersAndSearch = ({
	bizPlatforms = [],
	brands,
	selectedPlatform = null,
	selectedBrand = null,
	isLoading = false,
	handlePlatform,
	handleBrand,
	isActive = false,
	handleActiveFilter,
	filterActive,
	flipShowFilters,
	filterCount = 0,
	searchKeywords,
	dimensions,
	searchFieldSelected,
	handleSearchField,
	searchFieldValue,
	setFilter,
	isMultibrandEnabled = false,
	tableFields,
	handleColumnSelection,
	selectedColumns
}) => {
	const handlePlatformsLabelOption = (plf) => {
		return (
			<React.Fragment>
				<div className="logo">
					<img
						src={
							plf?.logo ||
							CATALOGUE_PLATFORMS_LOGO[plf.platformName.toLowerCase()] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
				</div>
				<div title={plf.platformName}>
					{plf.platformName && plf.platformName.length > 25
						? plf.platformName.slice(0, 25) + "..."
						: plf.platformName}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="locations-filters-search">
			<div className="filters">
				{isMultibrandEnabled && (
					<SelectFilterCustom
						options={brands.items}
						isLoading={brands.isLoading}
						field="brands"
						currValue={selectedBrand}
						setFilter={(f, value) => handleBrand(value)}
						labelKey={"name"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
					/>
				)}
				{bizPlatforms?.length > 1 && (
					<SelectFilterCustom
						options={bizPlatforms}
						// isLoading={isLoading}
						field="platforms"
						currValue={selectedPlatform || null}
						setFilter={(f, value) => handlePlatform(value)}
						labelKey={"platformName"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						renderLabel={handlePlatformsLabelOption}
						renderOptions={handlePlatformsLabelOption}
						placeholder="Select platform"
					/>
				)}
				<SelectFilter
					options={[
						{ value: false, valueForDisplay: "Active" },
						{ value: true, valueForDisplay: "Archived" }
					]}
					field="is_active"
					currValue={{
						value: isActive,
						valueForDisplay: (
							<span style={{ color: isActive ? "#FF425C" : "#000" }}>
								<span style={{ color: "#979797" }}>Status </span>
								{isActive ? "Archived" : "Active"}
							</span>
						)
					}}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={handleActiveFilter}
					isSearchable={false}
					isClearable={false}
				/>
				<div className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}>
					<div className="container" onClick={flipShowFilters}>
						<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
						<div className="filter-title">
							Filter
							{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
						</div>
					</div>
				</div>
			</div>
			<div className="search">
				{searchKeywords.length > 1
					? dimensions.width > 768 && (
							<div className="search-input-container">
								<SelectFilter
									options={searchKeywords}
									field="searchFieldSelected"
									currValue={searchFieldSelected}
									setFilter={handleSearchField}
									labelKey="valueForDisplay"
									valueKey="key"
									isSearchable={false}
									isClearable={false}
								/>
								<SearchFilter
									filterOption={{ field: "searchFieldValue" }}
									value={searchFieldValue}
									setFilter={setFilter}
									placeholder="Search"
								/>
							</div>
					  )
					: dimensions.width > 768 && (
							<SearchFilter
								filterOption={{ field: "searchFieldValue" }}
								value={searchFieldValue}
								setFilter={setFilter}
								placeholder="Search"
							/>
					  )}
				<TableColumnSelector
					options={tableFields}
					handleColumnSelection={handleColumnSelection}
					selectedColumns={selectedColumns}
				/>
			</div>
		</div>
	);
};

const Actions = ({
	handleGoLive,
	handleToggleStatus,
	handleUpdateItems,
	handleUpdateModifiers,
	handlePublish,
	clearSelection,
	handleArchiveRestore,
	selectedBrand = null,
	archived = false,
	count = 0,
	openVerifyCatalogueSidebar,
	isPrismEnabled,
	isMenuOverCatalogueEnabled = false
}) => {
	return (
		<div className="bulk-actions">
			<div className="count">
				{count} location(s) selected
				<span onClick={clearSelection}>Clear selection</span>
			</div>
			<div className="actions-container">
				{archived ? (
					<div className="action restore" onClick={handleArchiveRestore}>
						Restore
					</div>
				) : (
					<React.Fragment>
						<div
							className={"action" + (selectedBrand && selectedBrand?.id === "all" ? " disabled" : "")}
							onClick={handleGoLive}
						>
							Request to go Live
						</div>
						<SelectFilter
							field="toggleStatus"
							options={[
								{ label: "Set Online", value: "enable" },
								{ label: "Set Offline", value: "disable" }
							]}
							currValue={{ label: "Toggle Status", value: "toggle" }}
							setFilter={(f, v) => handleToggleStatus(v)}
							isClearable={false}
							isSearchable={false}
						/>
						<div className="action" onClick={handleUpdateItems}>
							Update Items
						</div>
						<div className="action" onClick={handleUpdateModifiers}>
							Update Modifiers
						</div>
						{isPrismEnabled && (
							<div className="action" onClick={openVerifyCatalogueSidebar}>
								Verify
							</div>
						)}
						{!isMenuOverCatalogueEnabled && (
							<div className="action" onClick={handlePublish}>
								Publish
							</div>
						)}
						<div className="action archive" onClick={handleArchiveRestore}>
							Archive
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
