import React from "react";

// third party
import moment from "moment";
import PubSub from "pubsub-js";
import _debounce from "lodash/debounce";
import _reduce from "lodash/reduce";
import _isEqual from "lodash/isEqual";
import _transform from "lodash/transform";
import _snakeCase from "lodash/snakeCase";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import camelCase from "camelcase";
import { createCanvas } from "canvas";
import Flatten from "canvas-flatten";
import Dither from "canvas-dither";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import StarPrntEncoder from "star-prnt-encoder";
import EscPosEncoder from "esc-pos-encoder";

// constants
import { CURRENCY_LOCALE_MAPPING, DEFAULT_SUFFIX_META, CRON_WEEK_OPTIONS } from "../client-config";
import { TRACK_EVENT, TRIGGER_SURVEY } from "../atlas-utils/tracking";

export const formatDate = (timestamp, format = "DD MMM, YYYY - hh:mm A") => {
	if (timestamp) {
		return moment(timestamp).format(format);
	}
	return "--";
};

export const capitaliseText = (val) => {
	if (val) {
		val = String(val).charAt(0).toUpperCase() + String(val).slice(1);
		return val;
	} else {
		return "";
	}
};

export const capitaliseTextStrict = (value, isWordLevelCapitalising = false) => {
	let stringifiedValue = String(value);
	const flaggedWords = {
		without: true,
		with: true
	};

	try {
		if (isWordLevelCapitalising) {
			let words = value?.split(" ")?.filter((val) => val !== "");
			words.forEach((word, i) => {
				word = word?.toLowerCase();
				if (!flaggedWords[word]) {
					words[i] = (word?.[0]?.toUpperCase() || "") + word?.slice(1);
				} else {
					words[i] = word;
				}
			});
			return words?.join(" ");
		}
		if (stringifiedValue.length > 0) {
			stringifiedValue = stringifiedValue?.toLowerCase();
			stringifiedValue = stringifiedValue?.[0]?.toUpperCase() + stringifiedValue?.slice(1);
			return stringifiedValue;
		}
		return "";
	} catch (error) {
		console.log(error);
		return "";
	}
};

export const isLoggedin = () => {
	if (lS.get("auth")) {
		return true;
	} else {
		return false;
	}
};

export const lS = {
	get: (key) => {
		const ls = localStorage.getItem("AT") && JSON.parse(localStorage.getItem("AT"));
		if (ls && ls[key]) {
			return ls[key];
		} else {
			return null;
		}
	},
	set: (key, value) => {
		const ls = localStorage.getItem("AT") && JSON.parse(localStorage.getItem("AT"));
		if (ls) {
			ls[key] = value;
			localStorage.setItem("AT", JSON.stringify(ls));
		} else {
			let data = {};
			data[key] = value;
			localStorage.setItem("AT", JSON.stringify(data));
		}
	},
	remove: (key) => {
		const ls = localStorage.getItem("AT") && JSON.parse(localStorage.getItem("AT"));
		if (ls) {
			delete ls[key];
			localStorage.setItem("AT", JSON.stringify(ls));
		}
		return true;
	}
};
window.lS = lS;

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const timeStampToDurPreset = (timestamp, preset, v2 = false) => {
	switch (preset) {
		case "hour":
			return moment(timestamp).format("h A");
		case "90day":
			return moment(timestamp).format("Do MMM");
		case "day":
			return moment(timestamp).format(`Do MMM${!v2 ? " hh:mm A" : ""}`);
		case "month":
			return moment(timestamp).format(`MMM${v2 ? " 'YY" : ""}`);
		default:
			return formatDate(timestamp);
	}
};

export const msaagesArrayToHtml = (mArray) => {
	if (!mArray || mArray.length === 0) {
		return "";
	}
	return mArray.map((i) => {
		return (i.field ? i.field + " - " : "") + i.message + "\n";
	});
};

export const jsonParser = (data, def = {}) => {
	let obj = def;
	try {
		obj = JSON.parse(data);
	} catch {
		obj = def;
	}
	return obj;
};

export const getRGBComponents = (color) => {
	const r = color.substring(1, 3);
	const g = color.substring(3, 5);
	const b = color.substring(5, 7);
	return {
		R: parseInt(r, 16),
		G: parseInt(g, 16),
		B: parseInt(b, 16)
	};
};

export const idealTextColor = (bgColor, lightColor = "#ffffff", darkColor = "#000000") => {
	var threshold = 105;
	var components = getRGBComponents(bgColor);
	var bgDelta = components.R * 0.299 + components.G * 0.587 + components.B * 0.114;
	return 255 - bgDelta < threshold ? darkColor : lightColor;
};

export const nthFyDate = (d) => {
	let date = d;
	let dateInt = parseInt(d);
	if (typeof d !== "string") {
		date = date.toString();
	}
	let lastDigit = date[date.length - 1];
	let firstDigit = date[0];

	// exception
	if (dateInt > 3 && dateInt < 21) {
		return `${d}th`;
	}
	if (firstDigit == 1 && lastDigit == 3) {
		return `${d}th`;
	}

	if (lastDigit == 1) {
		return `${d}st`;
	}

	if (lastDigit == 2) {
		return `${d}nd`;
	}

	if (lastDigit == 3) {
		return `${d}rd`;
	}
	return `${d}th`;
};

export const extractInitials = (name) => {
	if (!name) {
		return "";
	}
	let initials = "";
	if (name.length > 1) {
		initials = name[0].charAt(0) + name[1].charAt(0);
	} else {
		initials = name[0].charAt(0) + name[0].charAt(1);
	}
	return initials.toUpperCase();
};

export const isFeatureFlagEnabled = (key) => {
	if (window?.posthog?.__loaded) {
		return !!window?.posthog?.isFeatureEnabled(key);
	}
	return false;
};

const getNavigatorLanguage = () => {
	const { currency } = window.store.getState().login.loggedInbizDetail;
	return currency && CURRENCY_LOCALE_MAPPING[currency]
		? CURRENCY_LOCALE_MAPPING[currency].languageCode
		: navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: navigator.language || "en-US";
};

export const commifyNumbers = (num, languageCode, properties) => {
	// languageCode parameter is for overridinig the biz/default formatting
	languageCode = !languageCode ? getNavigatorLanguage() : languageCode;

	if (num === "--") {
		return num;
	} else if (num == 0) {
		return "0";
	} else if (num) {
		try {
			return new Intl.NumberFormat(languageCode, properties || {}).format(Number(num));
		} catch (err) {
			console.log(err);
			return num;
		}
	} else {
		return "--";
	}
};

const getSuffixMetaInfo = () => {
	const { currency } = window.store.getState().login.loggedInbizDetail;
	return currency && CURRENCY_LOCALE_MAPPING[currency]
		? CURRENCY_LOCALE_MAPPING[currency].suffixMeta
		: DEFAULT_SUFFIX_META;
};

export const getSuffixedNumber = (num, suffixMeta) => {
	if (!isNaN(num)) {
		// suffixMeta parameter is for overridinig the biz/default suffix config
		suffixMeta = !suffixMeta ? getSuffixMetaInfo() : suffixMeta;

		if (num < suffixMeta[0].base) {
			return commifyNumbers(num);
		}
		for (let i = suffixMeta.length - 1; i >= 0; i--) {
			if (num >= suffixMeta[i].base) {
				let commified = commifyNumbers(Number(num / suffixMeta[i].base).toFixed(1));
				return commified + suffixMeta[i].suffix;
			}
		}
	} else {
		return "--";
	}
};

export const printCurrency = (currencySymbol) => <span dangerouslySetInnerHTML={{ __html: currencySymbol }}></span>;

export const formatValueWithCurrency = (value, currency) => {
	if (value === undefined || currency === undefined) {
		return "";
	}
	let currencySymbol = new Intl.NumberFormat(CURRENCY_LOCALE_MAPPING[currency]?.languageCode || "en-US", {
		style: "currency",
		maximumFractionDigits: 0,
		currency
	});
	return currencySymbol.format(value);
};

export const setFloatingPrecision = (num, precision = 2) => {
	if (typeof num === "number") {
		return num.toFixed(precision);
	} else {
		const parts = num.split(".");
		if (parts.length === 1) {
			return parts[0] + "." + Array(precision).fill(0).join("");
		} else if (parts.length === 2) {
			if (parts[1].length === precision) {
				return num;
			} else if (parts[1].length < precision) {
				return (
					parts[0] +
					"." +
					parts[1] +
					Array(precision - parts[1].length)
						.fill(0)
						.join("")
				);
			} else {
				return parts[0] + "." + parts[1].slice(0, precision);
			}
		}
	}
	return num;
};

// parse cron expression and return its fields
export const parseCron = (cronExp) => {
	// split the expresionn
	let cronExpArr = cronExp.split(" ");

	// get all the field
	let min = cronExpArr[0];
	let hr = cronExpArr[1];
	let dayOfMonth = cronExpArr[2];
	let month = cronExpArr[3];
	let dayofWeek = cronExpArr[4];

	// handle every hour/minute contidion
	if (hr === "*") {
		hr = 12;
	}
	if (min === "*") {
		min = 0;
	}

	return {
		hr,
		min,
		dayOfMonth,
		month,
		dayofWeek
	};
};

// generate human readable timestamp from cron expression
export const getReadableCron = (cronExp) => {
	const cronObj = parseCron(cronExp);
	const { hr, min, dayOfMonth, month, dayofWeek } = cronObj;
	const time = moment(`${hr}:${min}`, "H:m").format("hh:mm A");
	let readableCron = "";
	if (month == "*" && dayOfMonth == "*/1") {
		readableCron = `Every day at ${time}`;
	} else if (month == "*/1" && dayofWeek == "*") {
		readableCron = `Every month on ${nthFyDate(dayOfMonth)} at ${time}`;
	} else {
		let readableDayObj = CRON_WEEK_OPTIONS.find((opt) => opt.value == dayofWeek);
		readableCron = `Every week on ${readableDayObj ? readableDayObj.label : dayofWeek} at ${time}`;
	}
	return readableCron;
};

// generate readable address string from an address object
export const generateReadableAddress = (address) => {
	let readableAddress = "";
	if (address && address.id) {
		const { address1, address2, city, pin, subLocality } = address;
		readableAddress += address1 ? address1 + ", " : "";
		readableAddress += address2 ? address2 + ", " : "";
		readableAddress += city ? city + ", " : "";
		readableAddress += subLocality ? subLocality + ", " : "";
		readableAddress += pin ? pin : "";
	}
	return readableAddress;
};

export const isPrimaryAddress = (address) => {
	if (address.tag) {
		const tag = address.tag.toLowerCase();
		if (tag.toLowerCase().indexOf("guest") < 0 && (tag === "home" || tag === "work")) {
			return true;
		}
	}
	return false;
};

export const scroll = (config = { top: 0, left: 0 }, el = window, behavior = "smooth") => {
	try {
		el.scroll({
			...config,
			behavior
		});
	} catch (error) {
		if (el.scroll) {
			el.scroll(config.left, config.top);
		}
		return;
	}
};

export const makeFlatObject = (arr = [], key = "key") => {
	let flatObj = {};
	arr.forEach((obj) => {
		flatObj[obj[key]] = obj;
	});
	return flatObj;
};

export const trackEvent = (eventName, eventMeta) => {
	if (eventName) {
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	}
};
export const generateUniqueId = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;
};

export const getClientID = () => {
	// check and use clientID from local storage
	let clientID = lS.get("clientID") || null;

	if (!clientID) {
		// generate new clientID and store it in local storage
		clientID = uuidv4();
		lS.set("clientID", clientID);
	}
	return clientID;
};

export const breadcrumbs = {
	encode: (val) => {
		// encode val
		const encodedVal = btoa(JSON.stringify(val));
		return encodedVal;
	},
	decode: (val) => {
		// decode val
		let decodedVal = "[]";
		try {
			decodedVal = atob(val);
		} catch (error) {
			decodedVal = "[]";
		}
		// parse decoded val
		const parsedVal = jsonParser(decodedVal, []);
		return parsedVal;
	}
};

export const triggerSurvey = (surveyName) => {
	PubSub.publish(TRIGGER_SURVEY, {
		surveyName
	});
};

export const handleBodyScroll = _debounce((isOpen) => {
	if (isOpen) {
		document.body.style.position = "fixed";
		document.body.style.top = `-${window.scrollY}px`;
	} else {
		const scrollY = document.body?.style?.top;
		document.body.style.position = "";
		document.body.style.top = "";
		window.scrollTo(0, parseInt(scrollY || "0") * -1);
	}
}, 200);

export const parseErrorMessages = (messages = []) => {
	const error = {
		message: "",
		fields: {}
	};
	if (messages.length === 0) {
		error.message = "Something went wrong, please try again.";
	} else {
		messages.forEach((msg) => {
			if (msg.field) {
				error.fields[camelCase(msg.field)] = msg.message;
			}
		});
	}
	return error;
};

export const parseSuperstructErrorMessages = (error) => {
	const errObj = {
		message: "",
		fields: {}
	};
	for (const failure of error.failures()) {
		errObj.fields[failure.key] = failure.message;
	}
	return errObj;
};

export const removeProp = (obj, propToDelete) => {
	for (let property in obj) {
		if (obj.hasOwnProperty(property)) {
			if (typeof obj[property] == "object") {
				removeProp(obj[property], propToDelete);
			} else {
				if (property === propToDelete) {
					delete obj[property];
				}
			}
		}
	}
	return obj;
};

export const getDateRangeFromPreset = (preset) => {
	let diff = 0;
	let startDate = moment();
	let endDate = moment();
	if (preset === "TODAY") {
		return { startDate, endDate };
	}
	if (preset === "YESTERDAY") {
		startDate = moment().subtract(1, "d");
		endDate = moment().subtract(1, "d");
		return { startDate, endDate };
	}
	if (preset === "THIS_WEEK") {
		diff = moment().diff(moment().day("Monday"), "d");
		startDate = moment().day("Monday");
		return { startDate, endDate };
	}
	if (preset === "THIS_MONTH") {
		diff = moment().date();
		startDate = moment().date(1);
		return { startDate, endDate };
	}
	if (preset === "LAST_7_DAYS") {
		diff = 7;
		startDate = moment().subtract(diff - 1, "d");
		return { startDate, endDate };
	}
	if (preset === "LAST_15_DAYS") {
		diff = 15;
		startDate = moment().subtract(diff - 1, "d");
		return { startDate, endDate };
	}
	if (preset === "LAST_30_DAYS") {
		diff = 30;
		startDate = moment().subtract(diff - 1, "d");
		return { startDate, endDate };
	}
	if (preset === "LAST_90_DAYS") {
		diff = 90;
		startDate = moment().subtract(diff - 1, "d");
		return { startDate, endDate };
	}
	if (preset === "THIS_YEAR") {
		diff = moment().dayOfYear();
		startDate = moment().dayOfYear(1);
		return { startDate, endDate };
	}
};

export const getDurationAsString = (totalDuration, durationType = "minutes") => {
	const duration = moment.duration(totalDuration, durationType);

	// Get Days
	// .asDays returns float but we are interested in full days only
	const days = Math.floor(duration.asDays());
	// if no full days then do not display it at all
	const daysFormatted = days ? `${days} ${days === 1 ? "day" : "days"} ` : "";

	// Get Hours
	const hours = duration.hours();
	const hoursFormatted = hours && totalDuration !== 60 ? `${hours} ${hours === 1 ? "hr" : "hrs"} ` : "";

	// Get Minutes
	const minutes = duration.minutes();
	const minutesFormatted = minutes || totalDuration === 60 ? `${totalDuration === 60 ? 60 : minutes} min` : "";

	return [daysFormatted, hoursFormatted, minutesFormatted].join("");
};

export const getSortOrder = (currSort, newSort) => {
	return currSort?.order === "" || currSort?.field !== newSort?.field
		? "DESC"
		: currSort?.order === "ASC"
		? "DESC"
		: "ASC";
};

export const parseFilters = (filters) => {
	let filtersObject = [];
	Object.keys(filters).forEach((f) => {
		if (!filters[f]) {
			return;
		}
		if (typeof filters[f] === "object") {
			if (filters[f].value || filters[f].id) {
				filtersObject.push({
					field: f,
					value: filters[f].value || filters[f].id
				});
			}
		} else {
			filtersObject.push({
				field: f,
				value: filters[f]
			});
		}
	});
	return filtersObject;
};

export const adjustNestedContainer = (toOpen = true, isParentWidthFixed = false) => {
	const parentNestedContainer = document.querySelectorAll(".overlay-sidebar-container > .content.content-show");
	if (!parentNestedContainer.length) {
		return;
	}
	const newWidth = toOpen ? parentNestedContainer[0].offsetWidth + 70 : parentNestedContainer[0].offsetWidth - 70;
	parentNestedContainer[0].style.width = `${isParentWidthFixed ? 750 : newWidth}px`;
	parentNestedContainer[0].style.right = `-${isParentWidthFixed ? 750 : newWidth}px`;
};

export const objectMapToArray = (inp = {}) => {
	return Object.keys(inp).map((key) => inp[key]);
};

export const getRandomChars = (length = 1) => {
	return Math.random()
		.toString(36)
		.slice(2, 2 + length);
};

export const lengthAdjust = (str = "", maxLen, displayLength = 4) => {
	if (str.length >= maxLen) {
		return str.slice(0, displayLength) + "...." + str.slice(str.length - displayLength, str.length);
	} else {
		return str;
	}
};

export const getPrinterCompatibleImages = async (file) => {
	const base64Image = await getBase64(file);

	// build starline and esc/pos compaatible images using base64 image
	const params = {
		base64Image,
		starLineImage: await build(base64Image, "starline"),
		escPosImage: await build(base64Image, "escpos")
	};
	return params;
};

const getBase64 = async (file) => {
	if (!file) {
		return null;
	}
	// compress the file if file size is more than 50kb for starline and esc/pos printers
	if (file && file.size >= 51200) {
		const result = await new Promise((resolve, reject) => {
			new Compressor(file, {
				quality: 0.8,
				maxWidth: 400,
				success: resolve,
				error: reject
			});
		});
		if (result && result.size < 51200) {
			file = result;
		} else {
			return null;
		}
	}

	// get base64 image
	const reader = new FileReader();
	reader.readAsDataURL(file);
	await new Promise((resolve) => (reader.onload = () => resolve()));
	return reader.result;
};

const build = (base64Image, type = "starline") => {
	if (!base64Image) {
		return null;
	}
	return new Promise((resolve) => {
		if (!base64Image) {
			resolve(null);
		}
		const img = new Image();
		img.src = base64Image;
		img.onload = () => {
			const { naturalHeight, naturalWidth } = img;
			const { width, height } = getScaledDimensions(naturalWidth, naturalHeight);

			const commands =
				type === "starline"
					? buildStarLineLogoHelper(img, width, height)
					: buildEscPosLogoHelper(img, width, height);

			resolve(commands ? JSON.stringify(commands) : commands);
		};
	});
};

const getScaledDimensions = (naturalWidth, naturalHeight) => {
	if (naturalWidth > naturalHeight) {
		const aspectRatio = naturalWidth / naturalHeight;
		const width = 400;
		let height = Math.round(width / aspectRatio);
		const heightDiff = height % 24;
		if (heightDiff < 24 / 2) {
			height -= heightDiff;
		} else {
			height += heightDiff;
		}
		return { width, height };
	} else {
		const aspectRatio = naturalHeight / naturalWidth;
		const height = 360;
		let width = Math.round(height / aspectRatio);
		const widthDiff = width % 8;
		if (widthDiff > 8 / 2) {
			width -= widthDiff;
		} else {
			width += widthDiff;
		}
		return { width, height };
	}
};

const buildStarLineLogoHelper = (img, width, height) => {
	let encoder = new StarPrntEncoder();
	let result = null;

	try {
		result = encoder.image(img, width, height, "atkinson");
	} catch (error) {
		result = null;
	}

	if (result) {
		const arr = result._buffer?.map((item) => {
			if (typeof item === "object") {
				item = Object.values(item);
			}
			return item;
		});
		return arr;
	}
	return result;
};

const buildEscPosLogoHelper = (img, width, height) => {
	let encoder = new EscPosEncoder();
	let result = null;

	try {
		result = encoder.image(img, width, height, "atkinson");
	} catch (error) {
		result = null;
	}

	if (result) {
		const arr = result._buffer?.map((item) => {
			if (typeof item === "object") {
				item = Object.values(item);
			}
			return item;
		});
		return arr;
	}
	return result;
};

export const validators = {
	numbersOnly: (str) => {
		const regex = /^\d+$/;
		return regex.test(str);
	},
	alphanumerics: (str) => {
		const regex = /^[a-zA-Z0-9]+$/;
		return regex.test(str);
	},
	alphanumericsSeparatedByHyphen: (str) => {
		const regex = /^(([a-zA-Z0-9]+)((-)([a-zA-Z0-9]+))?)+$/;
		return regex.test(str);
	},
	genericValidationForUnknownAggregators: (str) => {
		return true;
	},
	emailValidator: (emailId) => {
		const regex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(emailId);
	},
	urlValidator: (url) => {
		const regex =
			/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
		return regex.test(url);
	},
	phoneNumberValidator: (phoneNumber) => {
		const regex = /^[+]?([0-9]+[-]?[0-9]+)+$/;
		if (phoneNumber[0] === "+" && phoneNumber.length <= 20) {
			return regex.test(phoneNumber);
		} else if (phoneNumber.length <= 15) {
			return regex.test(phoneNumber);
		} else {
			return false;
		}
	}
};

export const decideMethodAndValidatePlatformId = (platform = "", userEntry) => {
	switch (platform) {
		case "swiggy":
		case "zomato":
		case "magicpin":
		case "dotpe":
		case "thrive":
		case "eazydiner":
		case "talabat":
		case "deliveroo":
		case "careem":
			return validators.numbersOnly(userEntry);

		case "amazon":
		case "dunzo":
		case "chatfood":
		case "ubereats":
			return validators.alphanumericsSeparatedByHyphen(userEntry);

		case "radyes":
		case "noon":
			return validators.alphanumerics(userEntry);

		default:
			return validators.genericValidationForUnknownAggregators(userEntry);
	}
};

export const validatePlatformUrl = (platform = "", userEntry) => {
	const cleanedPlatform = platform.replace(/ /g, "").toLowerCase();
	switch (cleanedPlatform) {
		case "zomato":
			return (
				validators.urlValidator(userEntry) &&
				(userEntry.includes("z.tt") || userEntry.includes("zomato.com") || userEntry.includes("zoma.to"))
			);
		case "noonfood":
			return validators.urlValidator(userEntry) && (userEntry.includes("noon") || userEntry.includes("noonfood"));
		case "justeat":
			return (
				validators.urlValidator(userEntry) && (userEntry.includes("just-eat") || userEntry.includes("justeat"))
			);
		case "eateasy":
			return (
				validators.urlValidator(userEntry) && (userEntry.includes("eateasily") || userEntry.includes("eateasy"))
			);
		case "pumpkinkart":
			return validators.urlValidator(userEntry) && userEntry.includes("pumpkinkart");
		case "thechefz":
			return validators.urlValidator(userEntry) && userEntry.includes("thechefz");
		default:
			return userEntry.includes(platform) && validators.urlValidator(userEntry);
	}
};

export const adjustStringLength = (str, maxLimit = 15) => {
	let newStr = "";
	if (str.length > maxLimit) {
		newStr = str.slice(0, maxLimit - 3) + "...";
		return newStr;
	} else {
		return str;
	}
};

export const getDurationObject = (appliedDateFilter) => {
	const { dateFilter: currDateFilter } = appliedDateFilter.current;
	const currDates = currDateFilter.split(",");
	const obj = {};

	// for current date range
	if (currDates.length === 1) {
		obj.duration = {
			preset: currDateFilter
		};
	} else if (currDates[0] && currDates[1]) {
		obj.duration = {
			custom: {
				start: currDates[0],
				end: currDates[1]
			}
		};
	}
	return obj;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const openFreshchatWidget = () => {
	if (window.fcWidget) {
		window.fcWidget.open();
		window.fcWidget.show();
	}
};

const TO_RADIANS = Math.PI / 180;
export async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
	const ctx = canvas.getContext("2d");

	if (!ctx) {
		throw new Error("No 2d context");
	}

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	// devicePixelRatio slightly increases sharpness on retina devices
	// at the expense of slightly slower render times and needing to
	// size the image back down if you want to download/upload and be
	// true to the images natural size.
	const pixelRatio = window.devicePixelRatio;
	// const pixelRatio = 1

	canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
	canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

	ctx.scale(pixelRatio, pixelRatio);
	ctx.imageSmoothingQuality = "high";

	const cropX = crop.x * scaleX;
	const cropY = crop.y * scaleY;

	const rotateRads = rotate * TO_RADIANS;
	const centerX = image.naturalWidth / 2;
	const centerY = image.naturalHeight / 2;

	ctx.save();

	// 5) Move the crop origin to the canvas origin (0,0)
	ctx.translate(-cropX, -cropY);
	// 4) Move the origin to the center of the original position
	ctx.translate(centerX, centerY);
	// 3) Rotate around the origin
	ctx.rotate(rotateRads);
	// 2) Scale the image
	ctx.scale(scale, scale);
	// 1) Move the center of the image to the origin (0,0)
	ctx.translate(-centerX, -centerY);
	ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

	ctx.restore();
}

export const dataURLtoFile = (dataurl, filename) => {
	let arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const getFileExtension = (filename) => {
	var parts = filename.split(".");
	return parts[parts.length - 1];
};

export const openExternalLink = (url) => {
	if (!url) {
		return;
	}
	window.open(url, "_blank");
};

export const truncateText = (text = "", limit) => {
	if (text.length <= limit) {
		return text;
	}

	return text.slice(0, limit) + "...";
};

export const snakeToCamel = (input) => {
	return input.replace(/_([a-z])/g, function (match, group) {
		return group.toUpperCase();
	});
};

/**
 * @param {Object} objOne
 * @param {Object} objTwo
 * @param {Array} keysToIgnore
 * @return {Array}
 */
export const findChangedKeysInTwoObjects = (objOne, objTwo, keysToIgnore = []) => {
	return _reduce(
		objOne,
		function (result, value, key) {
			return _isEqual(value, objTwo?.[key])
				? result
				: result.concat(key).filter((key) => !keysToIgnore.includes(key));
		},
		[]
	);
};

export const getTrackingSource = () => {
	const { pathname } = window.location;
	const path = pathname.split("/")?.[1];
	return path ? humanizeString(path) : null;
};

export const humanizeString = (str) => {
	if (!str) return;

	return str
		.replace(/([A-Z])/g, " $1")
		.replace(/[-_]/g, " ")
		.replace(/^./, function (str) {
			return str;
		});
};

export const generateUniqueUserId = (email, isdCode, phone, authBy = undefined) => {
	let uniqueId = "";
	if (authBy === "email") {
		uniqueId += email ? email : "";
	}
	if (authBy === "phone") {
		uniqueId += isdCode ? isdCode : "";
		uniqueId += phone ? phone : "";
	}
	if (!authBy) {
		uniqueId += email ? email : "";
		uniqueId += isdCode ? isdCode : "";
		uniqueId += phone ? phone : "";
	}
	return uniqueId;
};

export const snakiseObject = (obj) =>
	_transform(obj, (acc, value, key, target) => {
		const snakeKey = _isArray(target) ? key : _snakeCase(key);

		acc[snakeKey] = _isObject(value) ? snakiseObject(value) : value;
	});

export const inSample = (target) => {
	const domain_name = "atlas.urbanpiper.com";
	const sampleCookie = "mixpanel_sample="; // COOKIE NAME

	let current = document.cookie;

	if (current.indexOf(sampleCookie) > -1) {
		// Cookie already exists use it
		current = document.cookie.substring(document.cookie.indexOf(sampleCookie) + sampleCookie.length);
		if (current.indexOf(";") > -1) current = current.substring(0, current.indexOf(";"));

		current = parseInt(current);
	} else {
		// Cookie not found calculate a random number
		current = Math.floor(Math.random() * 100);
	}
	// reset the cookie to expire in 2 years
	let two_years = new Date();
	two_years.setTime(two_years.getTime() + 2 * 365 * 24 * 60 * 60 * 1000);
	two_years = two_years.toGMTString();

	document.cookie = sampleCookie + current + "; domain=" + domain_name + "; path=/" + " ; expires=" + two_years + ";";

	return target >= current;
};

export const fixedToTwoDecimal = (num) => {
	const parsedNum = parseFloat(num);
	return isNaN(parsedNum) || !isFinite(parsedNum) ? "0.00" : parsedNum.toFixed(2);
};

export const getMerakiV2Flag = (biz) => {
	return biz?.meraki && biz?.isMenuOverCatalogueEnabled;
};

export const getBrandLevelPublishPlatforms = (platformsData) =>
	platformsData?.objects?.filter((plt) =>
		plt?.featureFlags?.some((flag) => flag?.name === "brand_level_publish" && flag?.value === true)
	);
export const getFilteredPublishPlatforms = (platformsData) =>
	platformsData?.objects?.filter(
		(plt) => !plt?.featureFlags?.some((flag) => flag?.name === "brand_level_publish" && flag?.value === true)
	);

export const getHolidayHoursPlatforms = (platformsData = []) => {
	return platformsData?.filter(
		(plt) =>
			plt?.featureFlags?.some((flag) => flag?.name === "holiday_schedule" && flag?.value === true) ||
			plt?.platform?.featureFlags?.some((flag) => flag?.name === "holiday_schedule" && flag?.value === true)
	);
};

export const isHolidayHourEnabledPlatform = (platform) =>
	platform?.featureFlags?.some((flag) => flag?.name === "holiday_schedule" && flag?.value === true);
