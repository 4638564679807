import React from "react";
import { connect } from "react-redux";
import StackProgressBar from "../../../components/Periscope/Dashboard/StackProgressBar";
import Tooltip from "../../../components/Periscope/Dashboard/Tooltip";
import SkeletonLoader from "../../../components/Periscope/SkeletonLoader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { roundAndFormatNumber } from "../../../helpers/periscope";
import Popover from "../../../components/_commons/Popover";

const ItemAvailabilityStackedBar = ({ periscopeDashboard, handleStatusFilter, status, clearFilters }) => {
	const itemMetrics = periscopeDashboard?.itemMetrics;
	const totalItems = itemMetrics?.data?.totalItems || 0;
	const isLoading = itemMetrics?.loading;
	const error = itemMetrics?.error;

	const itemStackProgressBarData = [
		{
			itemOfflineCount: roundAndFormatNumber(itemMetrics?.data?.offlineItemsPercentage, 2),
			itemPartialOfflineCount: roundAndFormatNumber(itemMetrics?.data?.partialOfflineItemsPercentage, 2),
			itemRemainingCount: roundAndFormatNumber(
				100 - (itemMetrics?.data?.offlineItemsPercentage + itemMetrics?.data?.partialOfflineItemsPercentage),
				2
			)
		}
	];

	const progressBarKeys = ["itemOfflineCount", "itemPartialOfflineCount", "itemRemainingCount"];
	const keysColorMapping = {
		itemOfflineCount: "#B02B2B",
		itemPartialOfflineCount: "#FFA05B",
		itemRemainingCount: "#C6D2FF"
	};

	const history = useHistory();
	const selectTable = () => {
		clearFilters();
	};

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);

	const viewValue = queryParams?.get("view");
	const tableSelected = viewValue || "item";

	const renderPopover = (data) => {
		return (
			<div className="title-desc pop-info">
				<span className="main-title">{data.title}</span>
				<span className="info-desc">{data.description}</span>
			</div>
		);
	};

	return (
		<>
			<div className="item-main-graph border-black">
				<div className="item-status-div ">
					<div className="item-info-status">
						<div
							className={`item-status-present ${status == "offline" ? "selected-status" : ""} `}
							onClick={() => handleStatusFilter("offline")}
						>
							<div className="item-info-status-color brown"> </div>
							<div className="item-status-data">
								<div className="item-text-status item-small-font">
									{"Offline"}

									<div className="tooltip">
										<Popover
											data={{
												title: "Offline Items",
												description: "Items that are offline on all storefronts"
											}}
											renderPopover={renderPopover}
											position={"down-left"}
											showOnHover={true}
											showOnClick
										>
											<span className="item-graph-indicator">
												<img
													src="../../../assets/periscope/tooltip-icon.svg"
													className="tool-peri"
												/>
											</span>
										</Popover>
									</div>
								</div>
								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span
										data-testid="itemOfflineCount"
										className="percent-display-item"
									>{`${itemStackProgressBarData[0].itemOfflineCount}%`}</span>
								)}
							</div>
						</div>
						<div
							className={`item-status-present ${status == "partial_offline" ? "selected-status" : ""} `}
							onClick={() => handleStatusFilter("partial_offline")}
						>
							<div className="item-info-status-color orange"></div>
							<div className="item-status-data ">
								<div className="item-text-status item-small-font">
									{"Partially Offline"}

									<div className="tooltip">
										<Popover
											data={{
												title: "Partially Offline Items",
												description: "Items that are offline on some storefronts"
											}}
											renderPopover={renderPopover}
											position={"down-left"}
											showOnHover={true}
											showOnClick
										>
											<span className="item-graph-indicator">
												<img
													src="../../../assets/periscope/tooltip-icon.svg"
													className="tool-peri"
												/>
											</span>
										</Popover>
									</div>
								</div>
								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span data-testid="itemPartialOfflineCount" className="percent-display-item">
										{itemStackProgressBarData[0].itemPartialOfflineCount}%
									</span>
								)}
							</div>
						</div>
						<div
							className={`item-status-present ${status == "online" ? "selected-status" : ""} `}
							onClick={() => handleStatusFilter("online")}
						>
							<div className="item-info-status-color blue"></div>
							<div className="item-status-data">
								<div className="item-text-status item-small-font">
									{"Online"}

									<div className="tooltip">
										<Popover
											data={{
												title: "Online Items",
												description: "Items that are online on all storefronts"
											}}
											renderPopover={renderPopover}
											position={"down-left"}
											showOnHover={true}
											showOnClick
										>
											<span className="item-graph-indicator">
												<img
													src="../../../assets/periscope/tooltip-icon.svg"
													className="tool-peri"
												/>
											</span>
										</Popover>
									</div>
								</div>
								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<span className="percent-display-item">
										{itemStackProgressBarData[0].itemRemainingCount}%
									</span>
								)}
							</div>
						</div>
					</div>

					<div className="item-display-select">
						<div className="item-total-display">
							<div className="item-text-total">Total Items</div>
							<div>
								{isLoading || error ? (
									<>
										<SkeletonLoader classname={` ${error == true ? "stop-moving" : ""}  `} />
									</>
								) : (
									<div
										data-testid="totalItems"
										className="percent-display-item"
										style={{ textAlign: "right" }}
									>
										{totalItems}
									</div>
								)}
							</div>
						</div>
						<div className="select-display-container">
							<Link to="/periscope/item-availability?view=item" className="link">
								<div
									className={`${
										tableSelected == "item" ? "selected-display" : "item-border not-selected"
									} select-display item-border-radius`}
									onClick={() => {
										selectTable();
									}}
								>
									{tableSelected == "item" ? (
										<>
											<img src="/assets/periscope/spoon-blue.svg" className="select-item-image" />
										</>
									) : (
										<>
											<img
												src="/assets/periscope/spoon-white.svg"
												className="select-item-image"
											/>
										</>
									)}
									<span
										className="select-item-text"
										style={{
											...(tableSelected == "item" ? { fontWeight: "600" } : {})
										}}
									>
										Item
									</span>
								</div>
							</Link>
							<Link to="/periscope/item-availability?view=location" className="link">
								<div
									className={`${
										tableSelected == "location"
											? "selected-display"
											: "location-border not-selected"
									} location-border select-display`}
									onClick={() => {
										selectTable();
									}}
								>
									{tableSelected == "location" ? (
										<>
											<img
												src="/assets/periscope/location-blue.svg"
												className="select-item-image"
											/>
										</>
									) : (
										<>
											<img src="/assets/periscope/location.svg" className="select-item-image" />
										</>
									)}
									<span
										className="select-item-text"
										style={{ ...(tableSelected == "location" ? { fontWeight: "600" } : {}) }}
									>
										Location
									</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
				{isLoading || error ? (
					<>
						<div style={{ margin: "17px 0 0 10px", display: "flex" }}>
							<SkeletonLoader classname={` loading-stackedbar ${error == true ? "stop-moving" : ""}  `} />
							<SkeletonLoader
								classname={` loading-stackedbar space-middle ${error == true ? "stop-moving" : ""}  `}
							/>
							<SkeletonLoader classname={` loading-stackedbar ${error == true ? "stop-moving" : ""}  `} />
						</div>
					</>
				) : (
					<>
						<StackProgressBar
							outerDivClass={"item-status"}
							data={itemStackProgressBarData}
							keysColorMapping={keysColorMapping}
							keys={progressBarKeys}
							padding={1}
							borderRadius={1.5}
						/>
					</>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard,
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState
});

export default connect(mapStateToProps)(ItemAvailabilityStackedBar);
