import React, { useState } from "react";
import StackProgressBar from "./StackProgressBar";
import { connect } from "react-redux";
import ZeroPercent from "./ZeroPercent";
import SkeletonLoader from "../SkeletonLoader";
import { ActionItems } from "../../../client-config";
import { removeTrailingZeros, roundAndFormatNumber } from "../../../helpers/periscope";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from "./Tooltip";
import Popover from "../../_commons/Popover";

const ActionsRequired = (periscopeDashboard) => {
	const dashboardMetrics = periscopeDashboard.periscopeDashboard.dashboardMetrics;
	const isLoading = dashboardMetrics?.loading;

	const error = dashboardMetrics?.error;

	const values = {
		offlineStorePercent: roundAndFormatNumber(dashboardMetrics?.data?.offlineStorePercentage, 2),
		offlineItemsPercent: roundAndFormatNumber(dashboardMetrics?.data?.offlineItemsPercentage, 2),
		missingItemPercent: roundAndFormatNumber(dashboardMetrics?.data?.missingItemDetailsPercentage, 2)
	};

	const sortedItems = ActionItems.sort((item1, item2) => {
		return -(values[item1.key] - values[item2.key]);
	});
	const stackProgressBarData = {
		offlineStorePercent: [
			{
				completedFraction: values.offlineStorePercent,
				remainingFraction: 100 - values.offlineStorePercent
			}
		],
		offlineItemsPercent: [
			{
				completedFraction: values.offlineItemsPercent,
				remainingFraction: 100 - values.offlineItemsPercent
			}
		],
		missingItemPercent: [
			{
				completedFraction: values.missingItemPercent,
				remainingFraction: 100 - values.missingItemPercent
			}
		]
	};

	const progressBarKeys = ["completedFraction", "remainingFraction"];
	const keysColorMapping = {
		completedFraction: "#B02B2B",
		remainingFraction: "#EAEAEA"
	};
	const renderPopover = (obj) => {
		return <div className="pop-info">{obj}</div>;
	};
	return (
		<div className="action-required-all-divs">
			{sortedItems.map((item, key) => {
				const displayPercent = removeTrailingZeros(values[item.key]);
				return (
					<div className="action-required-main-container" key={item.label}>
						<div className={`image-div ${displayPercent > 0 ? "image-div-red" : "image-div-green"}`}>
							<img src={displayPercent > 0 ? item.imageUrlRed : item.imageUrlGreen} className="img" />
						</div>

						<div className="display-actions-periscope">
							<div className="action-required-small-title">
								<span className="label"> {item.label} </span>
								<div className="tooltip">
									<Popover
										data={item.infoText}
										renderPopover={renderPopover}
										position={"down-left"}
										showOnHover={true}
										showOnClick
									>
										<img src="../../../assets/periscope/tooltip-icon.svg" className="tool-peri" />
									</Popover>
								</div>
							</div>

							{isLoading || error ? (
								<div className="actions-bar-periscope">
									<SkeletonLoader classname={`loader ${error == true ? "stop-moving" : ""}`} />
								</div>
							) : (
								<>
									{values[item.key] == 0 ? (
										<ZeroPercent item={item} />
									) : (
										<div className="actions-bar-periscope">
											<StackProgressBar
												outerDivClass={"small-bar"}
												innerDivClass={"location-bar-graphs-place"}
												data={stackProgressBarData[item.key]}
												keys={progressBarKeys}
												keysColorMapping={keysColorMapping}
												padding={0.6}
												borderRadius={1}
											/>
											<div className="action-percent-periscope">
												<div className="percent-action-periscope align-items-center ">
													<p data-testid="percentage-id">{displayPercent}%</p>
												</div>

												<div className="arrow-action-periscope">
													<Link to={item.link}>
														<img
															style={{ cursor: "pointer" }}
															src="../../../assets/periscope/right-arrow-action.svg"
														/>
													</Link>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDashboard: state.periscopeDashboard
});

export default connect(mapStateToProps)(ActionsRequired);
